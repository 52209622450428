@import "../../styles/colors.scss";

.integrationDiscovery{
    padding-bottom: 50px;
    &__account{
        display: flex;
        margin-bottom: 26px;
        img{
            width: 48px;
            height: 48px;
            object-fit: contain;
            margin-right: 24px;
            height: 50%;
            transform: translateY(2px);
        }
        &-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--textLighter);
            &-date{
                font-size: 14px;
                font-weight: 400;
                margin-top: 6px;
                span:first-child{
                    margin-right:5px;
                }
            }
        }
    }

    &__percentage-bar-style{
        width: 100%;
        height: 35px;
    }

    &__percentage-no-tagging-style{
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
    
    &__action-btns{
        gap: 10px;
        button {
            background-color: var(--primary);
            &.disabled{
                color: var(--disableColor) !important;
                background-color: var(--disableBG) !important;
            }
        }
    }
    &__cards{
        display: flex;
        gap: 16px;
    }
    &__searchBox{
        max-width: 378px;
        margin: 16px 0;
    }
    &__subProjectsTable{
   
        border:1px solid #363442;
        border-radius: 4px;
        overflow: hidden;
        &__header{
            display: grid;
            grid-template-columns: repeat(7, 1fr) 30px;
            gap: 10px;
            padding: 14px 34px;
            border-bottom: 1px solid #363442;
            background: linear-gradient(0deg, #1F1D2D, #1F1D2D),
            linear-gradient(0deg, #363442, #363442);
            
            div{
                font-size: 14px;
                font-weight: 700;
                color: rgba($color: white, $alpha: .8);
                &:last-child{
                    text-align: right;
                }
            }
        }
        &__body{
           &-row{
            display: grid;
            grid-template-columns: repeat(7, 1fr) 30px;
            gap: 10px;
            padding: 12px 34px;
            &:nth-child(even){
                background: #222231;
            }
            &:not(:last-child){
                border-bottom: 1px solid #363442;
            }
            div{
                font-size: 14px;
                color: rgba($color: #D3D3D6, $alpha: 0.5);
                &:last-child{
                    text-align: right;
                }
            }
            &-name{
                &.disabled{
                    text-decoration: none;
                    color: var(--disableColor);
                }
            }
            &-status{
                span.row{
                    svg{
                        margin-right: 6px;
                    }
                }
            }
            &-menu-icon{
                padding: 0 7px;
                cursor: pointer;
                svg{
                    font-size: 14px;
                    color: var(--primary);
                }
            }
            &-badge{
                background-color: var(--badgeBG) !important;
                color: var(--primary);
                padding: 0px 8px;
                border-radius: 20px;
                font-size: 12px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: fit-content;
                margin: auto;
            }
            &.not-data{
                grid-template-columns: 1fr;
                text-align: center;
                background: #222231;
            }
           }
            
        }
        &__coverage {
        &__tooltip{
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            padding: 0 15px;
            li{
                display: flex;
                gap:10px;
                align-items: center;
                column-gap: 15px;
                 span:first-child {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
        }
        &__progress {
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            display: flex;
            width: 150px;
            border-radius: 10px;
            overflow: hidden;

            li {
                height: 8px;
                display: flex;
                gap: 10px;
            }
        }
        &__tooltip > li > span, 
        &__progress li{
            &.managed {
                background-color: $turquoise;
            }

            &.ghost {
                background-color: $salmon;
            }

            &.modified {
                background-color: $purple;
            }

            &.unmanaged {
                background-color: $strawberry;
            }

            &.unknown {
                background-color: $strawberry;
            }

            &.pending {
                background-color: $light_turquoise;
            }
        }
    }
    }

    &__projectsTable{
        .MuiDataGrid-main{
            border: 1px solid var(--discoveryBorder);
            border-radius: 5px;
            .MuiDataGrid-columnHeaders{
                padding-top: 14px ;
                padding-bottom: 14px ;
                background-color: var(--discoveryTableBG);
                background: linear-gradient(0deg, #1F1D2D, #1F1D2D), linear-gradient(0deg, #363442, #363442);
                .MuiDataGrid-columnHeaderTitle{
                    font-weight: 700;
                    color: var(--discoveryTableColor);
                }
            }
            .MuiDataGrid-row{
                &:hover{
                    background-color: inherit !important;
                }
                &:nth-child(even){
                    background: var(--card);
                    &:hover{
                        background-color: var(--card) !important;
                    }
                }
                &:last-child .MuiDataGrid-cell{
                    border-bottom: 0 !important;
                }
                .MuiDataGrid-cell{
                    font-size: 14px;
                }
            }

        }
        
       
    }
}

.light{
    .integrationDiscovery{
        &__projectsTable{
            .MuiDataGrid-main{
                .MuiDataGrid-columnHeaders{
                    background: unset;
                }
            }
        
        }
    }
}