@import "../../../styles/colors.scss";

.ViewManagerModal {
  padding: 0;

  .ViewsList {
    height: 100%;

    &__items {
      min-height: 75px;
      max-height: 280px;
      overflow-y: auto;
      .ant-radio-group {
        width: 100%;
      }
    }

    &__loading {
      height: 130px;
    }

    &__empty {
      height: 130px;
      gap: 20px;
      .ant-empty {
        margin: 0 !important;
      }

      &-add {
        cursor: pointer;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    &__footer {
      height: 65px;
      border-top: 1px solid var(--separatorColor);
      padding: 0 33px;

      &-save {
        gap: 10px;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          color: $primary;
          transition: all 0.2s ease;
        }

        &-icon {
          color: $primary;
          transition: all 0.2s ease;
        }
      }
    }
  }

  &__form {
    &-toggle {
      gap: 10px;
      margin-bottom: 20px;

      .form-label {
        margin-bottom: 0;
      }
    }
  }

  &__save {
    padding: 20px 40px;
  }
}
