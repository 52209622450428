@import "../../../styles/colors.scss";

.IacStackDrawer {
  padding: 0;

  &__tabs {
    .ant-tabs-tab {
      width: 200px;
    }

    &-tab {
      img {
        height: 18px;
        margin-right: 10px;
        filter: grayscale(100%);
      }

      .aws {
        max-height: 16px;
      }

      .active {
        filter: grayscale(0);
      }
    }

    &-body {
      background-color: var(--codeBG);
      height: calc(82vh - 55px) !important;
      max-height: calc(82vh - 55px) !important;
      overflow-y: auto;
      color: white;
      position: relative;

    &-properties {
      padding: 30px;
      gap: 25px;
      &-scope {
        gap: 15px;
        &-sub {
          gap: 15px;
          &-list {
            gap: 15px;
            &-provider {
              gap: 10px;
              &-integ {
                gap: 10px;
              }
            }
            &-notSupportedProvider {
              gap: 10px;
              &-icon {
                cursor: pointer;
              }
            }
          }
          &-view-asstes-btn {
            &-flag {
              cursor: pointer;
              width: min-content;
              gap: 8px;
              color: $primary;
              border: 1px solid transparent;
              background-color: $lightPrimary;
              font-size: 12px;
              font-weight: bold;
              border-radius: 24px;
              padding: 5px 11px;
              height: 25px;
              transition: all 0.2s ease;
              &:hover {
                border-color: $primary;
                background-color: darken($lightPrimary, 3);
              }
            }
          }
        }
      }
      &-subject {
        gap: 15px;
        &-list {
          gap: 15px;
          &-backend {
            gap: 10px;
            &-icon {
              width: 16px;
              height: auto;
            }
          }
        }
      }
    }
    }
  }
}

.maskCode {
    width: 100%;
    height: 250px;
    position: absolute;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(to bottom, transparent 0%,$sidebarBG 70%);
    position: absolute;
    bottom: 0;
    z-index: 2;
  
    &__title {
      padding-bottom: 35px;
      text-shadow: 0px 0px 2px $text;
    }
  
    .icon {
      transform: scale(2);
    }
  }
