@import "../../styles/colors.scss";

.ProdToggle {
    gap: 10px;

    &__icon {
        color: #A7B0B8;
        margin-left: 1px;
    }
}
