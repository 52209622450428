@import "../../../styles/colors.scss";

.InventoryTableWrapper {
  overflow-x: hidden;
  position: relative;
  padding-bottom: 0 !important;

  .MuiDataGrid-root {
    font-family: inherit;
  }

  &__row {
    td {
      // dont include the checkbox cell
      &:not(:first-child) {
        color: rgba($text, 0.45);
      }
    }
    &-ghostProperty {
      svg {
        width: 10px !important;
      }
      path {
        fill: #b295fe;
      }
    }
  }

  &__header {
    width: 100%;
    min-height: 35px;
    margin-bottom: 12px;
    gap: 20px;

    &-actions {
      gap: 10px;
      min-width: max-content;
    }
  }
  &__container {
    display: flex;
    width: 20px;
  }

  &__item {
    // gap: 10px;
    font-weight: bold;

    &-title {
      max-width: 70%;
    }

    &-cloudIcon {
      position: relative;
      width: min-content;
      &-icon {
        opacity: 0.6;
      }

      &-classification {
        fill: rgba($text, 0.3);
        transform: translateY(3px);
      }

      &-classificationActive {
        fill: $primary;
        transform: translateY(3px);
      }

      &-bubble {
        position: absolute;
        bottom: 4px;
        right: -4px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        font-size: 10px;
        color: white;
        font-weight: bold;
        border: 1px solid $appBG;

        &.orange {
          background-color: rgba(lighten($orange, 5), 1);
        }

        &.blue {
          background-color: rgba(lighten($darkBlue, 30), 1);
        }
        &.darkPurple {
          background-color: #7d15b2;
        }
        &.black {
          background-color: black;
        }
        &.darkBlue {
          background-color: #002879;
        }
        &.green {
          background-color: #5eb02e;
        }
        &.darkGreen {
          background-color: #387f8c;
        }
      }
    }
  }

  &__cloudIcon {
    width: auto;
    height: 16px;
    transition: filter 0.2s ease;

    &.k8s {
      max-height: 17px;
    }
    &.aws {
      max-height: 12px;
    }
  }

  &__iacIcon {
    width: 18px;
    height: 18px;
    transition: filter 0.2s ease;
  }

  &__iacCell {
    gap: 5px;
  }

  &__centered {
    display: flex;
    justify-self: center;
    text-align: center;
  }

  &__flag {
    font-size: 10px;
    font-weight: bold;
    border-radius: 24px;
    padding: 4px 11px;
    line-height: normal;
    min-width: 80px;
    width: max-content;
    background-color: lighten($lightGrey, 17);
    gap: 5px;

    &-icon {
      width: 12px;
    }

    &-analyzer {
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 14px !important;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  // return cloud icon it's color when row is selected
  .ant-table-row-selected {
    .inventoryTableWrapper__cloudIcon,
    .inventoryTableWrapper__iacIcon {
      -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
      filter: grayscale(0);
      transition: filter 0.2s ease;
    }

    .managedFlag,
    .driftFlag,
    .unmanagedFlag {
      background-color: transparent;
    }
  }

  &__configIcon {
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s ease;
    padding: 5px;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .cloudProviderCell {
    padding-left: 0 !important;
  }
}
