@import "../../../styles/colors.scss";

.AccessKeys {
  gap: 20px;
  margin-bottom: 20px; //addition to the whole users container gap.

  &__header {
    gap: 10px;
    &-btns {
      gap: 10px;
    }
  }

  &__body {
    gap: 20px;
    &-btns {
      gap: 10px;
    }
  }
}

// -------------------------------------- access keys modal -----------------------------------------

.CreateAccessKey {
  gap: 30px;

  &__intro {
    width: 90%;
    color: var(--textLighter);
    font-size: 12px;
  }

  &__keys {
    gap: 20px;

    &-item {
      font-size: 12px;
      gap: 11px;

      label {
        color: var(--label);
      }
      &.rowDisplay {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          min-width: 67px;
        }
        .CreateAccessKey__keys-item-key-text {
          width: 99%;
          margin-right: 5px;
          text-overflow: ellipsis;
        }
      }
      &-key {
        border: 1px solid var(--inputBorder);
        border-radius: 4px;
        padding: 10px 13px;
        align-items: center;
        font-family: 'Courier New', monospace;
        color: var(--text);

        &-icon {
          color: $primary;
          cursor: pointer;
          opacity: .5;
          transition: opacity .2s ease;

          &.active {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }
        }

        &-text {
          width: 85%;
        }
      }
    }
  }
}

// dark light support
.light {
  .CreateAccessKey__keys-item-key {
    background: $inputBG;
  }
}
.dark {
  .CreateAccessKey__keys-item-key {
    background: $codeCard;
    border-color: transparent;
  }
}
