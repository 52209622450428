.WebhookIntegraion {
    
    .WebhookCreation {
        gap: 30px;
        width: 50%;

        &__checbox {
            height: 32px;
            color: var(--textLighter);
            font-size: 14px;
        }

        .webhook-link {
            text-decoration: underline !important;
        }
    }
}