.HeaderSearchBox {
  transition: all 0.4s ease;
  width: 100%;
  height: 40px;
  background-color: var(--header-btn-BG);
  border-radius: 10px;
  border: 1px solid var(--header-btn-border);
  overflow: hidden;

  &:hover {
    border-color: var(--primary);
  }

  &__inner {
    position: relative;
    width: 100%;

    input {
      background-color: transparent;
      height: 40px;
      padding-right: 38px;
      padding-left: 3px;
      width: 100%;
      transition: all 0.3s ease;
      border-radius: 10px;
      border: none;

      &::placeholder {
        color: var(--textLighter);
        transition: all 0.3s ease;
      }

      &:focus {
        transition: all 0.3s ease;
        border-color: var(--primary) !important;
      }
    }

    &-ck {
      display: flex;
      position: absolute;
      right: 10px;
      top: 57%;
      transform: translate(10%, -50%);
    }

    &-clear {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--primary);
      transition: all 0.3s ease;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: var(--primary);
        transition: all 0.3s ease;
      }
    }
  }
  &__btn {
    color: var(--primary) !important;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 15px !important;
  }
}
