.RemediationAllBtn {
    cursor: pointer;
    padding: 3px 10px;
    border: 1px solid var(--primary);
    color: var(--text);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.4s ease;
    &.loading {
     &:hover {
      opacity: 0.8;
      background: unset;
      color: var(--text);
     }
    }
    &.onlyIcon {
      padding: 4px 6px;
    }
    &-icon {
      height: 16px;
      width: 16px;
    }
    &:hover {
      background-color: var(--primary);
      color: white;
    }
  }