.DiagramAssetBlock {
  background-color: var(--card);
  padding: 10px 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid var(--cardBorder);
  transition: border .2s ease;

  &:hover {
    transition: border .2s ease;
    border-color: var(--primary);
  }
  &__textContainer {
    height: 85%;
    width: 85%;
  }
}
