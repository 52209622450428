@import "../../../styles/colors.scss";

.InventoryTreeFilter {
    position: relative;
    .provider-type-title {
        color: var(--textSuperLight);
    }
    .provider-type-sub-title {
            color: var(--textSuperLight);
    }

  .provider-details-item{
    gap: 10px;
    color: var(--textSuperLight);
    display: flex;
    place-content: space-between;
    .aws {
      max-height: 12px;
      margin-left: 2px;
    }
    .icon {
      max-height: 15px;
      margin-left: 2px;
    }

    svg {
      width: 16px !important;
      fill: var(--textSuperLight);
      opacity: .7;
    }
  }

  .provider-details{
    display: flex;
    place-content: space-between;
  }
  
  .provider-type-item {
    gap: 10px;
    color: var(--textSuperLight);
    .aws {
      max-height: 12px;
      margin-left: 2px;
    }
    .icon {
      max-height: 15px;
      margin-left: 2px;
    }

    svg {
      width: 16px !important;
      fill: var(--textSuperLight);
      opacity: .7;
    }
  }
  &__title{
    padding:2px 10px 2px 6px;
    border-bottom: 1px solid var(--separatorColor) !important;
  }
  .SearchBox {
    font-size: 13px;
  }

  .ant-tree-treenode {
    &:hover {
      background-color: transparent;
      .ant-tree-checkbox {
        .ant-tree-checkbox-inner {
          border-color: $primary;
        }
      }
    }
  }
  .ant-tree-node-content-wrapper {
    cursor: pointer;
    width: 100%;
    margin: 3px 0 0 0 !important;

    .ant-tree-node-content-wrapper-normal {
      width: 100%;
    }
  }
  .ant-tree-treenode {
    .ant-tree-checkbox {
      .ant-tree-checkbox-inner {
        background-color: transparent;
      }
    }
    .ant-tree-checkbox-checked {
      .ant-tree-checkbox-inner {
        background-color: $primary;
      }
    }
  }
  &__clear {
    position: absolute;
    right: 10px;
    z-index : 10;
    background-color: transparent;
    width: max-content;
    border: none;
    transition: opacity 0.2s ease;
    cursor: pointer;
    gap: 8px;
    color: rgba($primary, 0.8) !important;
    padding: 5px 0 5px 0;
    font-size: 13px !important;
  }
}
