.GuardrailsModal {
    &__configuration {
        &-vars {
            margin-right: 10px;
        }
        &-vars-title, &-vars-input {
            flex: 1;
            &.no-margin { margin: 0 !important; }
            &.val {
                margin-right: 20px;
            }
        }
    }
    &-questionIcon{
        cursor: pointer;
        color: var(--label) !important;
    }
    &-inputsRow {
        align-items: flex-start !important;
    }
    &-addVarsTexts { margin-top: 3px; }
    &-addBtn {
        color: var(--primary);
        border: 1px solid var(--primary);
        border-top-width: 3px;
        border-radius: 2px;
        padding: 1px;
        cursor: pointer;
    }
    &-deleteBtn {
        color: var(--primary);
        cursor: pointer;
        margin-top: 10px;
        &.profile {
            align-self: flex-start;
            margin-top: 20px;
        }
    }
    &-addText {
        color: var(--primary);
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
    }
    &-orText { font-size: 12px; }
    &-copyIcon {
        width: 12px;
        cursor: pointer;
        path {
            fill: var(--primary);
        }
    }
    &__content {
        padding: 30px 40px;
    }
    &-title {
        padding-left: 10px;
    }
    &__section {
        padding-bottom: 20px;
        padding-top: 15px;
        border-bottom: 1px solid var(--separatorColor);
        .ProvidersList__item {
            flex-direction: column;
            min-width: unset;
            width: 85px;
            height: 85px;
            gap: 4px;
            svg {
                height: 30px;
                width: 30px;
            }
        }
        .ant-input-number { width: 365px; }
        .ant-radio-inner::after { left: 3px !important; top: 3px !important;}
        .ant-input-number-handler-wrap {  opacity: 1 !important;}
        .ant-radio-wrapper { margin-right: 0px !important ;}
        .ant-radio-inner { width: 14px; height: 14px;}
        &.noBottom { 
            border-bottom: none;
        }
        .EditableTagGroup {
            padding: 5px;
            input { 
                padding: 0px 5px !important;
                background-color: transparent !important;
            }
            .ant-tag-close-icon {
                color: var(--primary);
            }
        }
        .ant-select-selection-overflow {
            flex-wrap: nowrap !important;
            overflow: auto;
        }
    }
}