.CiModal {
  overflow: hidden;
  &__modal {
    display: grid;
    grid-template-columns: 260px calc(100% - 260px);
    padding: 0;
    overflow: hidden;
    max-height: 90vh;
    height: 85vh;

    &-icon {
      &-failed { color: var(--delete); }
      &-success { color: var(--create); }
    }

    &-leftSide {
      padding: 30px 0px;
      border-right: 2px solid var(--separatorColor);
      justify-content: space-between;
    }
    &-badges {
      height: 100%;
      justify-content: flex-end !important;
    }
    &-guardrailBtn {
      border: 1px solid transparent;
      padding: 3px 6px;
      margin: 0px 20px;
      &:hover {
        border: 1px solid var(--primary);
        border-radius: 4px;
        transition: border 0.3s ease;
      }
    }
  }

  &__content {
    min-height: 60vh;
    padding: 30px;
    position: relative;
    overflow: auto;
    &.hiddenOverflow {
      overflow: hidden;
    }
    &-body {
      height: 100%;
      position: relative;
    }
    &-header {
      height: 40px;
      align-items: flex-start !important;

      &-title {
        font-size: 16px;
        &-guardrails {
          color: var(--guardrailViolationBorder);
          padding: 3px 6px;
          border-radius: 10px;
          font-size: 10px;
          background-color: var(--guardrailViolationsFailedBg);
          &.passed {
            background-color: var(--guardrailsViolationsPassedBg);
            color: var(--guardrailsViolationsPassedIcon)
          }
        }
      }
    }
  }
  &__subTitle {
    color: #969696;
  }
  &__shareBtn {
    margin-top: 2px;
    margin-right: 5px;
    &-icon{
      height: 16px;
      width: 16px;
    }
  }
}
.PostPlan {
  .DiagnosticArr { margin-bottom: 20px; }
}

.PostPlanTable {
  &__action {
    &-icon {
      &.create {color: var(--create);}
      &.delete {color: var(--delete);}
      &.read {color: var(--read);}
      &.update {color: var(--update);}
      &.no-op {color: var(--no-op);}
      &.import {color: var(--import);}
      &-createdelete, &-deletecreate {
          height: 20px;
          width: 20px;
      }
    }
  }
  //detail panel diagnostics override
  .MuiDataGrid-detailPanel {
    left: 0 !important;
    background-color: var(--appBG) !important;
    .Diagnostic__body  {
        border: 0px !important;
        border-radius: 0px !important;
        padding-left: 25px;
        padding-right: 25px;
    }
    .Diagnostic__severityBtn {
        height: 20px;
        font-size: 12px;
    }
    .DarkCopyBox__wrapper-box { padding: 5px 12px !important}
    .Diagnostic__header {
        background-color: var(--card);
        border: 0px;
        border-radius: 0px;
        padding: 15px 25px 0px 25px;
    }
    .Diagnostic__header-title {
        font-size: 13px;
        color: var(--textLighter);
    }
}
}
