.DescriptionBlock {
  width: 100%;
  border-radius: 4px;
  .GradientBtn {
    align-self: flex-end;
  }
  .icon-fill-color {
    filter: invert(100%);
  }
}
