.AssetDataModal {
  display: grid;
  grid-template-columns: 270px calc(100% - 270px);
  padding: 0;
  overflow: hidden;
  height: 65vh; // this is to allow only inner tab to scroll and the tabs menu to stay static

  &__tab {
    min-height: 60vh;
    padding: 30px;
    overflow: auto;
  }

  .AssetTabs {
    overflow: auto;
  }

  // global asset collpase
  .AssetCard {
    &-title {
      font-size: 14px;
      line-height: normal;
      font-weight: 500;
      color: var(--textSuperLight);
    }
  }

  .AppAccordion__header {
    padding: 15px 20px;
  }

  .AppAccordion__header-icon {
    color: var(--textSuperLight);
    opacity: .65;
  }

  .AssetInfoCard {

    &__list {
      border-top: 1px solid var(--separatorColor);
      padding-top: 20px;
      gap: 15px;
      color: var(--textLighter);

      &.grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px 30px;
      }

      &-item {
        align-items: flex-start;
        gap: 15px;
        min-width: 100%;
        word-break: normal;
        &-label {
          font-weight: bold;
          width: 180px;
        }

        // component adjustments
        .IacStatusFlag {
          font-size: 12px;
          padding: 4px 11px;
          height: max-content;
        }

        .console-icon-link {
          opacity: 0.75;
          cursor: pointer;
          font-size: 13px;
          transition: all 0.2s ease;

          &:hover {
            transition: all 0.2s ease;
            opacity: 1;
            color: var(--primary);
          }
        }
      }
    }
  }

  &__footer {
    &-action-migrateIcon, &-action-addIacIgnored {
      margin-top: 7px;
      width: 17px;
      height: 17px;
      path {
        fill: white;
      }
    }

    &-action-right {
      flex-wrap: wrap;
      span.vertical-line {
        height: 20px;
        background-color: var(--cardBorder);
        width: 1px;
        display: inline-block;
      }
      .AppBtn {
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.AssetDataModal_suffix_actions{
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--dividerColor);
  align-items: center;
  background: linear-gradient(90deg, rgba(152, 114, 254, 0.30) 0%, rgba(64, 235, 221, 0.30) 100%);
  margin-right: 24px;
  button{
    background-color: unset;
    &:hover{
      background-color: rgba($color: white, $alpha: 0.05);
    }
    &:first-child:not(.disabled){
      svg{
        color: var(--darkPrimary);
      }
    }
    &:last-child:not(.disabled){
      svg{
        color: var(--green);
      }
    }
  }
  span.vertical-line {
    height: 20px;
    background-color: var(--dividerColor);
    width: 1px;
    display: inline-block;
  }
}
.AssetDataModal_title{
  display: flex;
  align-items: center;
  column-gap: 10px;  
}

.light{
  .AssetDataModal_suffix_actions{
    button:not(.disabled){
      color: #333333 !important;
    }
  }
}