@import "../../../styles/colors.scss";

.ModulesTable {
    height: 100%;
    padding-bottom: 0 !important;
    width: 100%;
    // padding-left: 20px;
    // padding-right: 20px;
    >.TableWrapper{
      flex-grow: 1;
    }
    &__loaderContainer {
        min-height: 60vh;
    }
    &__header {
      width: 100%;
      min-height: 35px;
      gap: 20px;
      justify-content: flex-end;
      margin-bottom: 20px;
  
      &-actions {
        gap: 10px;
        min-width: max-content;
      }
    }

    &__table {
      &-nameCell {
        gap: 10px;
        img {
          width: 16px;
          height: auto;
        }
      }
      &-cell {
        gap: 10px;
        img {
          width: 18px;
          height: auto;
        }
        &-flag {
          cursor: pointer;
          gap: 5px;
          width: min-content;
          color: $primary;
          border: 1px solid transparent;
          background-color: $lightPrimary;
          font-size: 10px;
          font-weight: bold;
          border-radius: 24px;
          padding: 4px 8px;
          height: 20px;
          transition: all 0.2s ease;
          &:hover {
            border-color: $primary;
            background-color: darken($lightPrimary, 3);
          }
        }
        &-soonFlag {
          font-size: 12px;
          padding: 2px 6px;
          gap: 5px;
          font-weight: 500;
          color: var(--disableColor);
        }
      }
    }
}
