.DatadogIntegration {

    .DatadogCreation {
        width: 100%;
        gap: 25px; 
        &__form {
            width: 100%;
            .form-item-width {
                max-width: 400px;
            }
        }

        &__intro {
            gap: 2px;
            &-list {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 0px;
                li:not(:last-child) { 
                margin-bottom: 10px;  
                }
            }
        }

        .ant-radio {
            transform: translateY(-1px);
            align-self: baseline;
    
            .ant-radio-inner {
              width: 14px !important;
              height: 14px !important;
            }
          }
    }
}