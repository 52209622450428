@import "../../styles/colors.scss";

.IconBtn {
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  color: rgba($text, 0.5);

  &:hover {
    opacity: 1;
  }

  &.red {
    color: $red;
  }

  &.purple {
    color: $primary;
  }

  &.blue {
    color: #4070dd;
  }
  &.orange {
    color: $orange;
  }
  &.darkPurple {
    color: #7d15b2;
  }
  &.darkBlue {
    color: #002879;
  }
  &.green {
    color: #5eb02e;
  }
  &.darkGreen {
    color: #387f8c;
  }

  &.filter {
    width: 28px;
    height: 28px;
    &:hover {
      color: $primary;
      background-color: $lightPrimary;
      border-radius: 26px;
    }
  }

  &.h-menu {
    width: 28px;
    height: 30px;
    color: var(--textSuperLight);

    &:hover {
      color: $primary;
      background-color: $lightPrimary;
      border-radius: 4px;
    }
  }

  &.disable {
    cursor: not-allowed;
    color: var(--disableColor);
    opacity: .7;
    background-color: transparent !important;
    border-color: none !important;
    border-radius: 4px;

    button {
      border: none;
      background-color: transparent !important;
    }
    &:hover {
      transform: none;
    }
  }
}
