@import "../../../../styles/colors.scss";

.CreateModuleTab {
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  height: 100%;

  &__menu {
    background-color: var(--codifyMenuBG);
    padding-top: 30px;
    border-left: 1px solid rgba(92, 92, 99, .4);
    
    &-item {
      padding: 5px 20px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease;
      img {
        height: 16px;
      }
      &:hover {
        color: white;
        transition: all 0.3s ease;
        .CreateModuleTab__menu-item-popover {
          display: block;
        }
      }
      &-name {
        max-width: 85%;
      }
      &.active {
        background-color: var(--codifyMenuActiveBG);
        color: white;
        transition: all 0.3s ease;
      }
      &-input {
        transition: none !important;
        padding: 0px 0px 0px 7px !important;
        border-width: 0 !important;
        &:hover {
          border-width: 1px !important;
        }
        &:focus {
          border-width: 1px !important;
          border-color: rgba(92, 92, 99, .4) !important;
        }
      }
      &-popover {
        display: none;
        position: absolute;
        right: 0;
        padding: 10px;
      }
    }
  }
  &__code {
    padding: 20px 0;

    &-inner {
      background-color: var(--codeBG);
      border-radius: 17.6px;
      padding: 20px;
    }
  }
}
