@import "../../styles/colors.scss";

.AssetIacLine {
  width: 90%;
  border-radius: 10px;
  height: 9px;
  overflow: hidden;

  &__block {
    height: 100%;

    &.managed,
    &.child {
      background-color: $turquoise;
    }
    &.unmanaged {
      background-color: $strawberry;
    }
    &.modified {
      background-color: $purple;
    }
    &.iacIgnored {
      background-color: $blueIacIgnored;
    }
  }

  &__tooltip {
    gap: 5px;
    &-item {
      min-width: 120px;
      font-size: 12px;
      gap: 5px;

      &-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &.managed,
        &.child {
          background-color: $turquoise;
        }
        &.unmanaged {
          background-color: $strawberry;
        }
        &.modified {
          background-color: $purple;
        }
        &.iacIgnored {
          background-color: $blueIacIgnored;
        }
      }
    }
  }
}
