.AppLogo {
  padding-left: 14px;

  &.sidebar {
    align-self: start;
    padding-top: 15px;
  }

  &__link {
    gap: 7px;
  }

  &__text {
    width: auto;
    height: 20px;
    transform: translateY(3px);
  }
}
