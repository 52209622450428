@import "../../../styles/colors.scss";

.SingleSuggestion {
  border-radius: 10px;
  color: white;
  padding: 2px 8px;
  width: 100%;
  font-size: 12px !important;
  margin-top: 7px;

  &__header {
    margin-bottom: 5px;

    .MinimalCopy__icon {
      opacity: 1;
    }

    &-account {
      gap: 8px;
      img {
        height: 16px;
        width: auto;
      }
    }
  }

  &__item {
    background-color: $sidebarBG;
    color: white;
    padding: 7px 0px 7px 33px !important;
    width: 100%;
    font-family: "Courier New", monospace;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($codeSeperator, 0.15);
    }
  }
}

.MinimalCopyItem{
  background-color: $sidebarBG;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 28px;
}
