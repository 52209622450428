.NewrelicIntegration {

    
}

.NewrelicCreation {
    width: 50%;
    gap: 20px; 

    &__intro {
        gap: 8px;
        &-list {
            padding-left: 15px;
            margin-top: 10px;
            margin-bottom: 0px;
            li:not(:last-child) { 
              margin-bottom: 10px;  
            }
          }
    }
    &__region {
        gap: 5px;

        img {
            height: auto;
            width: 20px;
        }
    }
}