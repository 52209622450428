.DropMenu {
  &__item {
    gap: 10px;
    padding: 10px 20px;
    color: var(--textLighter);
    cursor: pointer;
    transition: all 0.2s ease;

    &-icon {
      display: flex;
      svg {
        color: var(--primary);
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--separatorColorLight);
    }

    &:hover {
      background-color: var(--lightPrimary);
      transition: all 0.2s ease;
    }

    &.readonly {
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
