$nextColor: var(--nextProgressColor);
$stepTopPadding: 15px;

.CiProgress {
  margin-left: 40px;
  &__guardrail {
    position: relative;
    &-checkmarks {
      position: absolute;
      left: 5px;
      top: calc((105px + #{$stepTopPadding}) / 2);
      background-color: #1AD598;
      color: var(--appBG);
      width: 18px !important;
      height: 18px !important;
      padding: 3px;
      z-index: 1;
      border-radius: 50%;
      border: 2px solid var(--appBG);
      &.blocked {
        background-color: #EB4C3D;
        padding: 2px;
      }
    }
  }
  &-icon {
    &.finish {
      color: white;
    }
    &.active {
      color: white;
    }
    &.next {
      color: white;
    }
  }
  .ant-steps-item-disabled {
    .ant-steps-item-container {
      opacity: 0.7 !important;
    }
  }
  .ant-steps-item-active > .ant-steps-item-container {
    background: var(--gradientColorBG);
    &:hover{
      background: var(--gradientColorBG);
    }
  }
  .ant-steps-item-container {
    margin-right: 30px;
    height: 34px;
    border-radius: 15px;
    margin-top: -1px;
    &:hover {
      background: var(--ciProgressHover);
    }
  }
  .ant-steps-item-title {
    line-height: 30px !important;
  }
  &.three {
    @media (max-height: 770px) {
      .ant-steps-item {
        height: 65px !important; 
      }
      .CiProgress__guardrail-checkmarks {
        top: calc((65px + #{$stepTopPadding}) / 2);
      }
    }
    @media(min-height: 771px) and (max-height: 850px) {
      .ant-steps-item {
        height: 85px !important;
      }
      .CiProgress__guardrail-checkmarks {
        top: calc((85px + #{$stepTopPadding}) / 2);
      }
    }
  }
  &.four {
    .ant-steps-item { 
      height: 97px !important;
    }
    .CiProgress__guardrail-checkmarks {
      top: calc((97px + #{$stepTopPadding}) / 2);
    }
    @media (max-height: 770px) {
      .ant-steps-item {
        height: 50px !important;
      }
      .CiProgress__guardrail-checkmarks {
        top: calc((50px + #{$stepTopPadding}) / 2);
      }
    }
    @media(min-height: 771px) and (max-height: 850px) {
      .ant-steps-item {
        height: 65px !important;
      }
      .CiProgress__guardrail-checkmarks {
        top: calc((65px + #{$stepTopPadding}) / 2);
      }
    }
  }
  &.five {
    .CiProgress__guardrail-checkmarks {
      top: calc((77px + #{$stepTopPadding}) / 2);
    }
    .ant-steps-item { 
      height: 77px !important;
    }
    @media (max-height: 770px) {
      .CiProgress__guardrail-checkmarks {
        top: calc((40px + #{$stepTopPadding}) / 2);
      }
      .ant-steps-item {
        height: 40px !important;
      }
    }
    @media(min-height: 771px) and (max-height: 850px) {
      .CiProgress__guardrail-checkmarks {
        top: calc((55px + #{$stepTopPadding}) / 2);
      }
      .ant-steps-item {
        height: 55px !important;
      }
    }
  }
  //overrides antd steps
  .ant-steps-item {
    cursor: pointer;
    height: 105px !important;
    &:first-child {
       .ant-steps-item-active > .ant-steps-item-icon {
            &:before { display: none !important; }
       }
       .ant-steps-item-finish > .ant-steps-item-icon {
            &:before { display: none !important; }
       }
       .ant-steps-item-container > .ant-steps-item-icon {
        &:before { display: none !important; }
      }
    }
    
    &:last-child {
      .ant-steps-item-container > .ant-steps-item-tail {
        display: none !important;
      }
      .ant-steps-item-container > .ant-steps-item-icon {
        &:after { display: none !important; }
      }
      height: 40px !important;
    }
  }

  .ant-steps-item-wait {
    // wait override
    opacity: 1 !important;
    .ant-steps-item-icon {
      color: white !important;
      background-color: $nextColor !important;
      border: 2px solid $nextColor !important;
    }
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        width: 3px !important;
        height: 112% !important;
        background: $nextColor;
        opacity: 1;
      }
    }
  }
  .ant-steps-item-active {
    // active override
    .CiProgress__guardrail-checkmarks {
      left: 7px;
    }
    .ant-steps-item-title {
      color: white !important;
    }
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        background: linear-gradient(180deg, #9872fe 19%, #8578fe 37.63%, $nextColor 70.98%);
        width: 3px !important;
        height: 111% !important;
        opacity: 1;
      }
    }
  }

  .ant-steps-item-finish {
    // finish override
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        background: var(--primary) !important;
        width: 3px !important;
        opacity: 1 !important;
        height: 125% !important;
        transition: none !important;
      }
    }
  }

  @mixin arrow() {
    border-color: var(--primary) !important;
    border-style: solid !important;
    border-width: 0 3px 3px 0 !important;
    content: "" !important;
    display: block !important;
    height: 7px !important;
    left: 13px !important;;
    position: absolute !important;
    width: 7px !important;;
    top: 27px !important;;
    transform: rotate(43deg) !important;
  } 

  .ant-steps-item-active,
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border-radius: 50% !important;
      height: 32px !important;
      width: 32px !important;
      svg {
        margin-bottom: -2px;
        height: 16px;
        width: 16px;
      }
    }
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {
    &:before {
      @include arrow();
      top: -2px !important;
      transform: rotate(223deg) !important;
    }
    &:after {
      @include arrow();
    }
  }
  .ant-steps-item-active .ant-steps-item-icon {
    &:before {
        @include arrow();
        top: -2px !important;
        transform: rotate(223deg) !important;
    }
    &:after {
      @include arrow();
      border-radius: 0 !important;
      background-color: transparent !important;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      &:before {
        @include arrow();
        top: -3px !important;
        width: 8px !important;
        transform: rotate(223deg) !important;
        border-color: $nextColor !important;
      }
      &:after {
        @include arrow();
        border-radius: 0 !important;
        border-color: $nextColor !important;
        top: 26px !important;
      }
    }
  }
}