@import "../../styles/colors.scss";

.CliSnippet {
  width: 100%;
  background-image: linear-gradient(160deg, $primary, #59fee0);
  border-radius: 5px;
  color: rgba(white, 0.7);
  transition: all 0.2s ease;
  padding: 4px;

  &__wrapper {
    padding: 12px;
    background-color: $sidebarBG;
    border-radius: 4px;
    color: white;
  }

  &__header {
    color: lighten($primary, 5);
    margin-bottom: 10px;
    &-title {
      gap: 6px;
    }
  }

  &.deleteMode {
    background-image: linear-gradient(160deg, $red, $red);

    .CliSnippet__header {
      color: $red !important;
    }
  }
}
