.PostPlanConfig {
  &__cost {
    height: 100%;
    svg {
      margin-left: 40px;
    }
    &-subtitle {
      color: #abb2b68e;
    }
  }
  &__header {
    &-tab {
      gap: 10px;
      &-icon {
        width: 14px;
        height: 14px;
        &.tag {
          svg {
            width: 14px;
            height: 14px;
            path {
              fill: currentColor;
            }
          }
        }
    
      }
    }
    &-title {
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-top: 20px;
      &.sub {
        padding-top: 10px;
      }
    }
    .ant-tabs-tab-disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
      &:hover { color: var(--textSuperLight) !important; }
    }
  }

  .ant-drawer-header {
    padding: 0px !important;
  }
  .ant-drawer-content {
    border-radius: 0px 4px 4px 0px !important;
  }
  .ant-drawer-mask {
    border-radius: 4px !important;
  }
  .Configuration {
    margin-top: 10px;
    background-color: var(--codeBG);
    border: 1px solid var(--cardBorder);
    height: 75%;
  }
  .ant-tabs-tab {
    min-width: 110px !important;
    max-width: 150px !important;
    width: unset !important;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab:first-child {
      span {
        margin-left: 20px;
      }
    }
  }
}

.ResourcePlan {
  margin: 20px 25px;
  height: 100%;
  &__title {
    font-weight: bold;
    &.create {
      color: #1ad598;
    }
    &.delete {
      color: #eb3336;
    }
  }
  &__icon {
    &.create {
      color: #1ad598;
    }
    &.delete {
      color: #eb3336;
    }
  }
  &__cont {
    height: 100%;
  }
}
