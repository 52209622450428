@import "../../styles/colors.scss";

.TerraformImport {
  background-color: var(--codeBG);
  height: calc(82vh - 2px);
  max-height: calc(82vh - 2px);
  overflow-y: auto;
  color: white;
  position: relative;

  &__item {
    padding: 30px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--separatorColor);
    }

    pre {
      padding: 0 !important;
    }
  }
}
