$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
.ExploreSandboxAlert {
    position: relative;
    width: 100%;
    max-width: 457px;
    line-height: normal;
    border-radius: 7px;
    z-index: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    &__container {
        padding: 10px 15px;
        background: rgba(0, 0, 0, 0.45);
        border-radius: 7px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background: $gradient-color;
        border-radius: 7px;
        filter: blur(10px);
    }
    &:hover {
        .ExploreSandboxAlert__icon {
            transform: rotate(-90deg) translateY(3px);
            transition: all 0.5s ease;
            color: white;
        }
        background: $gradient-color;
        transition: all 0.5s ease;
        .ExploreSandboxAlert__container {
            background: transparent;
            transition: all 0.5s ease;
        }
        .ExploreSandboxAlert__text {
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
            color: white;
        }
    }
    &__text {
        animation: fadeIn 1.5s ease;
        text-wrap: nowrap;
        color: var(--textLighter);
    }
    &__icon {
        width: 16px;
        height: 15px;
        color: var(--textLighter);
        transform: rotate(-90deg);
        animation: moveExploreArrow 1.5s;
        animation-iteration-count: 1;
    }

}
@keyframes moveExploreArrow {
    90% {
      animation-timing-function: ease-in;
      transform: rotate(-90deg) translateY(5px);
    }
    93% {
      transform: scaleY(0)
    }
    100% {
      animation-timing-function: ease-out;
    }
}
.ExploreSandboxModal {
    border-radius: 7px;
    background: $gradient-color;
    padding: 2px !important;
    &__container {
        padding: 30px 40px;
        background: var(--header-btn-BG);
        border-radius: 7px;
    }
    &__button {
        background: linear-gradient(90deg, #9872FE 0%, #3CCBB1 100%);
        cursor: pointer;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 5px; 
        color: white;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        &:hover {
            box-shadow: 0px 0px 10px var(--primary);
            transition: all 0.5s ease;
        }
    }
}
.light {
    .ExploreSandboxAlert::before {
        filter: blur(4px);
    }
    .ExploreSandboxAlert__container {
        background: rgba(255, 250, 250, 0.55);
    }
}