@import "../../styles/colors.scss";

.AppAccordion {
  flex-direction: column !important;
  padding: 0px 0px 20px 0px !important;
  transition: padding 1s ease;
  &.notActive {
    padding: 0px !important;
  }
  &.noPadding {
    padding: 0px !important;
  }
  &__header {
    padding: 20px;
    cursor: pointer;
    &-icon {
      svg {
        transition: all 0.3s ease;
      }
      color: $darkGrey;
    }
  }
  &__content {
    padding: 0px 20px 0px 20px;
    color: darken($lightGrey, 20);
    overflow: auto;
    max-height: 500px;
    transition: max-height 1s ease-in-out;
    opacity: 1;
    &.notActive {
      opacity: 0;
      padding: 0px;
      max-height: 0px;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }
    &.noPadding {
      padding: 0px !important;
    }
  }
}
