@import "../../../styles/colors.scss";

.NavLinks {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
  &__section {
    &-title {
      margin: 0px 10px;
      padding: 10px;
      color: var(--grey);
      font-size: 13px;
    }
    &-line {
      animation: moveMenuLine 0.5s ease-in;
      margin: 10px 10px 10px 20px;
      height: 1px;
      width: 100%;
      background: linear-gradient(45deg, var(--grey), transparent);
    }
  }
  &__item {
    margin: 0 10px;
    position: relative;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    transition: color 0.2s ease;
    color: var(--sidebarLink) !important;
    cursor: pointer;

    &-new {
      border-radius: 7px;
      padding: 3px 5px;
      font-size: 10px;
      border: 0.5px solid white;
      text-shadow: 0px 0px 4px black;
      color: #ffffff;
      font-weight: 500;
      background: linear-gradient(115deg, #9872FE 0%, #59FEE0 100%);
      box-shadow: 0px 4px 4px 0px var(--gradientButtonShadow);
    }

    &-icon {
      font-size: 16px;
      width: 16px;

      svg {
        max-width: 16px;
        fill: var(--sidebarLink);
        transform: translateY(1px);
      }
    }

    &-arrow {
      position: absolute;
      right: 15px;
    }

    &:hover {
      transition: opacity 0.2s ease;
      color: var(--primary);

      svg {
        fill: var(--primary);
      }

      .NavLinks__item-icon > svg, .NavLinks__item-text {
        color: var(--primary);
        transition: color 0.2s ease;
      }
    }

    &.centered {
      justify-content: center;
      padding-left: 5px;
    }

    &.activeNavLink {
      transition: color 0.2s ease;
      font-weight: bold;
      color: var(--primary);

      .NavLinks__item-icon > svg, .NavLinks__item-text {
        color: var(--primary);
        transition: color 0.2s ease;
        fill: var(--primary);
      }
      .LinkSubMenu__line{
        display: block;
        border-color: var(--text);
      }
    }
  }
  &__withNew { 
    gap: 7px;
    &-favorite {
      position: absolute;
      right: 10px;
    }
  }
}

@keyframes moveMenuLine {
  0% { transform: translateX(150px);}
  50% { transform: translateX(0px);}
  75% { transform: rotateX(90deg);}
  100% { transform: rotateX(0deg);}
}