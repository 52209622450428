@import "../../../styles/colors.scss";

.InsightsFilters {
  overflow-x: hidden;
  font-size: 13px;
  overflow-y: auto;

  & > * {
    margin-bottom: 15px;
  }

  &-close-btn {
    border: 1px solid var(--separatorColor) !important;
    background-color: transparent;
    cursor: pointer;
    color: var(--textLighter) !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.2s ease;

    &:hover {
      transition: all 0.2s ease;
      background-color: $lightPrimary;
    }

    &.expand {
      transform: scaleX(-1);
    }
  }

  &-clear-btn {
    cursor: pointer;
    font-weight: 400;
  }
}
