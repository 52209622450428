.BackupDR {
  &__filters {
    flex-wrap: wrap;
    padding-inline: 3px;
    min-height: 27px;
  }
  &__header {
    padding: 12px 20px !important;
  }
  .InventoryCharts__charts-item {
    max-width: 50% !important;
    border: 0px;
    padding: 0px;
  }
}
