.AiRemeditionManagement {
    &__header {
        margin-top: 4px;
        display: grid; 
        grid-template-columns: 1fr 37fr 4fr 2fr !important; 
        align-items: center; 
        &-icon {
            fill: var(--text);
            height: 20px;
            width: 20px;
        }
        &-icon-type {
            width: 20px !important;
            margin-left: 7px !important;
        }
        &-preview {
            font-size: 16px !important;
            font-style: normal;
            letter-spacing: 0.2px;
        }
        &-icon-thinkerbell {
            justify-self: end;
        }
        &-description {
            font-size: 12px;
            display: grid; 
            grid-template-columns: 1fr 37fr 4fr 2fr !important; 
        }

        &-description-element {
            grid-column: 2 / 3 !important;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
    }
    &__content {
        padding: 0;
        padding-left: 30px;
        overflow: hidden;
        background-color: var(--ciWizardLeftPanelBG);
        .AppWizard__content-body {
        width: calc(85vw - 305px);
        border: none;
        background-color: rgba(24, 24, 37, 1) !important;
        }

        &.stepsUnvisible {
            padding-left: 0;
            .AppWizard__content-body { width: unset; }
        }
    }
 
    &__footer {
        padding: 20px 25px;
        border-top: 1px solid var(--separatorColor);
        height: 85px;
        .ActionBtn__icon {
            svg {margin-top: 4px;}
        }
        &-close {
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: var(--textLighter) !important;
            opacity: .8;
            transition: all 0.2s ease;
            &:hover {
              opacity: 1;
            }
            &.disabled {
              cursor: not-allowed;
              visibility: hidden;
            }
        }
        &-comment {
            align-self: flex-end;
            color: var(--textLighter);
            font-size: 12px;
        }
        .button-margin {
            margin: 0 8px;
          }
    }
    &__subtitle {
        margin-left: 31px;
    }

    .AppWizard__steps {
        width: 340px;
    }
    .AppWizard__content {
        border-radius: 0;
        border: none;
        &-header { display: none; }
        &-body {
            height: 65vh;
            background-color: var(--ciWizardContentBodyBG);
        }
        &-footer {
            display: none;
        }
    }
    .AppWizard__content-footer-cancel {
        width: unset;
        height: unset;
    }
}

.light{
    .AiRemeditionManagement  {
        .AppWizard__content {
            &-body {
                height: 65vh;
                background-color: var(--card) !important;
            }
        }
    }

}
