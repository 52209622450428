.facet-container {
    flex: 1;
    overflow: auto;

    .facet-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .ant-collapse {
        display: flex;
        z-index: 1000;
        gap: 10px;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        display: flex;
        width: 200px;
        height: 30px;
        font-size: 14px;
        background: #212132;
        border: 1px solid #8e8d8f80;
        border-radius: 5px;
        color: #8e8d8f;
        padding: 0px 10px 0px 10px !important;
    }

    .ant-collapse-content-box {
        border: 1px solid #ffffff4d;
        border-radius: 10px !important;
        margin-top: 3px !important;
    }

    .ant-collapse-arrow {
        display: none !important;
    }

    .ant-collapse-item-active .ant-collapse-header {
        color: #9872fe !important;
        font-weight: 400 !important;
    }

    .ant-collapse-item > .ant-collapse-header:hover {
        color: #9872fe !important;
    }

    .ant-collapse-content,
    .ant-collapse-content-active {
        position: absolute !important;
        width: 300px;
        z-index: 1000;
    }
}

.light {
    .facet-container {
        .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
            background-color: #ffffff;
        }

        .ant-collapse-content-box {
            border: 1px solid #b6b4c4;
        }
    }
}
