.AssetRelationship {
  height: 100%;

  &__header {
    height: 40px;
    align-items: flex-start !important;
    &-title {
      font-size: 16px;
    }
  }
  &__body {
    height: calc(100% - 40px);

    .RelationshipChart {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    .RelationshipTable {
      height: 100%;
      padding-bottom: 0;
      overflow: hidden;
      position: relative;
    }

    .RelationshipAssetConfig {
      .Configuration {
        border: none;
        border-radius: 0;

        &__loading,
        &__no-data {
          height: 40vh;
        }
      }
    }
  }
}
