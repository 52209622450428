@import "../styles/colors.scss";

.AuthError {
  background-color: #181825;
  height: 100vh;
  width: 100vw;

  &__card {
    width: 600px !important;
    text-align: center;
    padding: 30px !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #212133;
    border: 1px solid #30304A;  
    color: white;

    img {
      width: 70px;
      height: auto;
      margin-bottom: 20px;
    }
  }

  a {
    color: $primary;
    font-size: 14px;
  }
}
