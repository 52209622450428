.LottieLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 16%; 
  overflow: hidden;
  height: 50px;
  }

  .LottieLoading-animation {
    width: 280px !important;
    height: 280px !important;
    max-width: 100%;
    max-height: 100%;
  }
  