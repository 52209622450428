@import "../../styles/colors.scss";

.SuccessBox {
  gap: 40px;
  animation: grow 0.35s;

  &__text {
    gap: 10px;

    &-title {
      gap: 10px;
      &-text {
        color: $turquoise;
        font-weight: bold;
        font-size: 28px;
      }

      &-icon {
        color: $turquoise;
        font-size: 25px;
      }
    }

    &-subTitle {
      color: var(--textLighter);
      font-size: 16px;
      text-align: center;
    }
  }

  &__image {
    img {
      height: 100%;
      max-height: 180px;
      width: auto;  
    }
  }
}
