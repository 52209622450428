.OktaIntegration {

    .OktaCreation {
        width: 50%;
        gap: 25px; 

        &__intro {
            gap: 3px;
            &-list {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 0px;
                li:not(:last-child) { 
                  margin-bottom: 10px;  
                }
              }
        }
    }
}