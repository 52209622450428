.PremiumBtn {
    padding: 8px 16px;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    background: linear-gradient(93deg, #9872fe 50%,  #59fee0);
    gap: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.2s ease;
    line-height: 15px;

    &__icon {
      height: auto;
      width: 13px;
    }
  
    &:hover {
      background-color: var(--darkPrimary);
      transition: all 0.2s ease;
    }

    // when sidebar is closed
    &.minimal {
      padding: 0;
      width: 40px;
      height: 40px;
    }
  }