.AiRemediationCompere {

    &__path {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 35px !important;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 25px;

        &__content {
            display: flex;
            align-items: center;
        }

        &__icon {
            margin-left: 4px;
            padding-top: 6px;

            svg {
                width: 100%;
                height: 100%;
                max-width: 24px;
                max-height: 24px;
            }
        }

        &__title {
            margin-left: 12px;
            font-size: 16px;
            font-weight: 700;
        }


        &__count {
            margin-left: 15px;
            color: #888;
        }
    }

    &__header {
        height: 40px;
        display: flex !important;
        padding: 0px;
        background: #22222F;
        border: 1px solid #353344;
        border-radius: 4px 4px 0px 0px;
    }

    &__codeDiff {
        background-color: #13131c;
        border: 1px solid #353344;
        border-radius: 0px 0px 4px 4px;
    }

    &__title {
        flex: 1;
        font-size: 16px;
        font-weight: 700;
        margin-left: 15px;
    }
}