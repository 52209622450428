@import "../../styles/colors.scss";

.FilterBadge {
  color: $primary;
  width: max-content;
  padding: 5px 12px;
  border-radius: 34px;
  border: 1px solid transparent;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: $lightPrimary;

  &__content {
    gap: 5px;
    // text-transform: lowercase;

    &-img {
      fill: $primary;
      height: 15px;
      width: auto;
    }
    &-emptyImg {
      height: 15px;
      width: auto;
    }
  }
  &-ghost {
      width: 12px !important;
      height: 11px !important;
    path {
      fill: $primary;
    }
  }
  &__close {
    opacity: 0.5;
    transition: all 0.2s ease;
  }

  &:hover {
    border-color: rgba($primary, .7);

    .FilterBadge__close {
      opacity: 1;
      transition: all 0.2s ease;
    }
  }
}
