@import "../../../styles/colors.scss";

.TfVar {
    display: grid;
    flex-direction: row;
    align-items: flex-start;
    grid-template-columns: 136px 1px 100px calc((100% - 277px)/2) calc((100% - 277px)/2);
    gap: 10px;
    &.smallScreen {
        grid-template-columns: 136px 1px 100px 125px calc(100% - 402px);
    }
    &__icon {
        background-color: var(--innerCard);
        color: var(--disableColor);
        border-radius: 6px;
        padding: 5px;
        border: 1px solid var(--separatorColor);
        &.active {
           background: rgba($primary, 0.6);
           color: white;
           border-color: $primary;
           box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        }
    }
    &__divider {
        height: 1.9em !important;
        margin: 0px !important;
    }
    &__type {
        background: rgba($primary, 0.6);
        text-align: center;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0px 5px
    }
    &__name {
        font-weight: bolder;
        color: var(--text);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__textArea {
        &.desc {
            resize: none !important;
        }
    }
}