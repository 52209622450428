$color: #916afc;

.LinelLoader {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: var(--disableBG);
  width: 80%;
  height: 14px;
  border-radius: 3px;

  &.fullWidth {
    width: 100%;
    height: 30px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }

}