.ExcludedAssets {
  gap: 20px;

  &__wrapper {
    &-header {
      margin-bottom: 32px;
      margin-top: 12px;
      gap: 40%;
    }
  }

  &__filter-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;

    >span:first-child {
      font-size: 14px;
      font-weight: 400;
    }

    &_switches {
      display: flex;
      gap: 16px;
      align-items: center;

      >div {
        display: flex;
        gap: 11px;
        align-items: center;

        span {
          font-size: 12px;
          color: #D6D6DA;
        }
      }

    }

    &_dropdowns {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .divider {
      height: 16px;
      width: 1px;
      background: #909099;
    }

    .clear-btn {
      font-size: 14px;
      padding: 0 !important;

      span {
        text-decoration: underline !important;

      }
    }
  }
}

.light{
  .ExcludedAssets__filter-wrapper_switches {
    >div {
      span {
        color: #8E8D8F;
      }
    }

  }

}