@import "../../styles/colors.scss";
$len: 242.776657104492px;
$time: 2s;

.DevOpsLoader {
  position: relative;
  width: 50px;
    #outline {
        stroke-dasharray: $len*0.01, $len;
        stroke-dashoffset: 0;
        animation: anim $time linear infinite;
      }
      @keyframes anim {
        12.5% {
          stroke-dasharray: $len*0.14, $len;
          stroke-dashoffset: -$len*0.11;
        }
        43.75% {
          stroke-dasharray: $len*0.35, $len;
          stroke-dashoffset: -$len*0.35;
        }
        100% {
          stroke-dasharray: $len*0.01, $len;
          stroke-dashoffset: -$len*0.99;
        }
      }
      
      .check-out {
        position: absolute;
        bottom: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2rem;
      }
}
