/* example-split.css */
.split-horizontal {
  display: flex;
  width: 100%;
  height: 100%;
}
.split-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gutter {
  flex-shrink: 0;
  flex-grow: 0;
  background: white;
  border-right: 1px solid #dddddd;
  /* border-left: 1px solid #dddddd; */
  transition: background-color 0.3s ease;
  max-width: 13px;
}

.gutter-horizontal {
  cursor: col-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #8559FE;
}

.gutter-horizontal:after {
  content: "•••";
}

.gutter-vertical {
  cursor: row-resize;
}
.gutter:hover {
  background: #e6dcfd;
}
.gutter-dragging:hover {
  background: #e6dcfd;
}

.pane {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
