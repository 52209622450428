.aws-fix {
    max-height: 17px;
    transform: translateY(1px);
  }

  .aws {
    max-height: 12px;
    margin-left: 2px;
  }

  .ProviderIcon-cloudflare {
    max-width: 25px;
  }

  [class^="ProviderIcon-"][class*="objects"] {
    max-height: 22px !important;
    margin-bottom: 4px;
  }