@import "../../styles/colors.scss";

.AppComment {
  gap: 10px;
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 50px calc(100% - 50px);
    &-text {
      &-header {
        align-items: flex-start !important;
      }
      &-name {
        color: var(--primary);
      }

      &-date {
        color: var(--textSuperLight);
        opacity: .7;
        font-size: 12px;
        margin-bottom: 10px;
      }

      &-comment {
        color: var(--textLighter);

        &-edited {
          color: var(--textSuperLight);
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }

  &__actions {
    gap: 15px;
    color: var(--textSuperLight);
    font-size: 12px;

    &-item {
      gap: 5px;
      cursor: pointer;
      &:hover {
        color: var(--primary);
      }

      &.liked {
        color: var(--primary);
      }
    }
  }

  &__loading {
      height: 100px;
      font-size: 20px;
      color: var(--primary);
  }
}
