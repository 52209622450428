@import "../../../../../styles/colors.scss";
.Insights {
  min-height: 100%;
  // width: 100%;

  &__insight {
    &:not(:last-child) {
      // border-bottom: 1px solid var(--separatorColor);
      margin-bottom: 6px;
      padding-bottom: 6px;
    }
    gap: 10px;
    cursor: pointer;

    &:hover {
      .Insights__insight-content-title {
        transition: all 0.2s ease;
      }

      .Insights__insight-content-text {
        color: white;
        transition: all 0.2s ease;
      }
    }

    &-content {
      gap: 2px;
      transition: all 0.2s ease;

      &-title {
        gap: 10px;
        color: var(--textLighter);

        // &.reliability {
        //   color: $reliability !important;
        // }

        // &.optimization {
        //   color: $optimization !important;
        // }

        // &.misconfiguration {
        //   color: $misconfig !important;
        // }

        &-flag {
          img {
            height: 14px;
            width: auto;
          }
          gap: 5px;
          color: $primary;
          // background-color: $lightPrimary;
          font-size: 10px;
          font-weight: bold;

          &.reliability-flag {
            color: $reliability;
            // background-color: rgba($reliability, 0.15);
          }

          &.optimization-flag {
            color: $optimization;
            // background-color: rgba($optimization, 0.15);
          }

          &.misconfiguration-flag {
            color: $misconfig;
            // background-color: rgba($misconfig, 0.15);
          }
        }
      }
      &-text {
        color: var(--textLighter);
        transition: all 0.2s ease;
      }
    }
  }
}