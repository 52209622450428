.IacStep {
  width: 100%;

  &__integs {
    width: 100%;
    align-items: center;
    gap: 10px;

    &-list {
      width: 100%;
      max-width: 750px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
  }

    &-sub {
      text-align: left;
      width: 100%;
      max-width: 750px;
      padding-left: 10px;
      color: var(--textSuperLight);
      font-weight: 500;

      &:not(:first-child){
          margin-top: 30px;
      }
    }
  }
}
