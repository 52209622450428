$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.Diagnostic {
    &__header {
        border-radius: 4px 4px 0px 0px;
        padding: 10px 20px;
        border-bottom: 1px solid var(--separatorColor);
        &.critical, &.error { background-color:rgba(49, 124, 136, 0.8);}
        &.error { background-color: rgba(235, 51, 54, 0.8);}
        &.guardrail { background-color:#B23C3ACC; }
        &.high { background-color: rgba(45, 63, 92, 0.8); }
        &.medium { background-color: rgba(57, 69, 124, 0.8);}
        &.low, &.warning { background-color: rgba(70, 63, 149, 0.8); }
        &-title {
          color: white; 
          font-weight: 600;
          &.unbold {
            font-weight: unset;
          }
        }
      &-remediateIcon {
        cursor: pointer;
        width: 22px;
        height: 22px;
        padding: 3px;
        border: 1px solid #59FEE0;
        border-radius: 4px;
        &:hover {
          background-color: #59FEE0;
        }
        &.clicked {
          background-color: #59FEE0;
        }
      }
    }
    &__body {
      padding: 10px 20px;
      background-color: var(--card);
      border-radius: 0px 0px 4px 4px;
      border: 1px solid var(--separatorColor);
      border-top: none;
      &.withRemediate {
        border-radius: 0px;
      }
    }

    &__text { 
      font-size: 13px;
      &.guardrails {
        white-space: pre-wrap;
      }
    }
    &__icon {
        width: 13px !important;
        min-width: 13px !important;
        height: 13px;
        padding: 2px;
        border-radius: 2px;
        color: #30304A;
        &-guardrail { 
          width: 16px;
          height: 16px;
          path {
            fill: #D8453F;
          }
        }
        &.success { background-color: #1AD598 }
        // severity
        &.critical { background-color: var(--critical); }
        &.error { background-color: var(--delete); }
        &.high { background-color: var(--high); }
        &.medium { background-color: var(--medium); }  
        &.low, &.warning { background-color: var(--low); }
    }
    &__severityBtn {
        height: 22px;
        border-radius: 4px;
        font-size: 13px;
        padding: 1px 10px;
        gap: 8px;
        transition: all 0.3s ease;
        min-width: max-content;
        color: white !important;

        &.critical, &.error {
          background-color: #16383D;
          border-left: 5px solid var(--critical);
        }
        &.error {
          background-color: #832b2c;
          border-left: 5px solid var(--delete);
        }
        &.high {
          background-color: #1C2739;
          border-left: 5px solid var(--high);
        }
        &.medium {
          background-color: #1F2541;
          border-left: 5px solid var(--medium);
        }
        &.low, &.warning {
          background-color: #201D43;
          border-left: 5px solid var(--low);
        }
        &.unknown {
          background-color: #482e8a;
          border-left: 5px solid #562ab2;
        }
    }
    .DarkCopyBox__wrapper-box {
      padding: 5px 8px !important;
      background-color: var(--ciDiagnosticCodeBlock) !important
    }
    .DarkCopyBox__wrapper-box-icon {
      align-self: center;
    }
}