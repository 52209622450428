@import "../../../../styles/colors.scss";

.AssetTabs {
  border-right: 2px solid var(--separatorColor);
  padding-top: 30px;

  &__tab {
    width: 250px;
    height: 60px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 30px;
    gap: 10px;

    &-name {
      gap: 8px;
    }
    svg {
      stroke: var(--text);
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: var(--card);
      transition: all 0.2s ease;
    }

    &.active {
      background-color: rgba($primary, 0.8);
      color: white;
      font-weight: bold;
      transition: all 0.2s ease;
      svg {
        stroke: white;
        transition: all 0.2s ease;
      }
      .AssetTabs__tab-count {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    &.soon {
      padding: 0 5px 0 30px;

      .AssetTabs__tab-name {
        opacity: 0.5;
      }

      &:hover {
        background-color: var(--appBG);
        transition: all 0.2s ease;
        cursor: not-allowed;
      }
    }

    &-count {
      background-color: $primary;
      border-radius: 24px;
      min-width: 20px;
      min-height: 20px;
      padding: 1px 10px;
      color: white;
      font-size: 11px;
    }
  }
}
