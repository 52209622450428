.EmptyIac {
    gap: 30px;
    height: 100%;
    img {
        height: 210px;
        width: auto;
    }

    &__text {
        gap: 15px;

        .title {
            font-size: 30px;
        }

        &-action {
            gap: 40px;
            cursor: pointer;

            .lighterText {
                font-size: 16px;
            }
        }

        &.purple-text {
            opacity: 1 !important;
        }
    }
}