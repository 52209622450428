.RegoAccordionsBlock {
    border-left: 1px solid var(--primary);
    width: 100%;
    overflow: hidden;

    &__results {
        height: 270px;
        overflow: auto;
        padding: 10px;
        &-empty {
            height: 100%;
        }
        &-item {
            padding: 10px 0;
            border-bottom: 1px solid var(--separatorColor);
        }
    }

    &__table {
        .basic-card {
            padding: 10px;
            border: none;
        }
    }

    &__emptyjson {
        height: 270px;
    }
}