.GuardrailsFilters {
    &__item {
        font-size: 12px;
        color: var(--textLighter);
    }
    &__icon {
        width: 12px;
        &.workflows {
            fill: var(--text);
        }
        &.type { height: 12px;}
        &.filters { color: var(--text); }
    }
    &__divider {
        width: 16px;
        height: 1px;
        background-color: var(--separatorColor);
        transform: rotate(90deg);
    }
    &__clear-btn {
        font-size: 14px;
        padding: 0 !important;
        span {
            text-decoration: underline !important;
        }
    }
}