.AiRemediationCliCollapse {
    .ant-collapse-item .ant-collapse-header {
        background-color: unset !important;
        color: #FFFFFF !important;
    }

    .ant-collapse-item-active .ant-collapse-header {
        color: var(--primary) !important;
    }
    .panel-title {
        font-size: 12px !important;
    }
    &-panel-arrow-icon {

        .MinimalCopy__icon {
            color: var(--primary) !important;

        }

        .ant-collapse-item .ant-collapse-header {
            color: var(--primary) !important;
        }
    
        .ant-collapse-item-active .ant-collapse-header {
            color: var(--primary) !important;
        }
    }
}
