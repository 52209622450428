@import "../../styles/colors.scss";

.StackCreation {
  // first step -- provider
  .ProviderSelection {
    &__list {
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  .StackgGroupSelection {
    width: 50%;
    gap: 20px;
  }

  // second step -- location
  .StateLocation {
    gap: 30px;

    &__list {
      gap: 20px;
      flex-wrap: wrap;
    }

    &__forms {
      width: 50%;
    }
  }

  // third step -- summary
  .SelectStackNames {
    gap: 30px;
    width: 50%;

    &__summary {
      gap: 3px;
      font-size: 12px;
    }

    &__files {
      gap: 20px;
      font-size: 12px;
      line-height: 17px;

      textarea {
        &::placeholder {
          color: $grey;
        }
      }
    }
  }

  .selectAll__checbox {
    color: rgba($text, .55);
  }

  &__tags {
    color: rgba(4, 21, 39, 0.5);
    gap: 10px;
  }
}
