.CiPolicyCostSummary {
    padding: 10px 15px;
    transition: 0.3s all ease-in;
    cursor: pointer;
    &__titleCont {
      margin-bottom: 3px;
      &-text {
        color: var(--textLighter);
        font-size: 15px;
        font-weight: 800;
      }
      &-icon {
        color: var(--textLighter);
      }
    }
    &__cost {
      font-weight: 500;
      color: var(--textLighter);
      &-perMonth {
        font-size: 11px;
        &.purple { 
            color: var(--primary);
            margin-top: 3px;
        }
      }
      &.purple {
        color: var(--primary);
      }
      &.now {
        font-size: 23px;
        font-weight: 800;
        line-height: 1.15;
      }
    }
    &__tagBar {
      background: linear-gradient(#263245,#263245) padding-box, linear-gradient(90deg, #a185f8 0, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0) border-box;
      border-radius: 10px;
      width: 100%;
      height: 10px;
      border: 1px solid transparent;
      &.summary {
        height: 14px;
      }
      &.table {
        background: linear-gradient(#212133,#212133) padding-box, linear-gradient(90deg, #a185f8 0, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0) border-box;;
      }

      &-fill {
        height: 100%;
        background: linear-gradient(90deg, #a185f8, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0);
        border-radius: 8px 0 0 8px;
      }
    }
    &__severity {
      margin-top: 10px;
      &-text {
        color: 'white'
      }
      &-count {
        border-radius: 20px;
        font-weight: 700;
        width: 35px;
        text-align: center;
        color: var(--textLighter);
        &.critical {
          border: 1px solid var(--critical);
          background: rgba(63, 211, 216, 0.30);
        }
        &.high {
          border: 1px solid var(--high);
          background: rgba(73, 139, 200, 0.30);
        }
        &.medium {
          border: 1px solid var(--medium);
          background: rgba(78, 100, 192, 0.30);
        }
        &.low {
          border: 1px solid var(--low);
          background: rgba(82, 70, 185, 0.30);
        }
      }
    }
    &__dollarImg {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &__securityImg {
      position: absolute;
      top: 100px;
      right: 0;
    }
    &__taggingImg {
      position: absolute;
      top: 0;
      right: 15px;
    }
    .ant-divider-horizontal {
      margin: 10px 0px !important;
    }
    .ant-divider {
      border-width: 3px !important;
    }
  }
  .light {
     .CiPolicyCostSummary__tagBar {
        background: linear-gradient(#eefbfc, #eefbfc) padding-box, linear-gradient(90deg, #a185f8 0, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0) border-box;
        .table {
          background: unset;
        }
     }
  }