.Compose {
  background-color: var(--appBG);
  height: 100%;
  padding: 30px;
  color: var(--text);
  gap: 20px;
  overflow: auto;

  &__header {
    &-title {
    padding-left: 30px;
    margin-bottom: 5px;
    gap: 10px;
    font-size: 17px;
    color: var(--textLighter);
    &-icon {
      width: 18px;
      fill: var(--textLighter)
    }
    
  }
  .ant-tabs-tab {
    width: 220px !important;
  }
  &-tab {
    gap: 7px;
    font-size: 13px;
    &-icon {
      width: 14px;
      margin-left: 10px;
      path {
        fill: var(--textLighter) !important;
      }
    }
  }

  .ant-tabs-tab-active {
    color: var(--textLighter) !important;
    &:hover {
      color: var(--textLighter) !important;
    }
    .ant-tabs-tab-btn {
      &:focus {
        color: var(--textLighter) !important;
      }
    }
  }
  .ant-tabs-tab {
    &:hover {
      color: var(--textLighter) !important;
    }
    &:focus {
      color: var(--textLighter) !important;
    }
  }
  .ant-tabs-ink-bar {
    background: linear-gradient(92.57deg, #59fee0 4.91%, #9872fe 95.86%) !important;
  }
}
  &__languages {
    height: 100%;
    width: 45%;
    gap: 40px;
    &-btns {
        align-self: flex-end;
    }

    .btn-cancel {
      color: rgba(255, 255, 255, 0.753) !important;
    }
  }

  &__code {
    height: 100%;
    width: 55%;
  }

  &__msgsPrompt {
    height: 80%;
  } 

  .GradientTextarea {
    height: 100%;
    textarea {
      background-color: var(--appBG);
      color: var(--inputColor);
    }
  }
  &__sendMsgIcon {
    position: relative;
    height: 18px;
    animation-name: moveAndRotate;
    animation-duration: 2s;
    width: 18px;
    cursor: pointer;
    margin-right: 15px;
  }
}

@keyframes moveAndRotate {
  0% { transform: translateX(17px);}
  50% { transform: translateX(0px);}
  75% { transform: rotateX(90deg);}
  100% { transform: rotateX(0deg);}
}


// appDrawer override
.AppDrawer__content-headerCompose {
  height: 13vh;
  justify-content: flex-end;
  padding-left: 0px;
}
.AppDrawer__content-bodyCompose {
  height: 79vh !important;
  max-height: 82vh !important;
  overflow-y: auto;
  position: relative;
  border-bottom: 1px solid var(--separatorColor);
}

