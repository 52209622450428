@import "../../styles/colors.scss";

// tosast alert types background adjesments
.Toastify__toast--error {
  background-color: $red !important;
}

.Toastify__toast--success {
  background-color: $green !important;
}

.Toastify__toast--info {
  background-color: $primary !important;
}

.Toastify__toast--warning {
  background-color: $orange !important;
}

// custom toast content
.appToast {
  gap: 23px;

  &.gradient {
    .Toastify__toast--success, .Toastify__toast--info {
      background: var(--gradientColorBG) !important;
    }
  }
  &__icon {
      font-size: 25px;
  }

  &__content {
    gap: 5px;

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    &-text {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
