@import "../../../styles/colors.scss";

.TopFiveProperties {
  width: 100%;
  height: 100%;
  gap: 45px;
  position: relative;

  &__list {
    width: 100%;
    height: 100%;
    gap: 15px;
  }

  &__line {
    min-width: 5px;
    min-height: 97%;
    align-self: flex-end;
    margin-bottom: 3%;
    border-radius: 7px;
    background-color: $purple;
    display: block;
  }
  &__circle {
    border-radius: 50%;
    background-color: $purple;
    position: absolute;
    left: 10px;
    top: 50px;
    padding: 7px;
    svg {
      color: white;
      font-size: 14px;
    }
  }

  &__empty {
    width: 100%;
    height: 100%;
  }

  // item style
  .TopFivePropertiesItem {
    gap: 20px;
    position: relative;
    transition: 0.2s;

    &__icon {
      min-width: 40px;
      min-height: 40px;
      border-radius: 34px;
      background-color: rgba($primary, 0.1);
      color: $primary;
      font-size: 16px;
      transition: 0.2s;
    }

    &__text {
      gap: 5px;
      font-weight: 200;
      transition: 0.2s;
      word-break: break-all;

      &-count {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}
