.ServicesList {
    height: calc(100vh - 370px);
    gap: 8px;

    &__header {
        gap: 20px;
        display: grid;
        grid-template-columns: 40% calc(15% - 20px) calc(45% - 20px);
        padding: 5px 25px;
        font-size: 12px;
    }
}