$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
$gradient-color-darken: linear-gradient(92.57deg, darken(#9872fe ,53%) 4.91%, darken(#59fee0, 45%) 95.86%);
$gradient-color-lighten: linear-gradient(92.57deg, lighten(#9872fe ,23%) 4.91%, lighten(#59fee0, 15%) 95.86%);

.GradientBorderButton {
    background: $gradient-color;
    border-radius: 5px;
    padding: 2px;
    width: max-content;
    &__content {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        font-weight: 500;
        gap: 5px;
        background: var(--aiBtnBG);
        cursor: pointer;
        svg {
            fill: var(--sidebarLink);
        }
        &:hover {
            opacity: 1;
            background: transparent !important;
            color: whitesmoke !important;
            transition: all 0.3s ease;
        }
    }
}