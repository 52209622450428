@import "../../../styles/colors.scss";

.InsightDetails{
    background: var(--appBG);
    box-shadow: 0px 14px 24px 0px #00000033;
    padding: 16px 32px;

    &__heading {
      font-size: 14px !important;
      font-weight: 700 !important;
      text-align: left;
      color: var(--text) !important;
      margin-bottom: 10px;
    }

    &__paragraph-wrapper {
      --gradient-color: linear-gradient(90.34deg, #9872FE 0.14%, #33FDD8 100%);
      background: var(--gradient-color);
      border-radius: 5px;
      padding: 1px;
      margin-bottom: 16px;
      p{
        font-size: 14px;
        padding: 12px 16px;
        margin: 0 !important;
        border-radius: 5px;
        background-color: var(--aiBtnBG);
        color: var(--text);
      }
      span{
        color: rgba($color: white, $alpha: 0.8);
      }
    }

    &__body {
      display: grid;
      grid-template-columns: auto 1fr auto 1fr;
      row-gap: 16px;
      grid-auto-flow: dense;
      > label{
        font-weight: 700;
        margin-right: 14px;
        flex-shrink: 0;
        align-self: center;
        &.Notification{
          grid-column: 1;
        }
      }
      > div{
        display: flex;
        align-items: center;
        &.Notification{
          grid-column: 2;
          cursor: pointer;
          span{
            color: var(--primary);
            text-decoration: underline;
          }
        }
       img{
        max-height: 16px;
        object-fit: contain;
       }
      }
      button{
        height: unset !important;
        padding: 0 !important;
      }
      &__popover-content{
        list-style-type: none;
        padding: 0 12px;
        margin-bottom: 0 !important;
        li{
          display: flex;
          align-items: center;
          gap: 10px;
          &:not(:last-child){
            margin-bottom: 10px;
          }
          img,svg{
            height: 16px !important;
            object-fit: contain;
          }
        }
       
      }
      &__badge{
        .ant-badge-count{
          background-color: var(--primary) !important;
          p{
            font-size: 10px;
          }
        }
      }
      &__multi-image{
        display: flex;
        flex-direction: row;
        img{
          max-height: 20px !important;
          animation: fadeIn 1s;
          &:not(:first-child){
            margin-left: -5px;
            box-shadow: -5px 0px 2px rgba($color: black, $alpha: 0.5);
          }
        }
        &:hover {
          .InsightDetails__body__multi-image-purpleCount {
            background-color: var(--primary);
            color: white;
          }
        }
        &-purpleCount {
          &.wider { padding: 1px };
          height: 20px;
          width: 21px;
          background-color: var(--assetTypeCountBG);
          color: var(--assetTypeCountColor);
          animation: fadeIn 1s;
          margin-left: -9px;
          font-weight: 900;
          padding: 4px;
          font-size: 10px;
          border-radius: 50%;
          box-shadow: -5px 0px 5px rgba($color: black, $alpha: 0.5);
          &:hover {
            background-color: var(--primary);
            color: white;
          }
        }
      }
    }
      .ant-btn[disabled] {
        opacity: 0.4 !important;
      }

      &__disabled {
          opacity: 0.4;
          cursor: not-allowed;
      }
}

.CodeDrawer {
  position: relative;
  width: 100%;

  &__body {
    background-color: var(--codeBG);
    height: 66vh;
    max-height: 66vh;
    overflow-y: auto;
    color: white;
    padding-left: 15px;
    padding-bottom: 80px;
  }

}
.non-scroll{
  overflow: hidden;
}