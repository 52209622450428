.AssetComments {
  &__item {
      padding: 30px 0;
      min-height: 100px;
      &:not(:last-child) {
          border-bottom: 1px solid var(--separatorColor);
      }

      &:first-child {
          padding-top: 0;
      }
  }
}
