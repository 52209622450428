.popup-cmd-k {
    top: -210px !important;
    padding: 1.5px !important;
    background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
    border-radius: 10px;
    .ant-modal{
		position: relative;
		.ant-modal-content{
            background-color: #212133bd;
			.ant-list-bordered {
				border: none !important;
			}
		}
  	}

    .search-list-items{
        position: absolute;
        width: 700px;
        background-color: #212133bd;
    }

    &__autocomplete{
        width: 700px
    }

    .ant-select-selection-search{
        color: white !important;
        height: 50px !important;
    }

    .ant-select{
        border: 0px !important
    }
    .ant-select-selector{
        width: 100% !important;
        height: 50px !important;
        align-items: center !important;
        padding: 0px 0px 0px 43px !important;
    }

    .search-icon-style{
        position: absolute;
        left: 13px;
        top: 17px;
        font-size: 15pt !important;
    }

    .ant-select-selection-search-input{
        height: inherit !important;
        font-size: 20pt;
    }

    .ant-select-selection-search{
        padding: 0px 0px 0px 30px !important;
    }

    .ant-select-selection-placeholder{
        font-size: 20pt !important;
    }
}


.light{
    .popup-cmd-k {
        .ant-select-selection-search-input{
            color: #3e3b3b !important;
        }
    }
}