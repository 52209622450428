.ConnectedServices {
    &__body {
        &__popover-content{
            list-style-type: none;
            padding: 0 12px;
            margin-bottom: 0 !important;
            li{
              display: flex;
              align-items: center;
              gap: 10px;
              &:not(:last-child){
                margin-bottom: 10px;
              }
              img, svg {
                height: 16px !important;
                object-fit: contain;
              }
            }
           
        }
        &__multi-image{
            display: flex;
            flex-direction: row;
            &-container {
                padding: 2px;
                height: 20px;
                width: 21px;
                background: var(--connectedServicesBG);
                box-shadow: 0px 0px 1px rgba($color: black, $alpha: 0.5);
                border-radius: 3px;
                animation: fadeIn 0.3s;
                &:not(:first-child){
                    margin-left: -5px;
                    box-shadow: 0px 0px 1px rgba($color: black, $alpha: 0.5);
                }
                img{
                    max-height: 16px !important;
                    max-width: 16px !important;
                }
                &.popover {
                    border-radius: 3px;
                }
            }
            &:hover {
              .ConnectedServices__body__multi-image-purpleCount {
                background-color: var(--primary);
                color: white;
              }
            }
            &-purpleCount {
              &.wider { padding: 1px };
              height: 20px;
              width: 21px;
              background-color: var(--assetTypeCountBG);
              color: var(--assetTypeCountColor);
              margin-left: -9px;
              font-weight: 600;
              padding: 4px;
              font-size: 10px;
              border-radius: 5px;
              box-shadow: 0px 0px 1px rgba($color: black, $alpha: 0.5);
              &:hover {
                background-color: var(--primary);
                color: white;
              }
            }
          }
    }
 
}
.ConnectedServicesModal {
  position: relative;

  &-line {
    right: 50%;
    height: 100%;
    position: absolute;
    width: 1px;
    background-color: var(--layoutBorder);
  }
  &__green {
    color: #1AD598;
  }
  &__red {
    color: #EB4C3D;
  }
  &__integrations {
    overflow-y: auto;
    flex: 1;
    padding: 20px 30px;
  }
  &-services {
    &:hover {
      .ConnectedServicesModal-installedOn, .ConnectedServicesModal-missingOn, .ConnectedServicesModal-serviceName {
        color: var(--primary);
        text-decoration: underline;
      }
    }
  }
  &-installedOn {
    margin-right: 60px;
    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }
  }
  &-missingOn {
    margin-right: 30px;
    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }
  }

}