.IntegrationDiscoveryCard{
    padding: 16px 24px !important;
    border-radius: 5px !important;
    flex-direction: column;
    border: 1px solid #2B2941;
    &__title{
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--primary);
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--separatorColor);
        margin-bottom: 16px;
        svg:first-child{
            width: 16px !important;
            height: 16px;
        }
        span{
            font-size: 16px;
            font-weight: 700;
        }
       
    }
    &__content{
        color: var(--textSuperLight);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        p{
            font-size: 14px;
            margin-bottom: 16px;
        }
        &-count{
            font-size: 32px;
            margin-bottom: 10px;
        }
        &-footer{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            &.start{
                align-items: flex-start;
            }
            &-date{
                font-size: 12px;
            }
            button{
                margin-left: auto !important;
            }
        }
    }
}