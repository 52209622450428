.InsightsPage {
    display: grid;
    height: calc(100vh - 152px);
    grid-template-columns: 210px calc(100% - 210px - 20px);
    gap: 20px;
    transition: grid-template-columns 0.3s ease;
    overflow-y: hidden;

    &__content {
        height: calc(100vh - 152px);

        &__insightsHeader {
            flex-grow: 7;
        }

        &__insightsFrameworksScore {
            flex-grow: 3;
        }
    }

    &.collapsed {
        grid-template-columns: 40px calc(100% - 40px - 20px);
    }
}

.InsightsPage__content__grid {
    display: flex;
    gap: 10px;
}
