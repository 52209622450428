@import "../../../styles/colors.scss";

.SingleIntegrationFilters {
  // flex-wrap: wrap;
  gap: 20px;
  &__blocks {
    flex-wrap: wrap;
    justify-content: flex-end;

    &-clear {
      cursor: pointer;
      background-color: $primary;
      border: none;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      color: white !important;
      padding: 5px;
      transition: all 0.3s ease;

      &:hover {
        transition: all 0.3s ease;
        background-color: darken($primary, 5);  
      }

      &:disabled,
        [disabled="disabled"] {
          cursor: not-allowed;
          pointer-events: none;
          color: var(--disableColor) !important;
          background-color: var(--disableBG);
        }
    }
  }
}