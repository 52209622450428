.IacStacks {
    height: calc((100vh - 154px));
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.IacTableActions {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
  