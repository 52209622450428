.InsihtsFrameworksScore {
    flex: 3;

    &__selected {
        height: 96px;
        display: grid !important;
        grid-template-rows: auto 1fr auto;
        grid-gap: 1px;
        border: 1px solid transparent !important;
        background: linear-gradient(#212133, #212133) padding-box,
            linear-gradient(to right, #a185f8 0%, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0 100%) border-box;

        &__container {
            display: flex !important;
            align-items: center;
            gap: 5px;
        }

        &.light {
            background: linear-gradient(#ffffff, #ffffff) padding-box,
                linear-gradient(to right, #a185f8 0%, #8eb0f0 16.59%, #7adbe6 33.72%, #6ef6e0 43.32%, #59fee0 100%) border-box;
        }
    }
}
