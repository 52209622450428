@import "../../../styles/colors.scss";

.IacStacksTable {
  overflow-x: hidden;
  position: relative;
  padding-bottom: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding-left: 20px;
  // padding-right: 20px;

  >.TableWrapper{
    flex-grow: 1;
  }
  .MuiDataGrid-root {
    font-family: inherit;
  }

  &__row {
    td {
      // dont include the checkbox cell
      &:not(:first-child) {
        color: rgba($text, 0.45);
      }
    }
  }

  &__header {
    width: 100%;
    min-height: 35px;
    gap: 20px;
    justify-content: flex-end;
    margin-bottom: 20px;
    // padding: 10px;
    // padding-top: 25px;

    &-actions {
      gap: 10px;
      min-width: max-content;
    }
  }

  &__container {
    display: flex;
    width: 20px;
  }

  &__statusFlag {
    margin: 0 !important;
    padding-left: 15px !important;
    &.success {
      color: var(--green);
    }
    &.failure {
      color: var(--red);
    }
    &.progress {
      color: var(--orange);
    }
    &.unknown {
      color: var(--grey);
    }
  }
  &__runtimeType{
    display: flex;
    align-items: center;
    img{
      margin-right: 8px;
      object-fit: contain;
    }
  }

  &__item {
    gap: 5px;
    font-weight: bold;

    &-status {
      gap: 8px;
      svg {
        color: $primary;
        font-size: "10px";
      }
    }

    &-list {
      gap: 10px;
      &-cloudIcon {
        position: relative;
        width: min-content;

        &-bubble {
          position: absolute;
          bottom: 1px;
          right: -7px;
          border-radius: 50%;
          width: 14px;
          height: 14px;
          font-size: 10px;
          color: white;
          font-weight: bold;
          border: 1px solid $appBG;
          background-color: #c4b1fa;
        }
      }
    }

    &-title {
      max-width: 70%;
    }
    &-warningIcon {
      color: #FFA302;
      position: absolute;
      left: 37px;
      &.mod {
        position: unset;
        left: unset;
        &.mag5 {margin-left: 5px;}
        &.mag52 {margin-left: 52px;}
      }
    }
    &-partials {
      margin-left: 5px;
    }
    &-partial {
      &:hover {
        color: var(--primary);
      }
    }
    &-cloudIcon {
      position: relative;
      width: min-content;
      &-icon {
        opacity: 0.6;
      }
      &-classification {
        fill: rgba($text, 0.3);
        transform: translateY(3px);
      }

      &-classificationActive {
        fill: $primary;
        transform: translateY(3px);
      }

      &-bubble {
        position: absolute;
        bottom: 4px;
        right: -4px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        font-size: 10px;
        color: white;
        font-weight: bold;
        border: 1px solid $appBG;

        &.orange {
          background-color: rgba(lighten($orange, 5), 1);
        }

        &.blue {
          background-color: rgba(lighten($darkBlue, 30), 1);
        }
        &.darkPurple {
          background-color: #7d15b2;
        }
        &.black {
          background-color: black;
        }
        &.darkBlue {
          background-color: #002879;
        }
        &.green {
          background-color: #5eb02e;
        }
        &.darkGreen {
          background-color: #387f8c;
        }
      }
    }

    &-graph {
      width: 100%;
      &-popup {
        gap: 10px;
        font-size: 12px;
        color: var(--textLighter);

        &-circle {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          &.notSupported {
            background-color: var(--notSupported);
          }
          &.empty {
            background-color: transparent;
            border: 1px solid var(--header-user-menu-arrow);
          }
          &.managed {
            background-color: $turquoise;
          }
          &.ghost {
            background-color: $salmon;
          }
          &.modified {
            background-color: $purple;
          }
          &.unmanaged {
            background-color: $strawberry;
          }
          &.iacIgnored {
            background-color: $blueIacIgnored;
          }
          &.unknown {
            background-color: $strawberry;
          }
        }
      }
      &-inner {
        display: block;
        height: 10px;
        &.singleChild {
          border-radius: 10px;
        }
        &.multipleChildren {
          &:first-child {
            border-radius: 10px 0px 0px 10px;
          }
          &:last-child { 
            border-radius: 0px 10px 10px 0px;
          }
        }
        &.notSupported {
          background-color: var(--notSupported);
        }
        &.empty {
          background-color: transparent;
          border: 1px solid var(--header-user-menu-arrow);
        }
        &.modified {
          background-color: $purple;
        }
        &.managed {
          background-color: $turquoise;
        }
        &.ghost {
          background-color: $salmon;
        }
        &.modified {
          background-color: $purple;
        }
        &.unmanaged {
          background-color: $strawberry;
        }
        &.unknown {
          background-color: $strawberry;
        }
        &.iacIgnored {
          background-color: $blueIacIgnored;
        }
      }
    }

    &-view-asstes-btn {
      &-flag {
        cursor: pointer;
        gap: 8px;
        color: $primary;
        border: 1px solid transparent;
        background-color: $lightPrimary;
        font-size: 10px;
        font-weight: bold;
        border-radius: 24px;
        padding: 4px 8px;
        height: 20px;
        transition: all 0.2s ease;
        &:hover {
          border-color: $primary;
          background-color: darken($lightPrimary, 3);
        }
      }
    }
  }

  &__cloudIcon {
    width: auto;
    height: 16px;
    transition: filter 0.2s ease;

    &.k8s {
      max-height: 17px;
    }
    &.aws {
      max-height: 12px;
    }
  }

  &__iacIcon {
    width: 18px;
    height: 18px;
    transition: filter 0.2s ease;
  }

  &__iacCell {
    gap: 5px;
  }

  &__centered {
    display: flex;
    justify-self: center;
    text-align: center;
  }

  &__actions {
    gap: 5px;
    &-configIcon {
      opacity: 0.6;
      cursor: pointer;
      transition: opacity 0.2s ease;
      padding: 5px;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__flag {
    font-size: 10px;
    font-weight: bold;
    border-radius: 24px;
    padding: 4px 11px;
    line-height: normal;
    min-width: 80px;
    width: max-content;
    background-color: lighten($lightGrey, 17);
    gap: 5px;

    &-icon {
      width: 12px;
    }

    &-analyzer {
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 14px !important;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  // return cloud icon it's color when row is selected
  .ant-table-row-selected {
    .InventoryTable__cloudIcon,
    .InventoryTable__iacIcon {
      -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
      filter: grayscale(0);
      transition: filter 0.2s ease;
    }

    .managedFlag,
    .driftFlag,
    .unmanagedFlag {
      background-color: transparent;
    }
  }

  &__configIcon {
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s ease;
    padding: 5px;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .cloudProviderCell {
    padding-left: 0 !important;
  }

  .Status-column {
    text-transform: lowercase;
  }
  .MuiDataGrid-detailPanel {
    left: 10px !important;
  }
}
