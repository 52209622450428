.PlaylistBtn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: var(--card);
    background: var(--gradientColorBG);
    border-radius: 10px;
    transition: all 0.3s ease;
    animation: fadeIn 0.3s;
}
.PlaylistDrawer {
    padding: 1px;
    background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
    border-radius: 7px;
    position: fixed;
    position: absolute;
    top: 10%;
    right: 25px;
    height: 80%;
    width: 350px;
    z-index: 1000;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(92.57deg, #59fee0 4.91%, #9872fe 95.86%);
        border-radius: 7px;
        filter: blur(2px);
    }
    &__container {
        height: 100%;
        background: var(--card);
        transition: all 0.5s ease;
        border-radius: 7px;
    }
    &__header {
        padding: 20px;
        border-radius: 7px 7px 0px 0px;
        background: linear-gradient(90deg, var(--appBG), transparent);
    }
    &__content {
        padding: 0px 20px;
        padding-top: 10px;
        overflow-y: auto;
        height: calc(100% - 80px);
    }

    &__item {
        span {
            animation: moveMenuLine 0.5s ease;
        }
        &.selected {
            div {
                width: 700px;
                position: absolute;
                height: 500px;
                right: 109%;
                background: var(--gradientColorBG);
            }
        }
    }
    &-closeBtn {
        margin-top: 2px;
        width: 14px !important;
        height: auto;
    }
    &-scrollIcon {
        color: var(--text);
        bottom: 10px;
        right: calc(50% - 10px);
        position: absolute;
        &:hover {
            color: var(--primary);
        }
    }
}
.light {
    .PlaylistDrawer__header {
        border-bottom: 1px solid var(--CiCollapseBG);
    }
}