.ProvisionLangs {
  color: #808081;

  &__item {
    transition: all 0.2s ease;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px 8px;
    line-height: normal;
    width: max-content;
    border: 1px solid #3c3c50;

    &:hover {
      color: var(--primary);
      transition: all 0.2s ease;
    }

    &.active {
      border-color: var(--primary);
      color: var(--primary);
      transition: all 0.2s ease;
    }
  }
}
