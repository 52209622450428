// light mode layout
$sidebarBG: #041527;
$appBG: #f6f6f6;
$cardBG: #ffffff;
$headerBG: #ffffff;
$codeBG: #171721;
$codeCard: #30304a;

// typography
$text: #252525;
$textLighter: #515151;
$darkGrey: #737373;
$grey: #929292;
$lightGrey: #c7c7c7;
$darkBlue: #1c3250;
$footerText: #8d8d8d;

// branding
$primary: #9872fe;
$lightPrimary: rgba(152, 114, 254, 0.2);
$red: #e36f7e;
$dashboardRed: #ce5d85;
$green: #71c9ca;
$lightGreen: #59fee0;
$orange: #f78c6c;
$offWhite: #fafafa;
$blue: #59a0fe;
$pink: #f257bb;

// flags/ indications
$redFlag: #eb4c3d;
$darkRedFlag: #da3032;
$greenFlag: #3db15e;
$orangeFlag: #ffa302;
$ghost: #aa9876;

// forms/ inputs
$inputBorder: #dfe2e5;
$inputBG: #f8f9fa;
$inputColor: rgba(4, 21, 39, 0.5);
$label: rgba(4, 21, 39, 0.5);

// separator line
$separatorColor: rgba(#515151, 0.15);
$codeSeperator: rgba(255, 255, 255, 0.418);

// insights
$misconfig: #e31174;
$reliability: #0090ff;
$optimization: #7cbdbf;

// IAC STATUS
$purple: #9872fe; //drift
$purple_Lilac: rgba($purple, 0.2); //drift-bg
$turquoise: #67cebc; //codify

$light_turquoise: rgba($turquoise, 0.2); // codify-bg
$salmon: #f4b483; //ghost
$light_salmon: rgba($salmon, 0.2); //ghost-bg
$strawberry: #d76089; //unmanaged
$light_strawberry: rgba($strawberry, 0.2); //unmanaged-bg
$blueIacIgnored: #2f76cb; //iac-ignored
$light_blue: rgba($blueIacIgnored, 0.2);
