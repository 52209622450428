.BitBucketIntegration {
    .BitBucketOverview {
      width: 50%;
      gap: 30px;
    }
  
    .BitBucketConfiguration {
        width: 50%;
        &__intro {
          gap: 8px;
          &-list {
              padding-left: 15px;
              margin-top: 10px;
              margin-bottom: 0px;
              li:not(:last-child) { 
                margin-bottom: 10px;  
              }
            }
        }
    }
  
    .small-dark-blue {
      line-height: 20px;
    }
  }
  