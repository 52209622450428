.AiRemediationVc {

    .AiRemediationVc__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
    }

    .ant-collapse {
        display: grid;
        gap: 10px;
    }

    .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 35px !important;
        font-size: 14px;
        cursor: pointer;
    }

    .panel-header-content {
        display: flex;
        align-items: center;
    }

    .panel-title {
        margin-left: 12px;
    }

    .panel-count {
        margin-left: 15px;
        color: #888;
    }

    .panel-link {
        margin-left: 15px;
        height: 18px;

        &:hover {
            color: var(--primary);
          }
    }

    .panel-icon-provider {
        margin-left: 4px;
        img {
            width: 100%;
            height: 100%;
            max-width: 24px;
            max-height: 24px;
        }
    }

    .panel-icon {
        margin-left: 4px;
        padding-top: 6px;
        svg {
            width: 100%;
            height: 100%;
            max-width: 24px;
            max-height: 14px;
          }
    }

    .panel-arrow-icon {
        margin-left: auto;
        color: rgba(110, 104, 137, 1) !important;
        transition: transform 0.4s ease;
    }

    .ant-collapse-item {
        border: 1px solid rgba(135, 128, 166, 0.5);
        background-color: rgba(33, 33, 51, 1);
        width: 100%;
        border-radius: 4px;
    }

    .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 2px 8px;
        font-size: 14px;
    }

    .ant-collapse-content {
        font-size: 14px;
    }

    .ant-collapse-content-box {
        border-bottom: 0 !important;
    }

    .ant-collapse-arrow {
        display: none !important;
    }

    .ant-collapse-item-active .panel-arrow-icon {
        transform: rotate(90deg) !important;
    }

    .ant-radio-wrapper {
        display: flex;
        align-items: center;
    }

    .ant-radio {
        margin-right: 8px;
    }

    .ant-radio-inner {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }

      &__pagination{
        border-bottom: 0px !important;
        overflow: visible !important;
        margin-right: 10px;
        .MuiIconButton-root:hover {
          color: var(--primary);
        }
        .MuiTablePagination-toolbar {
          color: var(--textLighter);
          p {
            margin-top: 10px !important;
          }
          .MuiTablePagination-selectIcon {
             color: var(--textLighter);
             &:hover { color: var(--primary); };
          }
          .MuiTablePagination-displayedRows {
            margin-top: 12px !important;
         }
         .MuiTablePagination-select {
            margin-bottom: 2px !important;
         }
        }
      }
    
      .ant-radio-inner::after {
        top: 23% !important;
        left: 20% !important;
      }

          .AiRemediationVc__no-data {
              transform: translateY(60%);
          }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.light{
    .AiRemediationVc {
        .ant-collapse-item {
            background-color: rgba(255, 255, 255, 1);
            border: 1px solid rgba(146, 146, 146, 0.5);

            .ant-collapse-content-box {
                background-color: white !important;
                color: white !important;
            }
        }
        &.AiRemediationProvider{
            .AiRemediationCliCollapse {
                background-color:  rgba(246, 247, 248, 1);
            }
            .panel-header {
                color: rgba(51, 51, 51, 1);
            }
    
            .ant-collapse-item {
                background-color: rgba(255, 255, 255, 1);
                border: 1px solid rgba(146, 146, 146, 0.5);
            }
        }

    }
}