.AppPopoverSelect_content {

    min-width: 208px;
    padding: 0 15px;
    h5 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
        color: white;
    }

    &-list {
        display: flex !important;
        flex-direction: column !important;
        gap: 8px !important;
        .ant-checkbox-wrapper {
            align-items: flex-start !important;
        }
        label {
            span {
                font-size: 12px !important;
                color: rgba($color: white, $alpha: 0.5) !important;
            }

            &.ant-checkbox-wrapper-checked {
                span {
                    color: var(--primary) !important;
                }

                .ant-checkbox-inner {
                    border-color: var(--primary) !important;
                }
            }

            .ant-checkbox-inner {
                border-color: #B9B9B9 !important;
                width: 14px;
                height: 14px;

                &:after {
                    width: 5.714286px;
                    height: 8.142857px;
                    top: 48%;
                    left: 20%;
                }
            }
        }
        &-withIcon{
           img{
            height: 14px ;
            object-fit: contain;
            margin:0 8px 0 4px;
           }
        }
    }

    >button {
        margin-top: 15px !important;
        display: block;
        width: 100%;
        box-shadow:  none !important;
    }
}

.AppPopoverSelect__button {
    display: flex !important;
    align-items: center !important;
    padding: 0px 12px !important;
    height: 24px !important;
    border-radius: 5px !important;
    border: 0.5px;
    justify-content: space-between;
    &-label {
        svg{
            margin-left: 6px;
            font-size: 12px !important;
            line-height: 14.06px;
        } 
    } 
    &-count{
        font-size: 9px;
        color: white;
        background-color: var(--primary);
        border-radius: 50%;
        animation: fadeIn 0.5s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
    }
}

.light{
    .AppPopoverSelect_content {
            &-list {
                label {
                    span {
                        color: rgba($color: #333333, $alpha: .5) !important;
                    }
    
                    &.ant-checkbox-wrapper-checked {
                        span {
                            color: var(--primary) !important;
                        }
                    }
                }
    
            }
        }
    .AppPopoverSelect__button{
        background-color: transparent !important;
        color: #8E8D8F !important;
        &-label {
            svg{
                fill: red !important;
            }
        }
     
    }
}