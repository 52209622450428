@import "../../../styles/colors.scss";

.DemoAlert {
  width: 100%;
  background: linear-gradient(92.57deg, #1b0161 4.91%, #01715c 95.86%);
  line-height: normal;
  border-bottom: 1px solid #47455e;
  padding: 10px 0;
  gap: 10px;

  &__text {
    gap: 5px;

    &-seperator {
      font-size: 20px;
      transform: translateY(-1px);
    }
  }

  &__btn {
    color: white;
    padding: 5px 16px;
    border-radius: 25px;
    border: 1px solid white;
    background-color: transparent;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: white;
      transition: all .3s ease;
      color: black;
    }
  }

  #lid {
    animation: blink 2.4s infinite;
  }
  #eye {
    animation: squeeze 4.4s infinite;
  }
  
  @keyframes blink {
    90% {
      transform: none;
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(15px) scaleY(0)
    }
    100% {
      animation-timing-function: ease-out;
    }
  }
  @keyframes squeeze {
    90% {
      transform: none;
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(9px) scaleY(0.4)
    }
    100% {
      animation-timing-function: ease-out;
    }
  }
}
