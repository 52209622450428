.Mutation {
    border: 1px solid #353344;
    border-radius: 10px;
    font-size: 13px;
    margin: 20px 0 16px 0;
    background-color: rgba(21, 21, 28, 1);

    &__header {
        background-color: rgba(44, 45, 64, 1);
        width: 100%;
        height: 32px;
        border-radius: 8px 8px 0px 0px;
    }

    &__attributes {
        overflow: auto;
        border-radius: 0 0 10px 10px;
    }

    &__before {
        margin-left: 2%;
        font-size: 12px;
        font-weight: 600;
    }

    &__after {
        margin-right: 45%;
        font-size: 12px;
        font-weight: 600;
    }
}
