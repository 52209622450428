
.FiltersChat {
    padding: 1px;
    background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
    border-radius: 10px;
    position: fixed;
    bottom: 20px;
    right: 100px;
    height: 55%;
    width: 400px;
    z-index: 1000;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(92.57deg, #59fee0 4.91%, #9872fe 95.86%);
        border-radius: 10px;
        filter: blur(10px);
    }
    &__container {
        height: 100%;
        background: var(--card);
        transition: all 0.5s ease;
        border-radius: 10px;
       
    }
    &__conversationTime {
        position: absolute;
        top: 12px;
        right: calc(50% - 15px);
    }
    &__header {
        padding: 20px;
        border-radius: 10px 10px 0px 0px;
        background-color: var(--aiBtnBG);
        &-icon {
            width: 36px;
            height: 36px;
        }
    }
    &__title {
        font-size: 15px;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        left: 13px;
        top: 13px;
        width: 18px !important;
        height: 18px;
        color: var(--text);
        &:hover {
            color: var(--primary);
        }
    }
    &__preview {
        position: absolute;
        right: 13px;
        top: 13px;
    }
    &__messages {
        position: relative;
        overflow: auto;
        height: calc(100% - 98.5px - 50px); // 98px is header height and 48px is input height
        padding: 15px;
        margin-right: 1px;
        background-color: var(--appBG);
        padding-top: 40px;
        .TypingDots {
            align-self: flex-end !important;
            margin-right: 60px !important;
        }
    }
    &__input {
        position: relative;
        height: 50px;
        background-color: var(--appBG);
        border-radius: 0px 0px 10px 10px;
        border-top: 1px solid var(--CiCollapseBG);
        &-sendIcon {
            position: absolute;
            right: 10px;
            animation: moveAndRotate 1.5s ease;
        }
        &-thinkerIcon {
            position: absolute;
            left: 25px;
            height: 11px;
            width: 13px;
            transform: rotate(270deg);
            path {
                fill: var(--disableColor);
            }
        }
        input {
            border-width: 0px !important;
            border-radius: 20px!important;
            border-right-color: transparent !important;
            margin-left: 15px !important;
            width: calc(100% - 50px) !important;
            background-color: var(--card) !important;
            padding-left: 30px !important;
            &:focus {
                box-shadow: none !important;
            }
            &::placeholder {
                font-size: 12px !important;
                color: var(--disableColor) !important;
            }
        }
    }
    &__message {
        position: relative;
        width: calc(100% - 80px);
        padding: 10px;
        border-radius: 3px 3px 3px 0px;
        background-color: var(--aiBtnBG);
        color: var(--text);
        margin-left: 40px;
        margin-right: 40px;
        &.thinkerbell {
            background-color: var(--primary);
            color: white;
            border-radius: 3px 3px 0px 3px;
            margin-left: 60px;
            width: calc(100% - 100px);
        }
        &-time { 
            align-self: flex-end;
            font-size: 11px !important;
        }
        &-creator {
            &-thinker {
                height: 11px;
                width: 13px;
                margin-bottom: 7px;
            }
        }
        &-userIcon {
            left: -40px;
            bottom: 0;
            position: absolute;
            height: 27px;
            width: 27px;
            min-width: 27px;
            border-radius: 34px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border: 1px solid var(--primary);
        }
        &-thinkerbellIcon {
            position: absolute;
            right: -40px;
            height: 27px;
            padding: 2px;
            width: 27px;
            min-width: 25px;
            border-radius: 34px;
            border: 1px solid #59fee070;
            box-shadow: 1px 1px 5px 0px #59fee070;
            bottom: 0;
        }
        
    }
}