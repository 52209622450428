@import "../../../styles/colors.scss";

.ProviderAccountsTable {
    &__rowTable {
        background-color: var(--card);
        height: 100%;
        width: 100%;
        padding: 0 0 0 70px;
    }

    &__flag {
      cursor: pointer;
      width: min-content;
      gap: 5px;
      color: $primary;
      border: 1px solid transparent;
      background-color: $lightPrimary;
      font-size: 10px;
      font-weight: bold;
      border-radius: 24px;
      padding: 4px 8px;
      height: 20px;
      transition: all 0.2s ease;
      &:hover {
        border-color: $primary;
        background-color: darken($lightPrimary, 3);
      }
    }

    .MuiDataGrid-columnHeaders {
      min-height: 28px !important;
      max-height: 28px !important;
      line-height: 28px !important;
      position: sticky;
      z-index: 2;
    }
    .MuiDataGrid-columnHeader {
      height: 28px !important;
    }
    .MuiDataGrid-virtualScroller {
      margin-top: 0 !important;
    }
    .MuiDataGrid-main {
      overflow: visible;
    }
}
