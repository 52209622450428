@import "../../styles//colors.scss";

.Dashboard {
    width: 100%;
    gap: 25px;

    &__onBoarding {
        gap: 10px;
        &-card {
            &-profile {
                gap: 10px;
                position: relative;
                &-img {
                    border-radius: 34px;
                    width: 100px;
                    height: 100px;
                    position: absolute;

                    &-inner {
                        position: absolute;
                        top: 21%;
                        left: 3%;
                        width: 58%;
                        height: 58%;
                        border-radius: 34px;
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }
                &-status {
                    gap: 10px;
                    font-size: 16px;
                    color: var(--textLighter);
                    font-weight: 300;
                    &-header {
                        color: var(--primary);
                        font-size: 18px;
                    }
                }
            }
        }

        &-close {
            font-size: 12px;
            transform: translateY(-25px);
        }
    }

    &__right-middle-section {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__click-here-button {
        cursor: pointer;
    }

    &__total_assets {
    }

    &__tagging-coverage {
        outline: 1px solid transparent;
        transition: outline-color 0.3s ease;
        cursor: pointer;
        margin: 10px 0px 10px 0px;

        &.inner-style {
            &:hover {
                outline-color: var(--primary);
            }
            &.light {
                background-color: rgba(99, 183, 115, 0.3) !important;
            }
        }
    }

    &__assetWrapper {
        display: flex;
        gap: 15px;
    }

    &__top {
        gap: 24px;
        height: 130px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    &__center {
        display: grid;
        grid-template-columns: 1fr 5fr 2fr;
        gap: 24px;

        &.initialScan {
            grid-template-columns: 1fr 5fr;
        }

        &-container {
            gap: 10px;

            &-assets {
                @media (min-width: 1550px) {
                    gap: 50px;
                }
                &-iacCoverage {
                    padding: 40px 20px !important;
                    &-title {
                        margin-left: 20px;
                    }
                }
                &-tooltip {
                    transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
                    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
                    border-radius: 3px;
                    padding: 0 !important;
                    cursor: default;
                    .ant-tooltip-inner {
                        background-color: var(--card) !important;
                        color: var(--text) !important;
                        font-size: 12px;
                    }
                }
                &-graph {
                    max-height: 173px;
                    margin-left: -28px;
                    &-total {
                        gap: 25px;
                        cursor: default;
                        &-seperator {
                            height: 100%;
                            width: 1px;
                            background-color: var(--textSuperLight);
                        }
                        &-block {
                            gap: 4px;
                            &-number {
                                font-size: 26px;
                                font-weight: 400;
                            }
                            &-label {
                                font-weight: 300;
                                color: var(--textLighter);
                            }
                        }
                    }
                }
                &-states {
                    justify-content: center;
                    display: flex;
                    flex-direction: row;
                    grid-template-columns: 200px 200px;
                    gap: 20px;
                }
            }
            &-iac {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
                gap: 15px;
                height: 100%;
            }
        }
        &-total {
            align-content: center;
            flex: 2;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-info {
                border: 1px solid var(--primary);
                color: var(--textLighter);
                border-radius: 50%;
                width: 16px !important;
                height: 16px;
                padding: 3px;
                &:hover {
                    color: var(--primary);
                }
            }
            &-text {
                font-weight: bold;
                font-size: 35px;
                animation: moveAndRotate 1s;
            }
            &-coming {
                position: relative;
                align-self: center;
                &-text {
                    position: absolute;
                    bottom: 12px;
                    left: 41px;
                    color: var(--primary);
                    display: flex;
                    gap: 4px;
                }
            }
            &:hover {
                border-color: var(--primary);
            }
        }
        &-otherTypes {
            flex: 9;
            background-color: var(--card);
            width: 100%;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 10px;
            border: 1px solid var(--cardBorder);
            height: 100%;
        }

        &-totalOther {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }

    &__centerBottom {
        display: grid;
        grid-template-columns: calc(25% - 20px) 75%;
        gap: 24px;

        &-container {
            gap: 10px;
        }
    }

    &__bottom {
        gap: 10px;
        &-insights {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
        }
    }
}
