.GcpIntegration {

    .GcpCreation {
        gap: 30px;
        width: 50%;

        &__intro {
            gap: 10px;
            &-orderedlist {
                padding-left: 10px;
                margin-top: 10px;
                margin-bottom: 0px;
                li {
                  padding: 0 !important;
                }
                li:not(:last-child) { 
                  margin-bottom: 10px;  
                }
              }
        }
    }
}