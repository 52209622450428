.InsightDetails {
  // adjustments for the categories dropdown
  .InsightCategoryLabel__labelWrapper {
    height: 32px;
  }

  &__categories {
    &-item {
      .ant-select-item-option-content {
        .InsightCategoryLabel {
          height: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
