@import "../../../styles/colors.scss";

.IntegrationCountCard {
  color: var(--textLighter) !important;
  width: 100%;
  height: 180px;
  padding: 10px 20px;
  gap: 30px;
  transition: all 0.2s ease !important;

  &__text {
    gap: 15px;

    img {
      height: 38px;
      width: auto;
    }

    .okta-icon {
      height: 28px;
    }

    .newrelic-icon {
      height: 38px;
    }

    .aws-icon {
      height: 36px;
    }

    .cloudFlare-icon {
      height: 32px;
    }
  }

  &__count {
    background-color: var(--innerCard);
    min-width: 45px;
    height: 24px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
  }

  &:hover {
    box-shadow: none;
    border-color: rgba($primary, 0.8);
    background-color: rgba($primary, 0.1);
  }
}
