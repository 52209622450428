$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.UnsavedCodifyModal {
    border-radius: 7px;
    background: $gradient-color;
    padding: 2px !important;
    &__container {
        padding: 30px 40px;
        background: var(--header-btn-BG);
        border-radius: 7px;
    }
    &__wrap {
        margin-left: 280px;
    }
    &__buttons {
        align-self: flex-end;
    }
}