.VcsCode-link {
  color: var(--textLighter) !important;
}

.VcsCode-integ {
  gap: 10px;

  img {
    height: 20px;
    width: auto;
  }
}
