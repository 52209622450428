.TagsTable {
    height: 100%;
    padding: 10px 20px;
    &__header {
        display: grid;
        width: 100%;
        background: #2C2D40;
        grid-template-columns: 50% 50%;
        border: 1px solid var(--cardBorder);
        border-radius: 4px 4px 0px 0px;
        padding: 10px 15px;
    }
    &__body {
        position: absolute;
        background-color: var(--card);
        border: 1px solid var(--cardBorder);
        border-radius: 0px 0px 4px 4px;
        width: calc(100% - 40px);
        &-divider {
            position: absolute;
            width: 1px;
            right: calc(50% + 10px);
            height: 100%;
            background-color: var(--cardBorder);
        }
        &-row {
            border-bottom: 1px solid var(--cardBorder);
            display: grid;
            grid-template-columns: 50% 50%;
            padding: 10px 15px;
            &:last-child {
                border-radius: 0px 0px 4px 4px;
                border-bottom: 0px;
            }
        }
    }
}
.light {
    .TagsTable__header {
        background: #EDEDED;
        border: 1px solid #EAEAEA;
    }
    .TagsTable__body {
        border: 1px solid #EAEAEA;
        &-divider {
            background-color: #EAEAEA;
        }
        &-row {
            border-bottom: 1px solid #EAEAEA; 
        }
    }
}