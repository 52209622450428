@import "../../styles/colors.scss";

.PercentageBar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &__progress-container {
    width: 100%;
    border-radius: 10px;
    display: flex;
    border: 1px solid transparent;
    background: linear-gradient(#373535, #373535) padding-box, 
    linear-gradient(to right,       #a185f8 0%,
    #8eb0f0 16.59%,
    #7adbe6 33.72%,
    #6ef6e0 43.32%,
    #59fee0 100%) border-box;
  }

  .light{
    background: linear-gradient(#e3e0e0, #e3e0e0) padding-box, 
    linear-gradient(to right,       #a185f8 0%,
    #8eb0f0 16.59%,
    #7adbe6 33.72%,
    #6ef6e0 43.32%,
    #59fee0 100%) border-box;
  }

  &__progress-bar {
    height: 100%;
    background: linear-gradient(
      90deg,
      #a185f8 0%,
      #8eb0f0 16.59%,
      #7adbe6 33.72%,
      #6ef6e0 43.32%,
      #59fee0 100%
    );
    border-radius: 8px 0 0 8px;
  }

  &__progress-label {
    margin-right: 10px;
    width: 24px;
    height: 12px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    text-align: left;
    color: rgba(152, 114, 254, 1);
  }

  .one {
    height: 65%;
    margin-top: 2px;
  }

  .two {
    height: 76%;
    margin-top: 1px;
  }

  .three {
    height: 76%;
    margin-top: 1px;
  }

  .four {
    height: 81%;
    margin-top: 1px;
  }
  .hundred {
    border-radius: 8px;
  }

  .light {
    .progress-container {
      background: linear-gradient(#e3e0e0, #e3e0e0) padding-box, 
      linear-gradient(to right,       #a185f8 0%,
      #8eb0f0 16.59%,
      #7adbe6 33.72%,
      #6ef6e0 43.32%,
      #59fee0 100%) border-box;
    }
  }

}

.PercentageBar-Tooltip{
  .ant-tooltip-inner {
    text-align: center !important;
  }
}
