.Guardrails {
    &__header {
        &-icon {
           color: var(--text);
        }
    }
    &__list { overflow: auto; }

    &__empty {
        align-items: center;
        margin: 0 auto;
        margin-top: 100px;
        &-icon {
           color: var(--ciEmptyImgBottomLine);
        }
        &-description, &-title {
            max-width: 630px;
            text-align: center;
            line-height: 1.5;
        }
    }
}