.ExcludedDrifts {
  gap: 20px;

  &__wrapper {
    &-header {
      margin-bottom: 15px;
      gap: 40%;
    }
  }
}
