@import "../../styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Tektur:wght@700&display=swap");
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.welcome-block-user-login-container {
  min-height: 100vh;
  width: 100vw;
  background: #220A35 url("https://infralight-templates-public.s3.amazonaws.com/auth0/erancloud_n_a_distant_galaxy_800k.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  grid-template-columns: 3fr 1fr;
  justify-content: center;
  align-items: center;

  &__close {
    background-color: transparent;
    position: absolute;
    top: 9px;
    right: 20px;
    border: none;
    cursor: pointer;
    color: #909099;
    font-size: 16pt;
    transition: all 0.2s ease;
  }
}

.welcome-block-user-headers {
    bottom: 6%;
    left: 6%;
    display: flex;
    position: absolute;
    color: white;

    &__slogan-title{
      display: flex;
      flex-direction: column;
      font-family: 'Tektur', cursive;
    }

    &__slogan-title-main{
      font-size: 50px;
      font-weight: bold;
    }

    &__slogan-title-sub {
      font-size: 35px;
      font-weight: bold;
      color: #75FFD7;
    }
}


.welcome-block-user-card{
  background: #220A35 url("../../../src/Images/general_icons/welcome-block-users/back_ground_gradient.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 511px;
  width: 623px;
  border-radius: 25px;
  color: white;
  position: relative;
  justify-content: center;
  display: flex;
  padding-top: 35px;
  background-color: transparent;
  opacity: 0.91;

  &__image-style{
    position: absolute;
    height: 424px;
  }

  &__text-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
    z-index: 2;
    padding-top: 70px;
    align-self: center;
    font-size: 14px;
  }

  &__description-container{
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    font-family: "Roboto", sans-serif;
  
    &__sub-title{
        padding-bottom: 17px;
    }
  }

  &__sub-header-text{
      color: #9872FE;
      margin-top: 10px;
      font-weight: bold;
      padding-bottom: 9px;
  }

  &__link-container {
      display: flex;
      gap: 10px;
      padding-top: 3px;
  }

  &__text-link{
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
  }

  &__login-link{
      color: #9872FE;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      padding-top: 22px;
      position: absolute;
      bottom: 72px;
  }
}

.calendly-badge-widget{
  display: none !important;
}