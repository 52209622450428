@import "../../../../styles/colors.scss";
.DriftExclusionModal {
  padding: 0px 0px 30px 0px;
  .content-wrapper {
    padding: 15px 30px !important;

    &__rowWrapper {
      gap: 10px;
      color: $textLighter;
      margin-bottom: 1.2rem;
    }

   
  }
  &__provider {
    img {
      width: 20px;
      height: auto;
      margin-right: 10px;
    }
  }
  
}
