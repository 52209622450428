.SingleIntegration {
    gap: 30px !important;
    &.tableView{
        > div:nth-child(2){
            order: 1;
        }
        > div:last-child{
            order: 2;
        }
    }
}