.SuspendedAccount {
  background-color: #181825;
  height: 100vh;
  width: 100%;
  color: #121212;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  gap: 30px;
  position: relative;

  &__title {
    font-size: 32px;
  }

  &__text {
    font-size: 18px;
    gap: 5px;
  }

  &__actions {
    gap: 20px;
    margin-top: 35px;
  }

  &__link {
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: 300ms;
    border-bottom: 1px solid transparent;

    &:hover {
      color: rgba(255, 255, 255, 0.7);
      border-color: rgba(255, 255, 255, 0.7);
      transition: all 0.3s ease;
    }
  }

  .logout {
    position: absolute;
    top: 40px;
    right: 40px;
    font-weight: 500;
  }
}
