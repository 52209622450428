@import "../../../../styles/colors.scss";

.AssetHistoryV2 {
  gap: 20px;
  height: 94%;

  &.row {
    align-items: start;
  }

  &__title {

    &-text {
      font-size: 16px;
    }
  }

  &__revisions {
    height: 100%;
    position: relative;

    &__calandar-container {
      display: flex;
    }

    &__calandar-row {
      z-index: 2;
       text-align: center;
       flex: 1;
    }

    &-items {
      position: relative;
      height: 100%;
      z-index: 1;
      margin-bottom: 20px;
      overflow-y: auto;
      scroll-behavior: smooth;
    }
  }

    &__scrollIcon {
      background: rgba(33, 33, 50, 1);
      border: 1px solid var(--separatorColor);
      font-size: 33px;
      border-radius: 50%;
      padding: 5px;
      position: absolute;
      cursor: pointer;  
      bottom: 40px;
      left: 3.5%;
      transition: all 0.4s ease-in;
      z-index: 4;
      width: 30px;
      height: 30px;
      border: 1px solid rgba(133, 89, 254, 1);
      color: #8559FE;

      &.loading {
        animation: downAndUp 2s infinite;
      }
  
      &:hover {
        background-color: var(--primary);
        color: white;
  
      }
  
      &.disabled {
        cursor: not-allowed;
        color: var(--disableColor);
        border: 1px solid var(--disableColor);
  
        &:hover {
          background-color: var(--disableColor);
          color: white;
        }
      }
    }
  
    &__loadMore {
      font-size: 12px;
      text-align: center;
      z-index: 4;
      font-weight: 400;
      color: #B1B1B7 !important;
      margin-bottom: 15px;
      width: 10%;
      transition: all 1s ease-out;

      &.loading {
        animation: downAndUp 2s infinite;
      }
    }

  gap: 10px;
  position: relative;

  &__calendarIcon {
    width: 30px;
    height: 30px;
    fill: rgba(33, 33, 50, 1);
  }

  &__sub-title {
    justify-content: space-around !important;
    flex: 9;
  }

  &__line {
    height: calc(100% - 80px);
    margin-bottom: 20px;
    margin-top: 11px;
    width: 2px;
    z-index: 0;
    position: absolute;
    left: 4.85%;
    background: var(--separatorColor);
  }


}

.RevisionCompareV2 {

  &__empty {
    height: 100%;
    width: 100%;
    min-height: 300px;
  }

  &__identical {
    border-radius: 0px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 9;
    background-color: var(--assetCodeBG);
    border: 1px solid rgba(56, 51, 81, 1);
    border-radius: 0px 10px 10px 0px;

  }

  &__diff {
    width: 100%;
    align-items: baseline !important;
    // padding: 10px 10px 10px 0px;
    flex: 10;
  
    &.selected,
    &:hover {
      .RevisionCompareV2__diff-code,
      .RevisionCompareV2__identical {
        border-color: rgba(133, 89, 254, 1)
      }
      .RevisionCompareV2__diff-code.date {
        border-color: rgba(133, 89, 254, 1);
        background-color: rgba(133, 89, 254, 1);
      }
    }

    &-code {
      flex: 9;
      background-color: var(--assetCodeBG);
      color: white;
      border: 1px solid rgba(56, 51, 81, 1);
      border-radius: 0px 10px 10px 10px;
      z-index: 2;
      left: 113px;
      align-self: flex-start;
      transition: all 0.3s ease;
      min-height: 80px;

      &.date {
        flex: 1;
        border-radius: 10px 0px 0px 10px;
        border-right: none;
        z-index: 3;
        padding: 12px;
        margin-right: -1px;
        display: flex;
        color: var(--textLighter);
        background-color: rgba(33, 33, 50, 1);
        align-self: flex-start;
        min-height: unset;

      }
    }

    &-title {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;

      &-block {
        padding: 10px;
        width: 50%;
        font-weight: bold;
        opacity: .8;
      }
    }
  }
}
.light {

  .RevisionCompareV2__diff-code,
  .RevisionCompareV2__diff-code.date,
  .RevisionCompareV2__identical {
    background-color: var(--assetCodeBG);
    color: rgba(255, 255, 255, 1);
    border-color: var(--assetCodeBG);
  }

  .AssetHistoryV2__calendarIcon {
    fill: rgba(138, 112, 244, 0.27);
    border-color: rgba(133, 89, 254, 1);
  }

  .AssetHistoryV2__scrollIcon {
    background-color: rgba(138, 112, 244, 0.27);
    border-color: rgba(133, 89, 254, 1);
    &.disabled {
      cursor: not-allowed;
      color: var(--disableColor);
      border: 1px solid var(--disableColor);
      background-color: var(--disableColor);
      color: white;
    }
  }
}