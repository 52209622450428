@import "../../styles/colors.scss";

.AntdTableWrapper {
  // .ant-table-tbody > tr.ant-table-row:hover > td {
  //   // background-color: var(--tableRowHoverBG) !important;
  // }

  .ant-table-expanded-row-fixed {
    padding: 8px 8px !important;
  }

  .ant-table-row {
    td {
      color: var(--textSuperLight);
    }
  }
}
