.SingleIntegrationList {
  min-height: calc(100vh - 140px - 170px);
  gap: 40px;
  &__items {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    flex-wrap: wrap;
  }
}
