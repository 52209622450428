.AkamaiIntegration {

    .AkamaiCreation {
        width: 50%;
        gap: 25px; 

        &__intro {
            gap: 3px;
        }
    }
}