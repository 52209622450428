.ClickOps__Collapse {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: rgba(44, 45, 64, 1);
    border-radius: 0 0 8px 8px;
    font-size: 12px;

    .ant-collapse-header {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        padding: 12px 0px 8px 12px;
        font-size: 12px !important;
        border-bottom: none !important;
        transform: translate(-5px, -13px) !important;
    }

    .ant-collapse-item {
        width: 100%;
    }

    .ant-collapse-item-active .ant-collapse-header {
        color: white !important;
        background-color: rgba(44, 45, 64, 1) !important;
    }

    .ant-collapse-item > .ant-collapse-header:hover {
        background-color: rgba(44, 45, 64, 1) !important;
    }

    .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow {
        color: white !important;
    }

    .ant-collapse-content-box {
        border-bottom: none !important;
    }

    .ant-collapse-content {
        background-color: rgba(44, 45, 64, 1) !important;
    }

    .sagiv-test {
        .ant-collapse-header {
            // transform: translate(-16px, -13px) !important;
        }
    }
}
