.ServiceListItem {
  background-color: var(--innerCard);
  min-height: 40px;
  border-bottom: 1px solid var(--separatorColor);
  gap: 20px;
  display: grid;
  grid-template-columns: 40% calc(15% - 20px) calc(45% - 20px);
  align-items: center;
  transition: all 0.2s ease;

  &.notActive {
    padding: 5px 25px;
    border-radius: 5px;
    border: 1px solid var(--separatorColor);
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease;
      border-color: var(--primary);
      background-color: var(--lightPrimary);
    }
  }

  &.active {
    border-color: var(--primary);
    border-width: 2px;
    background-color: var(--lightPrimary);
    padding: 10px 25px 10px 20px;
  }

  &__nameCol {
    gap: 15px;

    &-arrow {
      opacity: 0.5;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
        color: var(--primary);
        transition: all 0.2s ease;
      }
    }

    &-imgWrapper {
      border-radius: 5px;
      overflow: hidden;
      width: 23px;
      height: 23px;
      flex-shrink: 0;
      img {
        max-height: 25px;
        width: 23px;
        object-fit: contain;
      }
    }
  }
}
