.AppHeader {
  display: flex;
  height: 100%;
  padding: 15px 30px;
  line-height: normal;

  &__routerIndication {
    justify-content: center;
    gap: 12px;
    min-width: max-content;
    width: 100%;
  }

  &__gradient-border{
    background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
    border-radius: 5px;
    padding: 1px;
    width: max-content;
  }

  &__documentation-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 9px 9px;
    background: #2d2c45;
    gap: 6px;
    cursor: pointer;
    border-radius: 5px;
  }

  &__actions {
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
  }
  &__invite {
    height: 22px;
  }
  .GradientBorderButton {
    padding: 1px;
    border-radius: 10px;
    &__content {
      width: 38px;
      height: 38px;
      border-radius: 10px;
    }
  }
}