@import "./colors.scss";
$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
.small-muted {
  font-size: 13px;
  line-height: 15px;
  opacity: 0.5;
}

.muted {
  font-size: 14px;
  line-height: 22px;
  opacity: 0.4;
}
.small-muted {
  font-size: 12px;
  line-height: 22px;
  opacity: 0.4;
}

.big {
  font-size: 25px;
  line-height: 29px;
}

.title {
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}

.med-title {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

.sub-title {
  font-size: 12px;
  line-height: 18px;
  color: var(--textLighter);
}

.small-title {
  font-size: 14px;
  opacity: 0.75;
}

.bold {
  font-weight: bold;
}

.med-bold {
  font-weight: 500;
}

.centered {
  text-align: center;
}

.purple-text {
  color: $primary;
}

.red-text {
  color: $red;
}

.modal-title {
  font-size: 16px;
  color: var(--text);
  font-weight: bold;
}

.text {
  color: var(--textLighter);
}

.small-dark-blue {
  font-size: 14px;
  color: var(--textLighter);
  line-height: 18px;
}

.dark-blue {
  font-size: 14px;
  color: var(--textLighter);
  line-height: 18px;
}

.form-label {
  color: var(--label);
  display: block;
  margin-bottom: 5px;
}

.underline {
  text-decoration: underline !important;
}

.lighterText {
  color: var(--textSuperLight);
}

.table-sum-text {
  color: var(--textSuperLight);
  height: 20px;
}

.purple-link-dark-background {
  color: lighten($primary, 3) !important;
  transition: all 0.4s ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
    transition: all 0.4s ease;
  }
}
.pointer {
  cursor: pointer;
}
.default-cursor {
  cursor: default !important;
}
.code-text {
  font-family: "Courier New", monospace;
}

.basic-link {
  cursor: pointer;
  transition: 300ms;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: inherit;
    transition: all 0.3s ease;
  }
}
.basic-loading, .basic-100 {
  height: 100%;
}

.disabledText {
  color: var(--disableColor);
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 { font-size: 13px !important;}
.font-14 { font-size: 14px !important; }
.font-15 { font-size: 15px !important; }
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.wb-text {
  color: var(--text);
}
.gradient-text {
  background: $gradient-color;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}