// overrides the default styles for the EventCenter component
.app-content-wrapper {
    padding: 15px;
}

.EventCenter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 200px);

    &__menu {
        display: flex;
        flex-direction: row;
        background-color: #212132;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #6d688980;
        gap: 13px;

        .ant-divider {
            margin: unset !important;
            margin-top: 12px !important;
        }

        &__title {
            margin-top: 15px;
        }

        &__clear {
            display: flex;
            align-items: center;
            margin-right: 15px;
            gap: 15px;

            .ant-divider-vertical {
                top: -0.4em !important;
                height: 1.1em !important;
            }

            &__text {
                color: #9872fe;
                text-decoration: underline;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }

    &__table-wrapper {
        flex: 8;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #212132;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #6d688980;
    }

    &__table {
        .MuiDataGrid-detailPanel {
            width: 90%;
            background-color: var(--card);
        }

        &__eventType {
            display: flex;
        }
    }

    &__no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #ffffff;
        flex: 1;
    }

    .MuiDataGrid-detailPanelToggleCell {
        background-image: url("../../Images/general_icons/chevron_event_center.svg") !important;
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
        color: white !important;

        button {
            color: white !important;
        }

        .MuiSvgIcon-root {
            display: none !important;
        }
    }

    .MuiDataGrid-detailPanelToggleCell--expanded {
        background-image: url("../../Images/general_icons/chevron_event_center.svg") !important;
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        transform: rotate(360deg);

        .MuiSvgIcon-root {
            display: none !important;
        }
    }

    .MuiDataGrid-row--detailPanelExpanded {
        .MuiDataGrid-cell {
            border-bottom: none !important;
        }

        & + .MuiDataGrid-row {
            .MuiDataGrid-cell {
                border-top: 1px solid #51515159 !important;
            }
        }
    }

    .MuiDataGrid-cell--textLeft[data-field="type"] {
        display: flex !important;
        justify-content: center !important;
    }

    .MuiDataGrid-cell--textLeft[data-field="assetType"] {
        display: flex !important;
    }

    .MuiDataGrid-footerContainer {
        padding-right: 38px !important;
        margin-bottom: -12px !important;
    }
}

.light {
    .EventCenter {
        &__menu,
        &__table-wrapper {
            background-color: #ffffff;
        }
    }
}
