@import '../../../styles/colors.scss';

.RemoveResourceModal {
  &__content {
    width: 100%;
    height: calc(100vh - 77px);
    background-color: var(--codeBG);
    padding: 20px 30px;
    overflow-y: auto;
    color: white;
    position: relative;

    &-body {
      padding: 40px;

      &-url {
        margin-top: 1.5rem;
      }
    }

    &-success {
      padding: 40px;
      color: white !important;

      &-form {
        gap: 5px;
        color: white !important;
      }

      img {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
      }

      &-checkbox {
        gap: 15px;
      }

      &-submit {
        gap: 10px;
      }
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    height: 7vh;
  }
}
