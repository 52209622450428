.EventViewerContainer {
  gap: 10px;
  height: 100%;
}
.EventGraphContainer {
  &__column {
    height: 170px;
    width: 100%;
  }
  &__tooltip {
    padding: 10px 10px 10px 1px;
    gap: 5px;
    &-item {
      gap: 10px;
      &-mark {
        position: relative;
        height: 15px;
        width: 6px;
        &-dot {
          background-color: inherit;
          position: absolute;
          z-index: 2;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-50%);
        }
        &-line {
          background-color: inherit;
          margin: auto;
          border-radius: 10px;
          height: 100%;
          width: 2px;
        }
      }
     
    }
  }
}
.EventTableContainer {
  background: var(--card);
  border: 1px solid var(--eventViewerBorder);
  border-radius: 10px;
  height: 100%;
  gap: 10px;
  padding: 15px 30px;
  overflow: auto;
  &__tools {
    gap: 10px;
  }
}
.EventResReqContainer {
  background: var(--card);
  margin: 4px 0px;
  overflow: auto;
  max-height: 250px;
  gap: 5px;
  width: auto;
}

.ReqResCont {
  padding-top: 5px;
  gap: 10px;
}

.ReqResBox {
  background-color: var(--eventReqResBG);
  color: white;
  border: 1px solid var(--eventViewerBorder);
  border-radius: 5px;
  height: 100%;
  width: 93%;
  position: relative;
}

.EventTableSelect {
  transition: all 0.4s ease;
  width: 280px;
  height: 40px;
  border-radius: 10px !important;
  border: 1px solid var(--header-btn-border) !important;
  color: var(--textLighter) !important;
  background-color: var(--header-btn-BG) !important;
  &__graph {
    min-width: 160px;
    align-self: flex-end;
    .ant-select-arrow {
      color: var(--primary) !important;
    }
  }
  //ant-select overrides
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
    overflow: hidden !important;
    max-width: 97% !important;
    margin-top: 5px !important;
  }
  .ant-select-selection-placeholder {
    color: var(--textLighter) !important;
    transition: all 0.3s ease;
  }
  .ant-select-arrow {
    color: var(--primary) !important;
  }
  .ant-select-selection-search {
    color: var(--textLighter) !important;
  }
  &:focus {
    transition: all 0.3s ease;
    border-color: var(--primary) !important;
  }
  &:hover {
    border-color: var(--primary) !important;
  }
}
