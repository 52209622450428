@import "../../../styles/colors.scss";

.InitialDiscoveryAlert {
  width: 100%;
  background-color: #312c50;
  background-color: #312C50;
  line-height: normal;
  color: $primary !important;
  font-weight: bold;
  gap: 7px;
  padding: 15px 0;

  &__spinner {
      font-size: 20px;
      margin-right: 5px; //extra space than gap
  }
  &__info {
      transform: translateY(1px);
  }

  &.light {
    background-color: #E3DBF9;
  }
}
.light {
  .InitialDiscoveryAlert {
      background-color: #E3DBF9;
  }
}


