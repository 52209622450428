@import "../../../styles/colors.scss";

.UserConnected {
  gap: 10px;

  &__title {
    &-sub {
      font-size: 12px;
      color: var(--textSuperLight);
    }
  }

  &__wrapper {
    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }

  &__arrow {
    color: $lightGrey;
    font-size: 11px;
  }

  &__user {
    width: 34px;
    height: 34px;
    border-radius: 34px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
