@import "../../styles/colors.scss";

.CopyBox {
  width: 100%;
  border: 1px solid $inputBorder;
  border-radius: 4px;
  padding: 10px 13px;
  align-items: center;
  font-family: "Courier New", monospace;

  &.darkMode {
    background: $sidebarBG;
    max-height: 82vh;
    overflow-y: auto;
    color: var(--text);
    position: relative;
    border-radius: 4px;
  }

  &.wordBreak {
    word-break: break-all;
    white-space: pre;
  }

  &__icon {
    color: $primary;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s ease;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    &.darkMode {
      opacity: 1;
    }
  }

  &__text {
    width: 85%;
    line-break: anywhere;
  }
}

// dark light support
.light {
  .CopyBox {
    background: $inputBG;
  }
}
.dark {
  .CopyBox {
    background: $codeCard;
    border-color: transparent;
  }
}