@import "../../styles/colors.scss";

  .ProgressBar {
    &__links {
        width: 100%;
        max-width: 1000px;
    
        &-blocks {
          display: grid;
        }
    
        &-progress {
          height: 3px;
          background-color: var(--separatorColor);
          &-inner {
            height: 3px;
            transition: width .5s ease;
            background: linear-gradient(to right, #7F6AF4 0%, #88E3E4 100%);
          }
        }
      }
  }
