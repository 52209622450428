.PolicyCollapseFilters {
  &__title {
    height: 30px;
    border-bottom: 1px solid var(--separatorColor);
    color: var(--disableColor);
  }

  & > * {
    border-bottom: 1px solid var(--separatorColor);
  }

  &__panel {
    &-header {
      height: 20px;
      font-size: 13px;
      svg {
        min-width: 14px;
      }
    }
  }
  .clear-all {
    margin-left: 90px;
  }
}
