.LottieLoadingPai {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 80%;
    overflow: hidden;
  
    &__text {
      margin: 0 !important;
      margin-top: 30px !important;
    }
  
    .LottieLoadingPai-animation {
      width: 200px !important;
      height: 200px !important;
      max-width: 100%;
      max-height: 100%;
      background-color: #212133 !important;
    }
  }