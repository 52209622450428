@import "../../../../styles/colors.scss";

.AwsIntegration {
    .AwsOverview {
        width: 0%;
        gap: 5px;
        ul {
            li {
                margin-top: 10px;
            }
        }
    }
    &__autoDiscovery {
        margin-top: 20px;
        margin-bottom: 10px;
        .AppToggle {
            &__text {
                font-weight: 700;
            }
        }
        &-subtitle {
            font-size: 12px;
            margin-left: 35px;
            color: #d3d3d6;
            margin-top: 4px;
        }
    }
    .event-driven-block {
        margin-left: 7px;
        padding-left: 20px;
        padding-top: 10px;
        border-left: 2px solid var(--separatorColor);
    }

    .AccessKeypair {
        gap: 20px;
        width: 40%;
    }

    .AwsIntegrationConfiguration {
        gap: 20px;
        width: 50%;
        &__introList {
            padding-left: 15px;
            margin-top: 10px;
            margin-bottom: 0px;
            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        &__eventDriven {
            margin-top: 20px;
            margin-bottom: 10px;
            &-container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            &-icon {
                margin-left: 7px;
                height: 16px;
                width: auto;
                opacity: 0.8;
            }
            &-text {
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
            }
        }

        &__multipleAccount {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }
    }

    .AwsFinalInstructions {
        width: 88%;
        gap: 40px;
        .DarkCopyBox__wrapper-box {
            align-items: baseline !important;
        }

        &__intro {
            gap: 2px;
            &-list {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 0px;
                li:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            &-orderedlist {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 0px;
                li {
                    padding: 0 !important;
                }
                li:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        &__cmds {
            gap: 30px;

            &-item {
                font-size: 12px;
                gap: 11px;

                label {
                    color: $sidebarBG;
                    opacity: 0.5;
                }

                &-codeBlock {
                    background-color: $sidebarBG;
                    border-radius: 10px;
                    position: relative;

                    &-actions {
                        gap: 15px;
                        position: absolute;
                        top: 15px;
                        right: 15px;
                    }
                }
            }
        }
    }
}
