@import "../../../styles/colors.scss";

.SingleView {
  width: 100% !important;
  padding: 10px 30px !important;
  transition: background-color 0.2s ease;
  gap: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--separatorColor);
  }
  &.active {
    background-color: $lightPrimary;
  }

  &:hover {
    background-color: $lightPrimary;
  }

  .ant-radio-wrapper {
    width: 100% !important;
  }

  .ant-radio {
    transform: translateY(-1px);
    align-self: baseline;

    .ant-radio-inner {
      width: 14px !important;
      height: 14px !important;
    }
  }

  &__actions {
    width: 100%;
    font-size: 13px;
    width: max-content;
    gap: 10px;

    &-default {
      width: max-content;
      background-color: var(--disableBG);
      color: var(--textSuperLight) !important;
      border-radius: 24px;
      border: 1px solid transparent;
      font-size: 11px !important;
      padding: 5px 11px 4px 11px;
      line-height: 12px !important;
      transition: all 0.2s ease;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        color: var(--text) !important;
        border: 1px solid var(--textSuperLight);
      }

      &.default {
        background-color: $lightPrimary;
        color: $primary !important;
        border-color: rgba($primary, .5);
      }
      &.disabled {
        background-color: var(--disableBG);
        color: var(--textSuperLight) !important;
        border-color: transparent;
        cursor: not-allowed;
        &:hover {
          color: var(--textSuperLight) !important;
          border-color: transparent;
        }
      }
    }
  }

  &__firefly {
    width: 20px;
    height: auto;
    margin-left: 15px;
  }
}
