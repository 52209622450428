.InsightRego {
  width: 100%;

  &__wrapper {
    border: 1px solid var(--primary);
    overflow: hidden;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    min-height: 350px;
  }
}
