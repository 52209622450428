@import "../../styles/colors.scss";

.SearchBox {
  position: relative;
  transition: all .4s ease;
  width: 100%;
  border-radius: 4px;
  height: 35px;
  background-color: transparent;

  &.withBackground {
    background-color: #f3eeffc4;
  }
  &.darkMode {
    background: linear-gradient(0deg, #181824, #181824),
    linear-gradient(0deg, #303148, #303148);
    border: 1px solid #303148;
    box-shadow: 0px 2px 5px 0px #0000001A;
    border-radius: 8px;
    input{
      &::placeholder{
        color: white;
        font-size: 14px;
      }
    }
  }

  &.grayModeBorder {
    background-color: #F8F9FA;

    input {
      border: 1px solid #DFE2E5 !important;
    }
  }


  &.rightSide {
    input {
      &::placeholder {
        text-align: right;
      }
    }

    .SearchBox__magnify {
      position: absolute;
      left: 178px ;
      top: 50%;
    }

    &:hover {
      input {
        &::placeholder {
          opacity: 0;
        }
      }

      .SearchBox__magnify {
        opacity: 0 !important;
      }
    }
  }

  &:hover {
    transition: all .2s ease;
    // background-color: white;

    input {
      transition: all .2s ease;

      &::placeholder {
        color: $text;
        transition: all .2s ease;
      }
    }

    .SearchBox__magnify {
      color: $primary;
      transition: all .2s ease;
    }
  }

  input {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 35px;
    padding-left: 38px;
    width: 100%;
    transition: all .2s ease;

    &::placeholder {
      color: $primary;
      transition: all .2s ease;
    }

    &:focus {
      transition: all .2s ease;
    }

    &:hover {
      border-color: rgba($primary, .5) !important;
    }
  }

  &__magnify {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgba($primary, .8);
    transition: opacity .3s ease;
  }

  &__clear {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgba($text, 0.4);
    transition: all .3s ease;
    font-size: 12px;
    color: rgba($primary, .7);
    cursor: pointer;

    &:hover {
        color: rgba($primary, .9);
        transition: all .3s ease;
    }
  }

  &.grayMode {
    input {
      &::placeholder {
        color: var(--textSuperLight);
        transition: all .2s ease;
      }
    }

    .SearchBox__magnify {
      color: var(--textSuperLight);;
    }
  }

  &.noBorder {
    input {
      &:hover {
        border-color: transparent !important;
      }
    }
  }
}

.light{
  .SearchBox.darkMode {
    background: white;
    border: 1px solid #EAEAEA;
    box-shadow:none;
    input{
      &::placeholder{
        color: #8E8D8F;
      }
    }
  }
}