@import "../../../styles/colors.scss";

.Vulnerabilities-body {
  height: 60vh;
  max-height: 60vh;
}
.Vulnerabilities {
  height: 100%;
  width: 100%;
  //   padding: 25px 35px;

  &__loaderContainer {
    height: 60vh;
  }

  &__container {
    height: 100%;
    width: 100%;
    gap: 20px;
    &-filters {
      gap: 10px;
      &-button {
        height: 25px;
        font-size: 14px !important;
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 10px;
        gap: 8px;
        transition: all 0.3s ease;
        min-width: max-content;
        color: white !important;

        &.critical {
          background-color: #317c88;
          border-left: 5px solid #3fd3d8;
        }
        &.high {
          background-color: #2d3f5c;
          border-left: 5px solid #498bc8;
        }
        &.medium {
          background-color: #39457c;
          border-left: 5px solid #4e64c0;
        }
        &.low {
          background-color: #463f95;
          border-left: 5px solid #5246b9;
        }
        &.unknown {
          background-color: #482e8a;
          border-left: 5px solid #562ab2;
        }

        &:hover {
          &.critical {
            background-color: lighten(#317c88, 3);
          }
          &.high {
            background-color: lighten(#39457c, 3);
          }
          &.medium {
            background-color: lighten(#39457c, 3);
          }
          &.low {
            background-color: lighten(#463f95, 3);
          }
          &.unknown {
            background-color: lighten(#482e8a, 3);
          }
        }

        &.active {
          &.critical {
            border-color: #3fd3d8;
            background-color: darken(#317c88, 3);
          }
          &.high {
            border-color: #498bc8;
            background-color: darken(#2d3f5c, 3);
          }
          &.medium {
            border-color: #4e64c0;
            background-color: darken(#39457c, 3);
          }
          &.low {
            border-color: #5246b9;
            background-color: darken(#463f95, 3);
          }
          &.unknown {
            border-color: #562ab2;
            background-color: darken(#482e8a, 3);
          }
        }
      }
    }
    &-list {
      //   border: 1px solid var(--cardBorder);
      //   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }
  }
}
