@import "../../../../styles/colors.scss";

.AssetsList {
  width: 100%;
  min-height: 100vh;

  &__title {
    display: flex;
    align-items: center;
    padding-left: 40px;
    height: 10vh;

    &-sum {
      margin-left: 5px;
      font-weight: normal;
      line-height: normal;
    }
  }

  &__loading {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__execute {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-btn {
      padding: 5px 10px;
      opacity: .8;
      font-weight: bold;
      border-radius: 4px;
      color: var(--textSuperLight);
      border: 2px solid transparent;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        border-color: var(--textSuperLight);
      }

      &-icon {
        font-size: 11px;
        margin-left: 5px;
      }
    }
  }

  &__assets {
    height: 90vh;
    overflow-y: auto;
    &-item {
      padding: 20px;
      border-top: 1px solid var(--separatorColor);
    }
  }
}
