@import "../../../styles/colors.scss";
.DriftAnalyzeDrawer {
  position: relative;

  &__impact-box {
    background: var(--driftBlockBG);
    border-radius: 4px;
  }

  &__divider {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  &__wrapper {
    width: 100%;
    min-height: 100vh;

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      opacity: 0.3;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    z-index: 2;
    min-height: 100vh;
    min-height: 100vh;
    overflow-x: auto;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;
      padding-left: 40px;
      height: 11vh;

      &-sub {
        gap: 20px;
        color: var(--sidebarLink);
      }
    }

    &-body {
      background-color: var(--codeBG);
      height: 82vh;
      max-height: 82vh;
      padding: 20px 30px;
      overflow-y: auto;
      color: white;
      position: relative;
      .ActionBtn__icon {
        margin-top: 5px !important;
      }
      &-header {
        display: grid;
        grid-template-columns: 35px 20% repeat(2, 1fr);
        gap: 20px;
        color: $primary;
        font-weight: bold;
        padding: 20px 30px 20px 15px;
      }
      &-diff {
        &-header {
          padding: 10px;
          padding-left: 30px;
          border-radius: 4px 4px 0px 0px;
          background: var(--driftDetailsHeaderBG);
          color: white;
          display: grid;
          grid-template-columns: 26% 37% 37%;
        }
        &-code {
          overflow: auto;
          background-color: var(--driftDetailsRowBG);
          border: 1px solid var(--driftDetailsBorder);
          border-radius: 0px 0px 4px 4px;
          border-bottom: 0px;
          &.json { 
            border-radius: 4px;
            overflow: unset;
          }
          &.withShowMore {
            border-radius: 0px;
          }
        }
        &-showMoreLess {
          background-color: var(--driftShowMoreBG);
          padding: 10px 15px;
          border-radius: 0px 0px 4px 4px;
          &-icon {
            cursor: pointer;
            height: 14px;
            width: 14px !important;
            padding: 3px;
            color: var(--CiCollapseBG);
            background: var(--primary);
          }
          &-text {
            cursor: pointer;
            color: var(--primary);
            text-decoration: underline;
          }
        }
        &-row {
          padding: 10px;
          padding-left: 30px;
          display: grid;
          grid-template-columns: 26% 74%;
          border-bottom: 1px solid var(--driftDetailsBorder);
          animation: fadeIn 0.7s;
          &.excluded {
            padding-left: 32px;
            .DriftAnalyzeDrawer__wrapper-body-diff-row-title, .CodeDiff {
              opacity: 0.5;
            }
          }
          &-title {
              color: white;
          }
        }
      }


      &-item {
        padding: 20px 30px 20px 15px;
        display: grid;
        grid-template-columns: 35px 20% repeat(2, 1fr);
        gap: 20px;

        // change grid when code diff library enabled
        &.diff-code-grid {
          grid-template-columns: 35px 20% 1fr !important;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--codeSeperator);
        }

        &.excluded {
          background-color: rgba($primary, 0.2);
        }

        &-diffcode {
          word-break: break-all !important;
          white-space: pre-wrap !important;
          color: rgb(197, 200, 198) !important;
          font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier,
            monospace !important;
        }

        &__tagswrapper {
          align-items: flex-start;
          gap: 15px;
          max-width: 100%;
          overflow: auto;
        }

        &-title {
          font-weight: bold;
          opacity: 0.7;
        }

        &-flag {
          position: absolute;
          left: 40px;
          border-radius: 24px;
          color: $primary;
          font-size: 12px;
          align-self: flex-start;
          padding-top: 5px;
        }

        &-code {
          align-items: flex-start !important;
          justify-content: flex-start;
          align-self: flex-start;
        }

        pre {
          padding: 0 !important;
        }
      }
      &-items {
        max-width: 100%;
      }
    }
    &-footer {
      height: 8vh;
      background-color: var(--card);
      width: 100%;
      gap: 15px;
      padding-left: 40px;
      overflow-y: auto;
    }
  }
  &.invisible {
    color: transparent;
    height: 0;
  }
}