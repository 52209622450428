@import "../../../../styles/colors.scss";

.TogglesFilter {
  border: 1px solid var(--separatorColor);
  border-radius: 4px;
  overflow: hidden;
  &__item {
    transition: background-color 0.3s ease;
    overflow: hidden;
    border-radius: 1px;
    color: var(--textLighter);

    &:hover {
      background: var(--card);
      transition: background-color 0.3s ease;
    }

    &.active {
      background-color: $lightPrimary;
      color: $primary;
    }

    &.disabled {
      color: var(--disableColor);
      cursor: not-allowed;
      pointer-events: none;
    }

    &-inner {
      color: inherit;
      height: 38px;
      color: inherit;
      cursor: pointer;
      border-color: var(--separatorColor) !important;
    }
  }
}
