@import "../../../styles/colors.scss";

.RepositoriesTable {
  display: flex;
  flex-direction: column;
  gap: 20px;

 
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  &__toggle {
      margin-right: 10px;
  }

  &__head {
    gap: 5px;
  }

  &__container {
    height: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 10px;
  }

  &__table {
    height: 100%;
    visibility: visible;
    opacity: 1;

    transition: opacity 0.2s ease;
    &.infoVisible {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

  }

  &__side {
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
    &.infoVisible {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &__item {
    font-weight: bold;

    &-link {
      cursor: pointer;
    }

    &-view-stacks-btn {
      &-flag {
        cursor: pointer;
        gap: 5px;
        width: min-content;
        color: $primary;
        border: 1px solid transparent;
        background-color: $lightPrimary;
        font-size: 10px;
        font-weight: bold;
        border-radius: 24px;
        padding: 4px 8px;
        height: 20px;
        transition: all 0.2s ease;
        &.transparent{
          background: unset !important;
        }
        &:not(.transparent):hover {
          border-color: $primary;
          background-color: darken($lightPrimary, 3);
        }
        svg{
          height: 12px;
        }
      }
    }

    &-icon {
      width: 18px;
      height: auto;
      &.actions {
        cursor: pointer;
        &.play {
          width: 14px !important;
          margin: 0;
        }
        &.spin {
          color: $primary;
          font-size: 12px;
        }
      }
    }

    &-rowIcon {
      width: 14px;
      height: auto;
    }

    &-exclamation {
      font-size: 14px;
      color: var(--red);
    }

    &-row {
      gap: 10px;
      word-wrap: "break-word";
      word-break: "break-word";
    }

    &-title {
      max-width: 70%;
    }

    &-cloudIcon {
      position: relative;
      width: min-content;
      &-icon {
        opacity: 0.6;
      }

      &-classification {
        fill: rgba($text, 0.3);
        transform: translateY(3px);
      }

      &-classificationActive {
        fill: $primary;
        transform: translateY(3px);
      }

      &-bubble {
        position: absolute;
        bottom: -5px;
        right: 6px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        font-size: 10px;
        color: white;
        font-weight: bold;
        border: 1px solid $appBG;

        &.orange {
          background-color: rgba(lighten($orange, 5), 1);
        }

        &.blue {
          background-color: rgba(lighten($darkBlue, 30), 1);
        }
        &.darkPurple {
          background-color: #7d15b2;
        }
        &.black {
          background-color: black;
        }
        &.darkBlue {
          background-color: #002879;
        }
        &.green {
          background-color: #5eb02e;
        }
        &.darkGreen {
          background-color: #387f8c;
        }
      }
    }
    &-configIcon {
      opacity: .6;
      cursor: pointer;
      transition: opacity .2s ease;
      padding: 5px;
  
      &:hover {
        opacity: 1;
      }
  
      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &__kindWrapper {
    button {
      transform: translateY(2px) !important;
      margin-right: 10px !important;
    }
  }

  &__loaderContainer {
    min-height: 60vh;
  }

  &__info {
    display: flex;
    justify-content: center;

    &.configIcon {
      opacity: 0.6;
      cursor: pointer;
      transition: opacity 0.2s ease;
      padding: 10px;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__description {
    color: $sidebarBG;
    font-size: 13px !important;
  }

  &__btns {
    gap: 15px;
    justify-content: flex-end;
  }

  &__cli {
    margin-top: 15px;
  }
}


.evaluate-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg{
    width: 20px;
    height: 20px;
  }
}