.EmptyNotifications {
    min-height: 75vh;
    gap: 55px;

    img {
        height: 260px;
        width: auto;
    }

    &__text {
        gap: 7px;

        &-action {
            gap: 25px;
            cursor: pointer;
        }
    }
}