@import "./colors.scss";

// ************ this stylesheet is only for ant design general overide styling **********

// popover -------------------------------

.ant-popover-arrow {
  display: none !important; //arrow break on build
}
.ant-tooltip-arrow {
  display: none !important;
}

.ant-tooltip-inner {
  border: 1px solid var(--separatorColor);
}

// drop down don't close beacuse themes colides
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none !important;
}

// checkbox hover color -------------------------------
.light {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary;
  }
}

.light .ant-checkbox-inner {
  background-color: transparent;
}

// drawer -------------------------------

.ant-drawer-body {
  padding: 0 !important;
}

// Tabs -------------------------------
.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-tab {
  padding: 16px 30px !important;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  width: 150px;
  justify-content: center;
  color: var(--textSuperLight);
  margin-right: 2px;
  font-weight: bold;

  &:hover {
    color: $text;
    transition: background-color 0.2s ease;
  }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px !important;
}

.ant-tabs-tab-active {
  transition: background-color 0.4s ease;
  transition: font-weight 0.4s ease;
  color: $primary;
  font-weight: bold;

  &:hover {
    color: $primary;
  }
}

// Table -------------------------------

.ant-table-cell {
  font-size: 13px;
}

.ant-table-body .ant-table-cell {
  padding: 5px 8px !important;
  font-size: 12px;
}

.ant-table-pagination {
  align-items: center;
  margin-bottom: 0 !important;
  margin-top: 26px !important;
}

.ant-pagination-total-text {
  opacity: 0.45;
  margin-right: 20px !important;
}

.ant-pagination-item-link {
  border-color: transparent !important;
}

.ant-pagination-item {
  border-radius: 4px !important;
  min-width: 24px !important;
  height: 24px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

// Form ---------------------------------
.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important; // remove * sign from required fields
}

.ant-form-item-label > label::after {
  //remove : sign from label
  display: none !important;
}

// error form message block
.light,
.dark {
  .ant-form-item-explain,
  .ant-form-item-extra {
    padding: 8px 0 0 5px;
    color: $red;
    opacity: 1 !important;
    height: max-content;
  }
}

// form item when error
.light,
.dark {
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: transparent;
    border-color: rgba($primary, 0.6) !important;
    box-shadow: none !important;
  }
}

.ant-select-item-option {
  &:not(:last-child) {
    border-bottom: 1px solid var(--separatorColor) !important;
  }
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word !important;
}

// Select --------------------------------------------------------------

.ant-select-item-option-selected {
  background-color: var(--lightPrimary) !important;
}

.light,
.dark {
  .ant-select {
    border: 1px solid var(--inputBorder);
    background-color: var(--inputBG);
    border-radius: 4px;
    color: var(--inputColor);
    transition: border-color 0.2s ease;

    .ant-select-arrow-loading {
      svg {
        fill: var(--inputColor) !important;
      }
    }

    &:hover {
      border-color: rgba($primary, 0.6);
      transition: border-color 0.2s ease;
    }

    // when search enabled
    .ant-select-selection-search {
      input {
        border-color: transparent !important;
        background-color: transparent;
        padding: 0 !important;
      }
    }
  }
}

.ant-select-clear {
  opacity: 0.7 !important;
  color: $primary !important;
}

.ant-select-selection-placeholder {
  color: var(--inputPlaceholder) !important;
}

// select styling on foucs
.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open.ant-select-show-search {
  border-color: rgba($primary, 0.6) !important;
}
.ant-select.ant-select-single.ant-select-show-arrow.ant-select-disabled.ant-select-show-search {
  background-color: rgba($lightGrey, 0.25);
  border-color: transparent;
  :hover {
    cursor: not-allowed;
  }
}

.ant-select-disabled {
  background-color: var(--disableBG) !important;
  // .ant-select-selection-search-input {
  //   cursor: not-allowed !important;
  // }
  pointer-events: none;
  cursor: not-allowed !important;
}

// Input -------------------------------------------------------------

.ant-input-disabled {
  background-color: var(--disableBG) !important;
}


// .light .ant-input,
// .light .ant-input-password {
//   border: 1px solid $inputBorder;
//   background-color: $inputBG;
//   border-radius: 4px;
//   color: rgba($text, .85);
//   transition: border-color 0.2s ease;
//   padding: 5px 11px;

//   &::placeholder {
//     color: rgba($inputColor, .4) !important;
//   }

//   &:hover {
//     border-color: rgba($primary, 0.6);
//     transition: border-color 0.2s ease;
//   }
// }

// .light .ant-input:focus,
// .light .ant-input-affix-wrapper:focus,
// .light .ant-input-password:focus,
// .light .ant-input-focused,
// .light .ant-input-affix-wrapper-focused {
//   box-shadow: none; //remove box shadow when foucs input
// }

// // border color for input password when focus
// .light .ant-input:focus,
// .light .ant-input-affix-wrapper:focus,
// .light .ant-input-password:focus,
// .light .ant-input-focused,
// .light .ant-input-affix-wrapper-focused {
//   border-color: rgba($primary, 0.6) !important;
// }

// Button -------------------------------------------------------------

// button loader icon
.ant-btn-loading-icon {
  span {
    margin-right: 8px;
  }
}

.light .ant-btn-primary:hover,
.light .ant-btn-primary:focus {
  color: #fff;
  background: darken($primary, 5);
  border-color: darken($primary, 5);
}

.light,
.dark {
  .ant-btn-dangerous {
    color: white;
    background: $red;
    border-color: $red;

    &:hover,
    &:focus {
      color: #fff;
      background: darken($red, 5);
      border-color: darken($red, 5);
    }
  }
}

// Modal --------------------------------------------------------------
.ant-modal-content {
  border-radius: 10px !important;

  .ant-modal-body {
    padding: 0;
  }
}

.ant-modal-header {
  // this is for cases using ant design built in title
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

// Steps (wizrd) -----------------------------------------------------------------
.light,
.dark {
  .ant-steps-item {
    height: 90px;
  }

  .ant-steps-item-title {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-steps-item-description {
    color: var(--textLighter);
    font-size: 12px;
  }

  .ant-steps-item-tail::after {
    background: var(--sidebarLink);
    opacity: 0.5;
  }

  .ant-steps-item-icon {
    background-color: $primary !important;
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      box-shadow: 0px 0px 10px rgba(133, 89, 254, 0.7);
      &:after {
        content: "";
        display: inline-block;
        background-color: white;

        width: 4px;
        height: 4px;
        border-radius: 50%;
        transform: translateY(-14px);
      }
    }
  }

  .ant-steps-item-wait {
    opacity: 0.5;
    .ant-steps-item-icon {
      background-color: var(--sidebarLink);
    }
  }
}

// Radio
.ant-radio-inner {
  background-color: transparent !important;

  &::after {
    top: 2px !important;
    left: 2px !important;
  }
}

// .ant-radio-group {
//   display: flex !important;
//   flex-wrap: wrap;
//   gap: 10px;
// }

// .ant-radio-button-wrapper {
//   height: 40px !important;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
//   min-width: max-content;
//   border-radius: 5px !important;
//   border-left-width: 1px !important;
// }

// .light .ant-radio-button-wrapper:not(:first-child)::before {
//   background-color: transparent !important;
// }

// multiple select
.light,
.dark {
  .ant-select-multiple .ant-select-selection-item {
    background: $lightPrimary;
    border: 1px solid transparent;
    border-radius: 4px;
    color: rgba($primary, 0.8);
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    user-select: none;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
  }
}

.light,
.dark {
  .ant-select-multiple .ant-select-selection-item-remove {
    color: rgba($primary, 0.8);
  }
}

.ant-select-selection-search {
  min-width: max-content;
  color: rgba($primary, 0.8);
}

// ----- collapse -----------

.ant-collapse {
  border: none !important;
  background-color: transparent !important;
}

.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  position: absolute;
  top: 40%;
  right: 25px;
  color: var(--textLighter);
  fill: var(--textLighter);
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  border-bottom: 1px solid var(--separatorColor);
  padding: 12px 15px !important;
  cursor: pointer !important;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background-color: var(--lightPrimary);
  }
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-content-box {
  border-bottom: 1px solid var(--separatorColor) !important;
}

.ant-collapse-item-active {
  .ant-collapse-header {
    color: $primary;
    background-color: var(--lightPrimary);
    font-weight: bold !important;

    .ant-collapse-arrow {
      color: $primary !important;
      fill: $primary !important;
    }
  }
}

.light {
  .ant-form-item-explain {
    color: $primary;
  }
  .ant-form-item-explain-error {
    color: #e36f7e;
  }
}

// tags
.ant-tag {
  border: 1px solid var(--separatorColor) !important;
  background-color: transparent !important;
  color: var(--textSuperLight) !important;
  border-radius: 15px !important;
  padding: 1px 13px !important;
  margin: 0 !important;
  &:hover {
    background-color: $lightPrimary !important;
    color: $primary !important;
    border-color: $primary !important;
  }
}

.ant-tag-checkable-checked {
  background-color: $lightPrimary !important;
  color: $primary !important;
  border-color: rgba($primary, 0.8) !important;
}

.new-label {
  background-color: $lightPrimary !important;
  color: $primary !important;
  border-color: rgba($primary, 0.8) !important;
}

.filter-tag {
  border-radius: 4px !important;
  background: rgba(152, 114, 254, 0.2) !important;
  background-color: rgba(152, 114, 254, 0.2) !important;
  color: #9872fe !important;
  border: 0 !important;
  .ant-tag-close-icon {
    svg {
      fill: #9872fe !important;
    }
  }
}

// tree
.ant-tree {
  padding: 4px 0 !important;
  font-size: 12px !important;
  color: var(--textSuperLight);
  background-color: transparent !important;
  width: 100% !important;

  .ant-tree-treenode {
    padding: 6px 0 !important;
    width: 100% !important;

    &:not(:last-child) {
      border-bottom: 1px solid var(--separatorColor) !important;
    }
  }

  .ant-tree-node-content-wrapper {
    padding-left: 0 !important;
  }
}

.ant-message .ant-message-notice {
  .ant-message-notice-content {
    width: 350px;
    .ant-message-success {
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: 15px;
    }
  }
}
