@import "../../../styles/colors.scss";

.UsersList {
  gap: 20px;
  margin-bottom: 20px; //addition to the whole users container gap.

  &__body {
    gap: 20px;

    // // ant design table styling
    

    .status-column {

      .status-icon {
        font-size: 10px;
        margin-right: 7px;
        &.green {
          color: #2eb67d;
        }
        &.red {
          color: #e36f7e;
        }
      }
    }

    .role-icon {
      font-size: 14px;
      &.green {
        color: #2eb67d;
        margin-right: 7px;
      }
      &.purple {
        color: $primary;
        margin-right: 7px;
      }
    }

    // delete user styling
    .action-user-cell {
      
      &__delete {
        border-left: 1px solid $separatorColor;
        padding-left: 8px;
        margin-left: 8px;
      }
    }
  }
}
