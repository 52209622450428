.GradientTextarea {
  background: linear-gradient(92.57deg, #59fee0 4.91%, #9872fe 95.86%);
    border-radius: 4px;
    padding: 1px;
    &__container {
      background-color: var(--card);
      border-radius: 4px;
      width: 100%;
      height: 100% !important;
      &-textarea {
        background-color: var(--appBG);
        padding: 10px;
        border-top: 1px solid var(--separatorColor);
        border-radius: 0px 0px 4px 4px;
        &-withButton {
          background-color: var(--card);
          border-radius: 4px;
          textarea {
            background-color: var(--card) !important;
            border-radius: 4px;
            margin-left: 5px;
          }
        }
      }
    }
    textarea {
      background-color: var(--card);
      border: 1px solid transparent !important;
      width: 100%;
      padding: 5px 11px;
      border-radius: 4px;
      resize: none !important;
    }
   
}