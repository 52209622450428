.BrowseRepositoryModal {
  padding: 0;

  &__content {
    background-color: var(--appBG);
    padding: 0 30px;
    height: max-content;
    overflow: hidden;

    &-head {
      padding: 20px 0;
    }

    &-list {
      height: 250px;
      overflow: auto;
    }
  }

  &__item {
    padding: 10px;
    gap: 10px;
    cursor: pointer;
    flex-wrap: wrap;
    &:not(:last-child) {
      border-bottom: 1px solid var(--separatorColor);
    }
    &:hover {
      background-color: var(--lightPrimary);
    }

    &.active {
      color: var(--primary);
    }

    &-logo {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      height: 25px;
      width: 100px;
    }
  }
}
