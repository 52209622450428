@import "../../../styles/colors.scss";

.SingleExcluded {
    padding: 6px 0px;
    gap: 20px;
    &.noPaddingBottom {
        padding-bottom: 0px;
    }
    &:last-child {
        padding-bottom: 15px;
    }

    &__labels,
    &__dataSources {
        display: flex;
        gap: 8px;
        align-items: center;
        > span {
            font-size: 12px;
            line-height: 14px;
            color: #909099;
        }
        &-list {
            gap: 8px;
            > span {
                height: 21px;
                display: flex;
                align-items: center;
                gap: 2px;
            }
            &-item {
                padding: 5px 10px;
                border-radius: 30px;
                background-color: #303148;
                border: 0.5px solid #9178f680;
                font-size: 10px;
                text-transform: capitalize;
                gap: 3px !important;
                cursor: default;
                img {
                    height: 15px;
                }
            }
            &-popover {
                padding: 0;
                margin: 0 !important;
                list-style-type: none;
                li {
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    span {
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
    }
    &__dataSources {
        margin-right: 16px;
    }
    &__user-information {
        font-size: 12px;
        &,
        > div {
            display: flex;
            align-items: center;
            color: var(--textMuted);
            font-weight: normal;
        }
        column-gap: 8px;
        img {
            margin-right: 8px;
        }
    }
    &__divider {
        display: inline-block;
        height: 14px;
        width: 2px;
        background-color: var(--separatorColor);
    }

    &__text {
        color: var(--textLighter);
        gap: 5px;
        width: 55%;

        &-name {
            gap: 10px;
            &-count {
                background-color: var(--innerCard);
                min-width: 15px;
                border-radius: 10px;
                padding: 1px 9px;
                font-weight: 500;
                font-size: 12px;
            }
            &.title {
                font-size: 12px;
            }
        }

        &-description {
            gap: 12px;
            font-size: 12px;
            line-height: 18px;
            color: #d1d1d4;

            &.withOverflow {
                overflow-x: auto;
            }

            &-providers {
                margin: 10px 0px 10px 0px;
                &-single {
                    &:not(:last-child) {
                        border-right: 1px solid var(--separatorColor);
                    }
                    padding-right: 15px;
                    gap: 8px;
                    font-size: 12px;
                    white-space: nowrap;
                    color: #d1d1d4;
                    width: max-content;
                    img {
                        width: auto;
                        height: 14px;
                    }
                    &-cloud {
                        color: $primary;
                    }
                }
            }

            &-comment {
                font-size: 12px;
                line-height: 18px;
                color: #d1d1d4;
                gap: 5px;
            }

            &-created {
                color: var(--disableColor);
                margin-right: 10px;
                span {
                    background: linear-gradient(90deg, #8559fe 50.67%, #7bdcca 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    &__actions {
        width: 45%;
        justify-content: flex-end;

        &-action {
            padding: 0 20px;
            border-right: 1px solid var(--separatorColor);
            gap: 10px;
            cursor: pointer;

            &.delete-btn {
                margin-right: 6%;
            }

            &.code-btn {
                svg {
                    color: $primary;
                }
            }
        }
        &-toggle-btn {
            gap: 15px;
            font-size: 10px;
            color: white;
        }
    }

    &__properties {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        &-property {
            padding: 0 10px 0px 0px;
            &:not(:last-child) {
                border-right: 1px solid var(--separatorColor);
            }
            gap: 10px;
        }
    }
}

.SingleExcluded-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 7px 0px 7px 0px;
    border-top: 1px solid var(--separatorColor);

    &__container {
        display: flex;
        flex-direction: inherit;
        margin: 5px 0px 1px 11px;
    }
}

.light {
    .SingleExcluded__dataSources {
        &-list {
            &-item {
                background: #f6f5f8;
                border-color: transparent;
            }
        }
    }
}
