@import "../../../styles/colors.scss";

.light {
  .IntegrationCard {
    background-color: var(--card);
  }
}

.dark {
  .IntegrationCard {
    background-color: $codeCard;
  }
}

.IntegrationCard {
  padding: 20px 20px 20px 5px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  width: 100%;
  border: 1px solid transparent;
  position: relative;

  &.connected {
    border-color: $turquoise;
  }

  &.soon {
    background-color: transparent;
    box-shadow: none;
    border-color: var(--separatorColor);

    .IntegrationCard__type-title {
      opacity: .6;
    }
  }

  &__flag {
    font-size: 12px;
    padding: 2px 6px;
    color: white;
    gap: 5px;
    font-weight: 500;

    &.connected {
      color: $turquoise;
    }
    &.soon {
      color: var(--disableColor);
    }
  }

  &__type {
    &-title {
      font-size: 12px;
      color: var(--text);
    }

    &-wrapperIcon {
      width: 70px;

      &-icon {
        height: 30px;
        width: auto;
        max-width: 30px;
      }
    }
  }

  &__action {
    &-btn {
      border-color: #916afc !important;
      color: #916afc !important;
      font-size: 13px !important;
      position: relative;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      overflow: hidden !important;
      padding: 3px 20px !important;

      svg {
        position: absolute;
        right: -20px;
        transition: right 0.3s ease;
      }
      span {
        transition: transform 0.3s ease;
      }

      &:hover {
        svg {
          position: absolute;
          right: 10px;
          transition: right 0.3s ease;
        }

        span {
          transform: translateX(-8px) !important;
          transition: transform 0.3s ease;
        }
      }
    }

    &-check {
      color: $green;

    }
  }
}
