.SingleGuardrail {
    &__header {
      &-actions {
        &-notifIcon {
          &.disabled {
            path {
              fill: var(--disableColor)
            }
          }
        }
      }
        &-icon {
            width: 24px;
            height: auto;
            padding: 3px 6px;
            border-radius: 7px;
            &.policy {background: #FF883233;}
            &.cost {background: #7DCA9E33;}
            &.tag { background:#9872FE33; }
            &.resource {background: #FF5FDC33;}
        }
    }
    &-divider {
        width: 1px;
        height: 14px;
        background-color: var(--text);
        margin-right: 4px;
        &.gray { 
            background-color: var(--label);
            margin: 0px;
        }
        &.lighter {
            background-color: var(--separatorColor);
            margin: 0px;
        }
        &.horizonal {
            width: 100%;
            height: 1px;
            margin: 0px;
            background-color: var(--separatorColor);
        }
    }
    &-label { color: var(--label); }
    &__criteria {
        gap: 5px !important;
        padding: 3px 6px !important;
        font-size: 13px !important;
        &.cost { cursor: unset !important;}
        &-icon {
            width: 14px;
            height: 14px;
            path { fill: var(--primary); }
        }
    }
    &__popover {
        padding: 0px 10px;
        &-list { margin-left: 5px; }
        &-section { 
            border-bottom: 1px solid var(--separatorColor);
            padding-bottom: 10px;
        }
        &-tagIcon {
          width: 16px;
          height: 16px;
          path { fill: var(--text); }
        }
        &-severityBtn {
            height: 22px;
            border-radius: 4px;
            font-size: 13px;
            padding: 1px 10px;
            width: 80px;
            color: white !important;
    
            &.critical, &.error {
              background-color: #16383D;
              border-left: 5px solid var(--critical);
            }
            &.error {
              background-color: #832b2c;
              border-left: 5px solid var(--delete);
            }
            &.high {
              background-color: #1C2739;
              border-left: 5px solid var(--high);
            }
            &.medium {
              background-color: #1F2541;
              border-left: 5px solid var(--medium);
            }
            &.low, &.warning {
              background-color: #201D43;
              border-left: 5px solid var(--low);
            }
            &.unknown {
              background-color: #482e8a;
              border-left: 5px solid #562ab2;
            }
        }
    }
    &__badge {
      .ant-badge-count {
        background-color: var(--primary) !important;
        box-shadow: none !important;
      }
      .ant-badge-count-sm {
        font-size: 10px !important;
      }
    }
    &__action {
        &-icon {
            &.create {color: var(--create);}
            &.delete {color: var(--delete);}
            &.read {color: var(--read);}
            &.update {color: var(--update);}
            &.no-op {color: var(--no-op);}
            &.import {color: var(--import);}
            &-createdelete, &-deletecreate {
                height: 20px;
                width: 20px;
            }
          }
    }
}