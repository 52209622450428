.unauthorized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 885px;
    width: 1279px;

    &__header-image {
        margin-right: 8px;
        transform: translateY(-1px);
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        flex: 2;
        color: #ffffff;

        &__title {
            font-size: 20px;
            font-weight: 700;
        }

        &-sub-title {
            font-size: 16px;
            font-weight: 500;
        }

        &__title-highlight {
            background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &__close-button {
            position: absolute;
            right: 10px;
            top: 5px;
            cursor: pointer;
            font-weight: 700;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 6;
        width: 100%;
        height: 510px;

        &__title {
            background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 12.86%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-top: 10px;
            overflow: hidden;
        }

        &__key_benefits {
            height: 500px;
        }

        &__key_capabilities {
            height: 400px;
            gap: 10px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            max-width: 333px;

            &-dot {
                width: 16px;
                height: 17px;
            }

            &-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                font-weight: 700;
            }

            &-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;
                ul > li {
                    margin-bottom: 10px;
                }
            }

            &-description {
                width: 196px;
                margin-top: 10px;
            }
        }
    }

    &__key-benefits {
        flex: 6;
    }

    &__seperator {
        height: 100%;
        margin-right: 18px;
        border-left: 1px solid #383847;
    }

    &__key-capabilities {
        flex: 4;
        height: 523px;
    }

    &__footer {
        display: flex;
        align-items: center;
        flex: 2;
        flex-direction: column;
        gap: 10px;

        &__description {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            &-a {
                font-size: 14px;
            }

            &-b {
                font-size: 14px;
                color: #888891;
                font-style: italic;
            }
        }
    }
}

.unauthorized-modal {
    overflow: hidden;
    max-height: 85vh;
    border-radius: 12px;
    padding: 15px 0px 0px 50px !important;
    justify-items: center;
}

@media only screen and (min-width: 867px) and (max-width: 1513px) {
    .unauthorized-modal {
        max-height: 96vh;
    }

    .unauthorized {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1100px;

        &__header {
            gap: 0px;
        }

        &__content {
            padding: 5px 0px 5px 10px !important;
            align-items: normal;

            &__container {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                overflow: hidden;
            }

            &__key_capabilities {
                height: 530px;
            }
        }

        &__key-benefits {
            flex: 9;
        }
    }
}
