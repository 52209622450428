@import "../../../styles/colors.scss";

.InsightCategoryLabel {
  &__labelWrapper {
    gap: 5px;
    line-height: 1;

    .reliability {
      color: $reliability;
    }
    .optimization {
      color: $optimization;
    }
    .misconfiguration {
      color: $misconfig;
    }

    img {
      height: 12px !important;
      width: auto;
      transform: translateY(-1px);
    }

    .disable {
      color: var(--disableColor);

      img {
        filter: grayscale(100%);
      }
    }
  }
}