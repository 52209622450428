.GithubProviderIntegration {

    .GithubOverview {
        width: 50%;
        gap: 3px;
    }

    .GithubProviderSetup {
        width: 50%;
        gap: 20px;

        &__loading {
            height: 50vh;
        }

        &__intro {
            gap: 3px;
            &-list {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 0px;
                li:not(:last-child) { 
                margin-bottom: 10px;  
                }
                &-item {
                    margin-bottom: 12px;
                }
            }
        }
    }
}