@import "../../styles/colors.scss";

.NewAppModal {
  width: 100%;
  position: relative;

  &__header {
    width: 100%;
    padding: 20px 30px;
    padding-right: 50px;
    border-bottom: 2px solid var(--separatorColor);
    gap: 18px;
    background-color: var(--card);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &-close {
      background-color: transparent;
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      cursor: pointer;
      color: var(--exitButtonX) !important;
      font-size: 22px !important;
      width: 25px;
      height: 25px;
      transition: all 0.2s ease;

      &:hover {
        color: var(--darkenExitButtonX) !important;
      }
    }

    &-icon {
      height: 32px;
      width: auto;
    }

    &-text {
      gap: 2px;

      &-title {
        font-size: 18px;
        color: var(--text);
      }

      &-sub {
        color: var(--textSuperLight);
        font-size: 13px !important;
      }
    }
  }

  &__body {
    padding: 30px;
    max-height: 65vh;
    overflow: auto;
    background-color: var(--appBG);
  }

  &__footer {
    padding: 26px 30px 23px 30px;
    border-top: 2px solid var(--separatorColor);
    justify-content: flex-end;
    align-items: flex-end !important;
    gap: 20px;
    background-color: var(--card);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

  }
}
