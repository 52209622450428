$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.RemediationBlock {
    background: $gradient-color;
    margin-top: -10px;
    padding: 1px;
    border-radius: 4px;
    &.expandedBlock {
        width: calc(100% - 1px);
        border-radius: 0px 0px 4px 4px;
    }
    &-header {
      padding: 7px 10px;
      background: $gradient-color;
      &-title {
        color: #212133;
      }
      &-icon {
        width: 22px;
        height: 22px;
      }
    }
    &-body {
      background: var(--card);
      padding: 10px 20px;
      border-radius: 0px 0px 4px 4px;

      &-loading {
        margin-top: 5px;
        margin-bottom: 5px;
        .TypingDots {
          span {
            height: 3px !important;
            width: 3px !important;
          }
        }
      }
    }
    &-closeIcon {
      color: #212133;
      width: 16px !important;
    }
  }