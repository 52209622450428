@import "../../../styles/colors.scss";

.K8SIntegration {
  .K8sOverview {
    gap: 20px;
    &__section {
      gap: 5px;
    }
  }

  .AccessKeypair {
    gap: 20px;
    width: 40%;
  }

  .K8sFinalInstructions {
    gap: 30px;

    &__intro {
      gap: 6px;
    }

    &__cmds {
      gap: 30px;

      &-item {
        font-size: 12px;
        gap: 15px;

        label {
          color: $sidebarBG;
          opacity: 0.5;
        }
      }
    }
  }
}

.ArgoCDOverview {
  &__input {
    margin-bottom: 1.5rem !important;
    flex-direction: column !important;
  }
}
