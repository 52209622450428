.PostApply {
    overflow: auto;
    height: 100%;
    .ActionBtn {
        &__icon {
            &.copy {
                margin-top: 3px;
                svg { width: 13px; height: 13px; } 
                path {
                    fill: var(--primary) !important;
                }
            }
        }
    }
}

.ApplyResources {
    &__status {
        &.failed { color: var(--delete); }
        &.success { color: var(--create); }
    }
    &__action {
        &-icon {
          &.create {color: var(--create);}
          &.delete {color: var(--delete);}
          &.read {color: var(--read);}
          &.update {color: var(--update);}
          &.no-op {color: var(--no-op);}
          &.import {color: var(--import);}
        }
    }
    &__icon {
        width: 13px !important;
        height: 13px;
        padding: 2px;
        border-radius: 2px;
        color: #30304A;
        &.success { background-color: #1AD598 }
        // severity
        &.critical, &.error { background-color: var(--critical) }
        &.high { background-color: var(--high);}
        &.medium { background-color: var(--medium);}  
        &.low, &.warning { background-color: var(--low);}
    }
    &__statusTooltip {
        width: 100%;
    }

    .MuiDataGrid-detailPanel {
        left: 0 !important;
        background-color: var(--appBG) !important;
        .Diagnostic__body  {
            border: 0px !important;
            border-radius: 0px !important;
            padding-left: 25px;
            padding-right: 25px;
        }
        .Diagnostic__severityBtn {
            height: 20px;
            font-size: 12px;
        }
        .DarkCopyBox__wrapper-box { padding: 5px 12px !important}
        .Diagnostic__header {
            background-color: var(--card);
            border: 0px;
            border-radius: 0px;
            padding: 15px 25px 0px 25px;
        }
        .Diagnostic__header-title {
            font-size: 13px;
            color: var(--textLighter);
        }
    }
   
}