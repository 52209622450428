.AccountTierBanner {
  &__img {
    transform: translateY(24px);
    width: 60%;
  }

  &__content {
    background-color: var(--appBG);
    border-radius: 6px;
    width: 100%;
    padding: 40px 20px 24px 20px;
    gap: 20px;

    &-text {
      gap: 3px;
      color: var(--textLighter);
    }
  }
}
