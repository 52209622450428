@import '../../styles/colors.scss';

.AssetsLineChart {
  justify-content: space-between;
  z-index: 100;

  // important fix for AntD chart hiding the chart
  [data-chart-source-type="G2Plot"] {
    overflow: unset !important;
  }
}