@import "../../styles/colors.scss";

.IacStatusFlag {
    font-size: 10px;
    border-radius: 24px;
    padding: 3px 11px;
    line-height: normal;
    width: max-content;
    gap: 5px;
    font-weight: 600;
    height: 18px;

    &-cost {
        &.down {
            color: var(--create);
        }

        &.up {
            color: var(--delete);
        }

        &-tooltip {
            max-width: unset !important;

            .ant-tooltip-inner {
                padding: 5px 0px !important;
            }

            &-header {
                &-title {
                    border-bottom: 1px solid var(--ciCollapseBorder);
                    font-weight: 500;
                    padding: 5px 10px;
                }

                &-content {
                    padding: 0px 10px;
                }
            }
        }
    }

    &-icon {
        height: 12px;
    }

    &-analyzer {
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 14px !important;

        &:hover {
            transform: scale(1.1);
        }
    }

    &.iacTab {
        font-size: 14px;
        background-color: transparent !important;
        padding: 0;
        border: none !important;
    }

    &.modal {
        font-size: 12px;

        &-managedFlag {
            background-color: $light_turquoise;
            color: $turquoise;
        }
    }

    &.dashboardFlag {
        font-size: 16px !important;
        gap: 0.5em;

        &-managedFlag {
            background-color: $light_turquoise;
            color: $turquoise;
        }
    }

    &.unmanagedFlag {
        color: $strawberry;
        background-color: $light_strawberry;
    }
    &.undeterminedFlag {
        color: var(--undeterminedFlagBorder);
        background-color: var(--undeterminedFlagBG);
        border: 1px dashed var(--undeterminedFlagBorder);
        cursor: help;
    }

    &.ghostFlag {
        background-color: $light_salmon;
        color: $salmon;
        cursor: pointer;
    }

    &__clickOps {
        color: $strawberry;
        height: 12px;
    }

    &__unmanagedFlag {
        gap: 9px;
    }

    &.inventory-table-flag {
        margin-left: 25px;
    }

    &.dashboard {
        font-size: 16px !important;
        gap: 0.5em;
        cursor: initial;
    }

    &.managedFlag {
        background-color: $light_turquoise;
        color: $turquoise;
        &.pending {
            border: 1px dashed rgba($turquoise, 0.6);
            cursor: help;
        }
    }

    &.childFlag {
        background-color: $light_turquoise;
        color: $turquoise;
    }

    &.iacIgnoredFlag {
        background-color: $light_blue;
        color: $blueIacIgnored;
        font-size: 11px !important;
    }

    &.driftFlag {
        background-color: $purple_Lilac;
        color: $purple;
        &.pending {
            border: 1px dashed rgba($purple, 0.6);
            cursor: help;
        }
    }

    &.ghostPendingFlag {
        background-color: transparent;
        color: $salmon;
        border: 1px dashed rgba($salmon, 0.5);
        cursor: help;
    }
    &.unmanagedPendingFlag {
        color: $strawberry;
        background-color: transparent;
        border: 1px dashed rgba($strawberry, 0.6);
        cursor: help;
    }
    &.deletedFlag {
        background-color: var(--disableBG);
        color: var(--disableColor);
    }
}

.light {
    .IacStatusFlag-icon {
        &.ghost {
            path {
                fill: #11d86c;
            }
        }
    }
}
