.serviceNowEditForm {
    .container {
        gap: 5px !important;
    }

    .form-item {
        margin-bottom: 1.5rem !important;
        flex-direction: column !important;
    }

    .form-item-wide {
        width: 70% !important;
    }

    .form-label {
        transform: translateY(-2px) !important;
    }
}
