@import '../../../styles/colors.scss';

.TFCIntegration {

    .TFCCreation {
        width: 50%;
        gap: 20px;
        &__intro {
            gap: 3px;
            &-list {
                padding-left: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
                li:not(:last-child) { 
                  margin-bottom: 10px;  
                }
              }
        }
    }

    .TfRunTaskInfo {
        gap: 20px;

        &__key {
            width: 60%;
        }

        &__instructions {
            margin-top: 20px;
            color: var(--text);

            &-title {
                padding-bottom: 10px;
                font-weight: bold;
            }

            ol {
                padding-inline-start: 20px;
                li {
                    padding: 8px 0;
                    color: var(--textSuperLight);

                    .purple-flag {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}