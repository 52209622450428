@import "/src/styles/colors.scss";
.CloudWorkflows {
    @media (max-width: 1400px) {
        width: 140px !important;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    > div {
        height: 100%;
    }

    &__card {
        outline: 1px solid transparent;
        transition: outline-color 0.3s ease;
        padding: 20px !important;
        cursor: pointer;
        height: 177px !important;
        &.savings {
            min-width: max-content;
            @media (max-width: 1400px) {
                min-width: unset;
            }
            height: 100%;
            background-color: #0d292a;
            &:hover {
                outline-color: #63b773;
            }
            &.light {
                background-color: rgba(99, 183, 115, 0.3) !important;
            }
        }
        &.drift {
            height: 100%;
            &:hover {
                outline-color: var(--primary);
            }
        }
        &.workflows {
            height: 100%;
            background-color: rgba(175, 161, 214, 0.2);
            &:hover {
                outline-color: #baafdf;
            }
        }
        &-title {
            text-align: center;
            font-size: 15px;
        }
        &-cost {
            color: #63b773;
            &.workflows {
                color: #afa1d6;
            }
            &-sum {
                font-weight: 500;
                font-size: 24px;
            }
            &-drift {
                path {
                    fill: #9872fe;
                }
            }
        }
        &.clickOps {
            background: $light_strawberry;
            &:hover {
                outline-color: $strawberry;
            }
        }
    }
    &__icon {
        fill: #afa1d6;
        color: #afa1d6;
        height: 32px;
        width: 32px;
    }

    &__cloudWaste {
        flex-direction: column;
        gap: 10px !important;
        display: flex;
    }

    &__clickOps {
        &-pink {
            color: $strawberry;
        }
        &-icon {
            width: 38px !important;
            height: auto;
        }
    }

    &__eol-icon {
        fill: #578ac8;
        color: #578ac8;
    }

    .eol-text {
        color: #578ac8;
    }
}
