.ProviderMasterFilter {
  border-radius: 4px;
  background: linear-gradient(
    270deg,
    rgb(37, 223, 192) 0%,
    rgb(152, 114, 254) 99.75%
  );
  padding: 1px;

  &__inner {
    padding: 4px;
    width: 100%;
    border-radius: 3px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(33% - 5px) 1px calc(33% - 5px) 1px calc(
        33% - 5px
      );
    color: var(--text);

    &-btn {
      width: 100%;
      color: var(--text);
      font-size: 12px;
      height: 25px;
      position: relative;
      border-radius: 2px;
      transition: backgroud 0.2s ease;

      &.disabled {
          color: var(--disableColor);
      }

      &.active {
        font-weight: 700;
        transition: backgroud 0.2s ease;
      }

      &.cloud {
        &.active {
            color: #3B2B74;
          background: linear-gradient(90deg, #9872fe 0%, #779ff2 100%);
        }
      }
      &.k8s {
        &.active {
            color: #2B4168;
          background: linear-gradient(90deg, #779ff2 0%, #4cbad5 100%);
        }
      }
      &.saas {
        &.active {
            color: #225F6A;
          background: linear-gradient(90deg, #4abcd4 0%, #25dfc0 100%);
        }
      }

      &-bubble {
        position: absolute;
        top: -14px;
        right: 1px;
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 3px;
        min-width: 16px;
        color: #333333;
        font-size: 11px;

        &.cloud {
          background: #7496eb;
        }

        &.k8s {
          background: #4eb9d6;
        }

        &.saas {
          background: #2ed8c5;
        }
      }
    }

    &-divider {
      height: 100%;
      width: 1px;

      &.first {
        background-color: #779ff2;
      }
      &.second {
        background-color: #4cbad5;
      }
    }
  }
}

// light-dark adjustments using global classes
.light {
  .ProviderMasterFilter {
    &__inner {
      background: linear-gradient(90deg, #dacefa 0%, #c0f1f0 100%);
    }
  }
}

.dark {
  .ProviderMasterFilter {
    &__inner {
      background: linear-gradient(90deg, #3f3366 0%, #25575d 100%);
    }
  }
}
