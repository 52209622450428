@import '../../../styles/colors.scss';

.NotificationList {
  gap: 20px;

  &__list {
    position: relative;
    gap: 15px;

    &-loading {
      min-height: 150px;
    }
  }
  &__filter-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;

    >span:first-child {
      font-size: 14px;
      font-weight: 400;
    }


    &_dropdowns {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .divider {
      height: 16px;
      width: 1px;
      background: #909099;
    }

    .clear-btn {
      font-size: 14px;
      padding: 0 !important;

      span {
        text-decoration: underline !important;

      }
    }
  }
  &__search-wrapper{
    padding: 17px 25px;
    border-radius: 10px;
    border: 1px solid #303148;
    background: #212132;
  }
}

.light{
  .NotificationList__search-wrapper{
    border: 0;
    background: white;
  } 
}