.CostAnalysis {
    overflow: auto;
    margin: 20px 25px;
    &__header {
        display: grid;
        flex-direction: row;
        width: 100%;
        grid-template-columns: 40% 25% 35%;
        &.titles {
            margin: 0px 15px 0px 15px;
            font-weight: bold;
            width: auto;
            font-size: 16px;
        }
        &.inner {
            padding: 5px 15px 5px 15px;
        }
    }
    &__title {
        font-size: 16px;
        font-weight: bold;
        &.create { color: var(--create); }
        &.delete { color: var(--delete); }
    }
    &__empty { height: 100%; }
    
    .ant-divider-horizontal {
        margin: 0px !important;
    }
    //overrides collapse
    .ant-collapse-item {
      overflow: hidden;
      cursor: pointer;
      background-color: var(--CiCollapseBG);
      border: 1px solid var(--ciCollapseBorder);
      border-radius: 5px;
    }
    .ant-collapse-content {
      background-color: var(--card) !important;
    }

    .ant-collapse-header {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      color: var(--text);
      width: 100%;
      height: 50px;
      padding: 10px;
    }
    .ant-collapse-arrow {
      margin: 0px 5px;
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
          color: var(--text);
          background-color: var(--ciOpenCollapseBG) !important;
          border-bottom: 2px solid var(--primary) !important;
          font-weight: 400 !important;
      }
    }
}