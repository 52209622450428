.EventTypes {
    background: linear-gradient(90deg, #25dfc0 0%, #9872fe 100%);
    padding: 1px;
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    height: 30px;
    font-size: 14px;

    &__item {
        background-color: var(--appBG);
        border: 1px solid #3e2f52;

        &-inner {
            height: 26px;
            cursor: pointer;
            color: var(--textLighter);
            transition: background-color 0.3s ease;

            &:hover {
                background: var(--card);
                transition: background-color 0.3s ease;
            }

            &.active-custom {
                background: linear-gradient(90deg, #25dfc0 0%, #9872fe 100%);
                color: #27274a;
            }
        }
    }
}

.light {
    .EventTypes {
        &__item {
            border: 1px solid #d7d7d7;
        }
    }
}
