@import "../../styles/colors.scss";

  .AppBtn {
    height: 30px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 15px;
    gap: 10px;
    transition: all 0.3s ease;
    color: white !important;
    background-color: $primary;
    min-width: max-content;

    &__icon {
      width: max-content;
    }

    &.icon-only {
      padding: 5px 10px;
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: darken($primary, 5);
    }

    &.secondaryBtn {
      color: $primary !important;
      border-color: $primary;
      background-color: transparent;

      &:hover {
        background-color: $lightPrimary;
      }
    }
    &.textInHover {
      .AppBtn__text {
        display: none;
      }
      transition: all 0.3s ease;
      &:hover {
        .AppBtn__text {
          display: block;
        }
      }
    }
    &.plain {
      color: var(--textLighter) !important;
      border-color: transparent;
      background-color: transparent;

      &:hover {
        color: var(--primary) !important;
      }
    }

    &.whiteOutlined {
      color: white !important;
      border-color: white;
      background-color: transparent;
      opacity: .8;

      &:hover {
        color: $primary !important;
        border-color: $primary;
      }
    }
    &.gradientOutlined {
      --gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
      position: relative;
      &:not(.disabled)::before{
        content: "";
        position: absolute;
        background: var(--gradient-color);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -2;
        border-radius: 4px;
      }
     &:not(.disabled)::after{
        content: "";
        position: absolute;
        background: var(--aiBtnBG);
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
        z-index: -1;
        border-radius: 3px;
      }
      background-color: transparent;
      color: var(--text) !important;
    }

    &.disabled {
      background-color: var(--disableBG) !important;
      color: var(--disableColor) !important;
      border: 1px solid transparent !important;
      cursor: not-allowed;
      &:hover {
        color: var(--disableColor) !important;
        background-color: var(--disableBG) !important;
        border: 1px solid transparent !important;
      }
      svg{
       color: var(--disableColor) !important;
      }
    }
  }
