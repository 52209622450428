@import "../../styles/colors.scss";

.TableWrapperV2 {
  &__table {
    color: var(--textLighter) !important;
    border: none !important;

    // columns row container
    .MuiDataGrid-columnHeaders {
      background-color: var(--columnBG) !important;
      color: var(--columnText) !important;
      border-bottom: 1px solid var(--tableRowBorder) !important;
      font-size: 13px;
    }

    .MuiDataGrid-selectedRowCount {
      display: none !important;
    }

    // column
    .MuiDataGrid-columnHeader {
      transition: background-color 0.2s ease;
      &:hover {
        transition: background-color 0.2s ease;
        background-color: darken(#fafafa, 3) !important;
      }

      &:focus {
        outline: none !important;
      }

      &:focus-within {
        outline: none !important;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0 6px;
    }

    // cell
    .MuiDataGrid-cell {
      border-bottom: 1px solid var(--tableRowBorder) !important;
      font-size: 12px;

      &:focus {
        outline: none !important;
      }

      &:focus-within {
        outline: none !important;
      }
      .Mui-disabled {
        display: none;
      }
    }

    .MuiDataGrid-cell--withRenderer {
      line-height: 31px;
    }

    &.bigger {
      .MuiDataGrid-row {
        max-height: 40px !important;
        min-height: 40px !important;
      }

      .MuiDataGrid-cell {
        font-size: 13px !important;
        max-height: 40px !important;
        min-height: 40px !important;
      }

      .MuiDataGrid-cell--withRenderer {
        line-height: 40px !important;
      }
    }

    &.selection-sign {
      .MuiDataGrid-row {
        cursor: pointer;
      }
    }

    // row hover
    .MuiDataGrid-row:hover {
      background-color: var(--tableRowHoverBG) !important;
    }

    // selected row color
    .MuiDataGrid-row.Mui-selected {
      background-color: var(--tabeSelectRowBG) !important;

      .MuiDataGrid-cell {
        color: $primary;
      }
    }

    // pagination
    .MuiTablePagination-root {
      p {
        margin-bottom: 0;
        color: var(--textLighter) !important;
      }

      .MuiIconButton-root {
        color: var(--textLighter);
        // background-color: transparent;

        &:hover {
          svg {
            fill: $primary;
          }
        }
      }

      .Mui-disabled {
        svg {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .MuiTablePagination-select {
        color: var(--textLighter);
      }
      .MuiInputBase-root {
        svg {
          fill: var(--textLighter);
        }
      }
    }

    // checkbox
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        fill: var(--tableCheckbox);
        width: 0.9em;
        height: 0.9em;
        font-size: 1.4rem;
      }
    }

    // column seperator line
    .MuiDataGrid-iconSeparator {
      fill: var(--tableCheckbox);
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        fill: $primary;
      }
    }
    &-row {
      &.new {
        &.reliability {
          background-color: rgba($reliability, 0.15);
        }

        &.optimization {
          background-color: rgba($optimization, 0.15);
        }

        &.misconfiguration {
          background-color: rgba($misconfig, 0.15);
        }
      }
    }

    // footer
    .MuiDataGrid-footerContainer {
      border-top: none;
    }

    &.iacTable {
      .MuiDataGrid-footerContainer {
        display: none;
      }
    }

    .MuiDataGrid-detailPanel {
      background-color: var(--darkerCard);
      left: 60px;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__empty {
    width: 100%;
    height: 100%;
    background-color: var(--card);
    z-index: 1;
  }

  // move pagination if interocme is on it's way
  &.movePagination {
    .MuiTablePagination-root {
      margin-right: 30px;
    }
  }

  
}

// dark light theme styling
.light {
  .TableWrapperV2 {
      &__column-menu {
        &__header {
          div {
            color: #8E8D8F;
          }
  
          border-bottom: 1px solid #E2E2E2;
        }
  
        &__content {
          &__checkbox-group {
            label {
              span {
                color: rgba($color: #333333, $alpha: .5) !important;
              }
  
              &.ant-checkbox-wrapper-checked {
                span {
                  color: #8559FE !important;
                }
  
                .ant-checkbox-inner {
                  border-color: #8559FE !important;
                }
              }
            }
  
  
          }
        }
  
        .SearchBox input {
          border-color: var(--header-btn-border) !important;
          color: #8E8D8F !important;
  
          &::placeholder {
            color: #8E8D8F;
          }
        }
      }
    }
  .MuiDataGrid-columnHeader {
    &:hover {
      background-color: darken(#fafafa, 3) !important;
    }
  }
  .TableWrapperV2__loader {
    background-color: rgba(white, .65);
  }
}
.dark {
  .MuiDataGrid-columnHeader {
    &:hover {
      background-color: darken(#2c2c41, 3) !important;
    }
  }
  .TableWrapperV2__loader {
    background-color: rgba(#212133, .85);
  }
}
