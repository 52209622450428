.ciDark {
    // action colors
    --create: #1AD598;
    --delete: #EB3336;
    --read: #2360C5;
    --update: #F8CD4C;
    --createdelete: #EE7431;
    --deletecreate: #EE7431;
    --no-op: #AFAFAF;
    --import: #EE1489;
    // action onHover
    --createHover: #1F4646;
    --deleteHover: #492533;
    --readHover: #212E4F;
    --updateHover: #4C4437;
    --createdeleteHover: #874B31;
    --deletecreateHover: #874B31;
    --no-opHover: #3D3E4B;
    --importHover: #5C0D39;
    // severity colors
    --critical: #3fd3d8;
    --high: #498bc8;
    --medium: #4e64c0;
    --low: #5246b9;
    --ciDiagnosticCodeBlock: #181825;
    --nextProgressColor: #9984e3;
    --ciCodeFilesBG: #12131D;
    --ciCodeContentBG: #181825;
    --ciWizardContentBodyBG: #181825;
    --ciWizardLeftPanelBG: #212133;
    --ciProgressHover: #312A50;
    --ciEmptyImgBottomLine: #CECECC1A;
    --guardrailViolationsFailedBg: #351D25;
    --guardrailViolationBorder: #D8453F;
    --guardrailsViolationsPassedBg: #153232;
    --guardrailsViolationsPassedBorder: #125043;
    --guardrailsViolationsPassedIcon: #07C688;
    --guardrailsViolationsSoftFailedBg: rgba(211, 166, 0, 0.75);
    --guardrailsViolationsSoftFailedBorder: #efb40e;
    --guardrailsViolationsSoftFailedIcon: #edb919;
}

.ciLight {
    // action colors
    --create: #00DB94;
    --delete: #DE1518;
    --read: #1258CC;
    --update: #FBBF0D;
    --createdelete: #EE7431;
    --deletecreate: #EE7431;
    --no-op: #9F9F9F;
    --import: #EE1489;
    // action onHover
    --createHover: #BFE9DB;
    --deleteHover: #FCC;
    --readHover: #C9DDFF;
    --updateHover: #FFE69A;
    --createdeleteHover: #F5DBD0;
    --deletecreateHover: #F5DBD0;
    --no-opHover: #BBB;
    --importHover: #F39BCC;
    // severity colors
    --critical: #3fd3d8;
    --high: #498bc8;
    --medium: #4e64c0;
    --low: #5246b9;
    --ciDiagnosticCodeBlock: #F8F9FA;
    --nextProgressColor: #9984e3;
    --ciCodeFilesBG: #f6f5f8;
    --ciCodeContentBG: #30304A;
    --ciWizardContentBodyBG: #FFFFFF;
    --ciWizardLeftPanelBG: #F6F5F7;
    --ciProgressHover:#E3E2F5;
    --ciEmptyImgBottomLine: #CECECC;
    --guardrailViolationsFailedBg: #E0B1B0;
    --guardrailViolationBorder: #B23C3A;
    --guardrailsViolationsPassedBg: #C4DCD8;
    --guardrailsViolationsPassedBorder: #278D6F;
    --guardrailsViolationsPassedIcon: #278D6F;
    --guardrailsViolationsSoftFailedBg: rgba(237, 214, 127, 0.75);
    --guardrailsViolationsSoftFailedBorder: #efb40e;
    --guardrailsViolationsSoftFailedIcon: #edb919;
}
