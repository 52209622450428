@import "../../../styles/colors.scss";

.State {
  &-col {
    transition: all .3s ease;
    max-width: 185px;
    &:hover {
      opacity: 0.8;
    }
  }
  &__arrow {
    &.Drifted {
      color: $purple;
    }
    &.Codified {
      color: $turquoise;
    }
  
    &.Unmanaged {
      color: $strawberry;
    }
  
    &.Ghost {
      color: $salmon;
    }
    svg {
      font-size: 20px;
    }
  }
  &.Drifted {
    color: $purple;
  }
  &.Codified {
    color: $turquoise;
  }

  &.Unmanaged {
    color: $strawberry;
  }

  &.Ghost {
    color: $salmon;
  }
  &__container {
    gap: 5px;
    &-data {
      gap: 5px;
      &-title {
        font-size: 16px;
        gap: 5px;
      }
      &-text {
        font-size: 14px;
        color: var(--textLighter);
        font-weight: 300;
        &-total {
          font-weight: 300;
          color: var(--textLighter);

        }
        &-pastWeek {
          font-weight: 600;
          color: var(--textLighter);

          font-size: 13px;
        }
        &.bold {
          font-weight: 500;
        }
      }
    }
    &-icon {
      padding: 14px;
      border-radius: 50%;
      position: relative;
      svg {
        font-size: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.Drifted {
        background-color: $purple_Lilac;
      }
      &.Codified {
        background-color: $light_turquoise;
      }

      &.Unmanaged {
        background-color: $light_strawberry;
      }

      &.Ghost {
        background-color: $light_salmon;
      }
    }
  }
}
