.BreadCrumbs {
  display: flex;
  gap: 8px;

  &__single {
    a {
      color: var(--text) !important;
      opacity: 0.5;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
        color: var(--text);
      }
    }

    &:last-child {
      a {
        opacity: 0.8;
      }
    }
  }
}
