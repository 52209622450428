.GcpProviderServiceIntegration {

    .GcpProviderServiceCreation {
        gap: 25px; 

        ul{
            padding-left: 10px;
            li{
              margin-top: 10px;
            }
          }

        &__intro {
            gap: 3px;
        }
        &__custom-toggle{
          display: flex;
          align-items: flex-start;
          vertical-align: middle;
          column-gap: 10px;
          margin-bottom: 24px;
          >div:first-child{
            margin-top: 2px;
            flex-shrink: 0;
          }
          &__title{
            display: flex;
            align-items: center;
            .toggle-label{
              color: var(--label);
              margin-right: 8px;
              font-weight: 700;
              font-size: 14px;
            }
            svg{
              height: 14px;
              width: auto;
            }
          }
          &__subtitle{
            font-size: 12px;
            color: var(--textSuperLight);
            margin-top: 4px;
          }
          
        }
        &__heading{
          color: var(--label);
          font-weight: bold;
        }
        &__advanced-options{
          margin: 24px 0;
          &-title {
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-size: 16px;
            font-weight: 700;
            padding-bottom: 10px;
            border-bottom: 1px solid #383847;
            color: var(--primary);
            margin-bottom: 24px;
          }
        }
    }
}