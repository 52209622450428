@import "../../../styles/colors.scss";

.IacStacksFilters {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  min-width: 215px;
  // border-right: 1px solid var(--separatorColor);
  &__header {
    border-bottom: 1px solid var(--separatorColor) !important;
    padding: 0 6% 0 20px;
    height: 55px;
    position: sticky;
    top: 0;
    background: linear-gradient(0deg, #212132, #212132), linear-gradient(0deg, rgba(109, 104, 137, 0.5), rgba(109, 104, 137, 0.5));
    z-index: 1;
    &-title {
      font-weight: 700;
      color: #D8D8D8;

      font-size: 16px;
    }

    &-actions {
      gap: 1px;
      color: $primary;
    }
  }

  &__item {
    gap: 8px;

    &-title {
      max-width: 80%;
      color: white;
      font-size: 13px;
      gap: 3px;

      &.active {
        color: $primary;
      }
    }

    &-filterIcon {
      position: relative;
      width: min-content;
      &-icon {
        font-size: 12px;

        &.classification {
          fill: var(--text);
          color: var(--text);
          transform: translateY(1px);
          width: 16px;
        }

        &.emptyIcon {
          color: var(--text);
          transform: translateY(1px);
          width: 16px;
        }
  
        &.classificationActive {
          fill: $primary;
          color: $primary;
          transform: translateY(1px);
          width: 16px;
        }

        &.emptyIconActive {
          color: $primary;
          transform: translateY(1px);
          width: 16px;
        }

        &.asset-type-icon {
          max-height: 16px;
        }

        &.years-icon {
          max-height: 18px;
          width: 18px;
        }

        &.provider-type-icon {
          width: 18px !important;
        }
      }
    }
  }

  // styling collapse
  .ant-collapse-item {
    overflow: hidden;
    cursor: pointer;
  }
  .ant-collapse-content {
    background-color: transparent !important;
  }

  .ant-collapse-header {
    color: $text;
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  .ant-collapse-arrow {
    top: 38.5% !important;
    right: 15px !important;
  }

  // inside white card mode
  .inCardMode {
    .ant-collapse-item {
      margin: 0 !important;
      overflow: hidden;
    }
  }
}


.light {
  .IacStacksFilters {
    &__header {
      background: white;

      &-title {
        color: var(--textLighter);
      }

      &-actions {
        gap: 1px;
        color: $primary;
      }
    }

    &__item {
      gap: 8px;

      &-title {
        color: var(--textLighter);
        &.active {
          color: $primary;
        }
      }
      &-filterIcon {
        position: relative;
        width: min-content;
        &-icon {
            // fill: var(--text);
            color: var(--text);
          
        }
      }
    }
  }
}