body.light {
    --card: #ffffff;
    --appBG: #f6f5f8;
    --codeBG: #30304A;
    --comingSoonCardBG: #fafafa;
    --comingSoonCardBorder: transparent;
    --layoutBorder: rgba(81, 81, 81, .05);
    --cardBorder: transparent;
    --codifyMenuBG: #26263b;
    --codifyMenuActiveBG: #30304A;
    --codifyMenuActiveFlag: #9872FE;
    --codeCardOverlay: #393957;
    --darkerCard: #f5f5f585;
    --assetCodeBG: #30304A;
    --innerCard: #F5F5F5;
    --innerIntegrationCard: #F5F1FC;

    // header
    --sidebarLink: #9696a0;
    --breadcrumbLink: #252525;
    --header-btn-border: #abb2b68e;
    --header-btn-BG: transparent;
    --header-user-menu-arrow: rgba(81, 81, 81, 0.3);
    --aiBtnBG: #f9f9f9;
  
    // base colors
    --primary: #9872FE;
    --lightPrimary: rgba(152, 114, 254, .2);
    --darkPrimary: #7f51fb;
    --middlePrimary: rgba(145, 106, 252, 0.6);
    --red: #e36f7e;
    --redFlagBG: rgba(227, 111, 126, 0.27);
    --green: #71c9ca;
    --greenFlagBG: rgba(113, 201, 202, 0.25);
    --ghost: #aa9876;
    --ghostFlagBG: rgba(170, 152, 118, 0.25);
    --orange: #f78c6c;
    --orangeFlagBG: rgba(247, 140, 108, 0.15);
    --blue: #59a0fe;
    --blueFlagBG: rgba(89, 160, 254, 0.15);
    --grey: #929292;
    --darkGrey: #737373;
    --lightGrey: #c7c7c7;
    --notSupported: #ECECEC;
    --pending: #0176D2; // pending
    --light_pending: rgba(1, 118, 210, .15); // pending

    // typo
    --text: #252525;
    --textLighter: #515151;
    --textMuted: #909099;
    --textSuperLight: rgba(37, 37, 37, 0.5);
  
    // separator line
    --separatorColor: rgba(81, 81, 81, 0.15);
    --separatorColorLight: rgba(81, 81, 81, 0.07);
    --codeSeperator: rgba(255, 255, 255, 0.15);
  
    // table
    --columnBG: #fafafa;
    --columnText: rgba(37, 37, 37, 0.7);
    --tableRowBorder: rgba(224, 224, 224, 0.5);
    --tableCheckbox: rgba(37, 37, 37, 0.18);
    --tableRowHoverBG: rgba(145, 106, 252, 0.12);
    --tabeSelectRowBG: rgba(145, 106, 252, 0.12);
    --innerCardBG: #f0f0f8;
    --innerCardCodeBG: #38385a;
    --flagsBG: rgba(238, 238, 238, 0.7);
  
    // buttons
    --disableBG: #f1f1f1;
    --disableborder: #e4e3e3;
    --disableColor: #A8A8A8;
    --exitButtonX: rgba(4, 21, 39, 0.55);
    --darkenExitButtonX: rgba(4, 21, 39, 0.7);
    --buttonBG: rgba(152, 114, 254, .2);
    --innerButtonBG: #c8b4fecf;
    --gradientButtonShadow: rgba(200, 200, 200, 0.25);
    --actionBtnColor: #f0f0f0;
  
    // forms
    --inputBorder: #d9e1e7;
    --inputBG: transparent;
    --inputColor: rgba(4, 21, 39, 0.8);
    --inputPlaceholder: rgba(4, 21, 39, 0.4);
    --label: rgba(37, 37, 37, 0.5);
    --formText: #515151;
    --formLabel: #333333;

    // inventory flags filter
    --misconfiguration: #e31174;
    --misconfigurationBG: #f9cee3;
    --reliability: #0090ff;
    --reliabilityBG: #cce8ff;
    --optimization: #7ddbde;
    --optimizationBG: #e5f7f8;
    --history: #22CAFF;
    --historyBG: #b9e9f7ee;
    --git: #ec5f62;
    --gitBG: #fbdee0;
    --comments: #61cd9a;
    --commentsBG: #dff4eb;
    --relationship: #F257BB;
    --relationshipBG: #fde5f5;
    --noTags: #9872FE;
    --noTagsBG: #e7dffb;

    // others
    --eventViewerBorder: rgba(109, 103, 135, 0.5);
    --eventReqResBG: #06060B;
    --codeFilesBG: #363659;
    --popoverBorderColor:transparent;
    --CiCollapseBG: #F5F5F5;
    --ciOpenCollapseBG: #E1DCF5;
    --ciCollapseBorder: #DCDCDC;
    --dividerColor:#66B7EB;
    --badgeBG:#CFBDFF;
    --discoveryBorder:#59595933;
    --discoveryTableBG: #E4E4E4;
    --discoveryTableColor: #575757;
    --policyIconColor: #9872FE;
    --policyIconBG: rgba(152, 114, 254, 0.2);
    --codeBG:#303148;
    --undeterminedFlagBG: #F4F4F4;
    --undeterminedFlagBorder: #9696A0;
    --assetTypeCountBG: #cebdff;
    --assetTypeCountColor: #8559fe;
    --gradientColorBG: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
    //fix-drift
    --driftDetailsHeaderBG: #22222F;
    --driftDetailsBorder: #393946;
    --driftShowMoreBG: #22222F;
    --driftDetailsRowBG: #15151C;
    --driftBlockBG: #212133;
    --otherTypesDividerColor: #f0eff2;
    
    --connectedServicesBG: #ffffff;
    --mapRegionsBG: white;
  }
  
  body.dark {
    --card: #212133;
    --appBG: #181825;
    --codeBG: #181825;
    --comingSoonCardBG: transparent;
    --comingSoonCardBorder: #363653;
    --layoutBorder: #30304A;
    --codifyMenuBG: #13131d;
    --codifyMenuActiveBG: #9872FE;
    --codifyMenuActiveFlag: white;
    --cardBorder: #30304A;
    --codeCardOverlay: #212133;
    --darkerCard: #1f1f2e9e;
    --assetCodeBG: #212133;
    --innerCard: #312F4B;
    --innerIntegrationCard: #312F4B;

    // header
    --sidebarLink: whitesmoke;
    --breadcrumbLink: #ffffff;
    --header-btn-border: #30304A;
    --header-btn-BG: #181825;
    --header-user-menu-arrow: #d3d3d3af;
    --aiBtnBG: #2d2c45;

    // base colors
    --primary: #9872FE;
    --lightPrimary: rgba(152, 114, 254, .2);
    --darkPrimary: #7f51fb;
    --middlePrimary: rgba(145, 106, 252, 0.6);
    --red: #e36f7e;
    --redFlagBG: rgba(227, 111, 126, 0.2);
    --green: #71c9ca;
    --greenFlagBG: rgba(113, 201, 202, 0.2);
    --ghost: #aa9876;
    --ghostFlagBG: rgba(170, 152, 118, 0.3);
    --orange: #f78c6c;
    --blue: #59a0fe;
    --blueFlagBG: rgba(89, 160, 254, 0.15);
    --grey: #929292;
    --darkGrey: #737373;
    --lightGrey: #c7c7c7;
    --notSupported: rgba(255, 255, 255, 0.281);
    --pending: #59a0fe; // pending
    --light_pending: rgba(89, 160, 254, .15); // pending

    //typo
    --text: #ffffff;
    --textLighter: rgba(255, 255, 255, 0.801);
    --textMuted: #909099;
    --textSuperLight: rgba(255, 255, 255, 0.7);

    // separator line
    --separatorColor: rgba(255, 255, 255, 0.1); //#3d3d5f
    --separatorColorLight: rgba(255, 255, 255, 0.07);
    --codeSeperator: rgba(255, 255, 255, 0.15);

    // table
    --columnBG: #2c2c41;
    --columnText: #d5d5d9;
    --tableRowBorder: rgba(81, 81, 81, 0.35);
    --tableCheckbox: rgba(255, 255, 255, 0.3);
    --tableRowHoverBG: rgba(145, 106, 252, 0.15);
    --tabeSelectRowBG: rgba(145, 106, 252, 0.15);
    --innerCardBG: #2c2c41;
    --innerCardCodeBG: #181825;
    --flagsBG: #41405F;

    // buttons
    --disableBG: rgba(167, 176, 184, 0.1);
    --disableborder: rgba(167, 176, 184, 0.1);
    --disableColor: #a8a8a8a9;
    --exitButtonX: rgba(255, 255, 255, 0.5);
    --darkenExitButtonX: rgba(255, 255, 255, 0.6);
    --buttonBG: #30304A;
    --innerButtonBG: #63639754;
    --gradientButtonShadow: rgba(0, 0, 0, 0.25);
    --actionBtnColor: rgba(255, 255, 255, 0.801);
  
    // forms
    --inputBorder: rgba(225, 231, 234, 0.2);
    --inputBG: transparent;
    --inputColor: white;
    --inputPlaceholder: rgba(255, 255, 255, 0.4);
    --label: rgba(255, 255, 255, 0.6);
    --formText: rgba(255, 255, 255, 0.7);
    --formLabel: rgba(255, 255, 255, 0.85);

     // inventory flags filter
     --misconfiguration: #e31174;
     --misconfigurationBG: #5a1d46;
     --reliability: #0090ff;
     --reliabilityBG: #16436f;
     --optimization: #7ddbde;
     --optimizationBG: #3b5a66;
     --history: #22CAFF;
     --historyBG: #bdf8fb58;
     --git: #ec5f62;
     --gitBG: #5d3440;
     --comments: #61cd9a;
     --commentsBG: #335551;
     --relationship: #F257BB;
     --relationshipBG: #803b67;
     --noTags: #9872FE;
     --noTagsBG: #493c6b;
     // others
     --eventViewerBorder: rgba(109, 103, 135, 0.5);
     --eventReqResBG: #06060B;
     --codeFilesBG: #12131D;
     --popoverBorderColor:#45455B;
     --CiCollapseBG: #322F4B;
     --ciOpenCollapseBG: rgba(152, 114, 254, .2);
     --ciCollapseBorder: rgba(109, 103, 135, 0.5);
     --dividerColor:#66B7EB;
     --badgeBG:#373259;
     --discoveryBorder:#363442;
     --discoveryTableBG: #E4E4E4;
     --discoveryTableColor: #FFFFFF;
     --policyIconColor: white;
     --policyIconBG: rgba(255,255,255,0.2);
     --codeBG: #13131C;
     --undeterminedFlagBG: #44444E;
     --undeterminedFlagBorder: #9696A0;
     --assetTypeCountBG: #504283;
     --assetTypeCountColor: #9872FE;
     --gradientColorBG: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
     //fix-drift
     
     --driftDetailsHeaderBG: #34354C;
     --driftDetailsBorder: #30304A;
     --driftShowMoreBG: #37325B;
     --driftDetailsRowBG: #212133;
     --driftBlockBG: #212133;
     --otherTypesDividerColor: #30304a;

     --connectedServicesBG: #30304A;
     --mapRegionsBG: #26266c;
  }