@import "../../../styles/colors.scss";

.IacStacksCategories {
    padding: 0 25px;
    position: relative;
    gap: 30px;
    &__button {
        border: 0;
        border-bottom: 4px solid transparent;
        // background-color: var(--appBG);
        background-color: transparent;
        cursor: pointer;
        padding:15px;
        gap: 10px;
        transition: all 0.3s ease;
        min-width: max-content;
        color: white;
        svg {
            width: auto;
            height: 14px;
            color: var(--primary);
            transform: translateY(2px);
            filter: grayscale(100%);
        }
        &:hover {
            border-bottom-color: var(--primary);
            color: var(--primary) ;
            svg {
                filter: grayscale(0%);
            }
        }
        &.active {
            border-bottom-color: var(--primary);
            font-weight: bold;
            color: var(--primary) ;
            svg {
                filter: grayscale(0%);
            }
        }
        &.disabled {
            background-color: var(--disableBG) !important;
            color: var(--disableColor) !important;
            cursor: not-allowed;
        }

        &-text {
            gap: 10px;
            &-flag {
                background-color: var(--lightGrey);
                width: max-content;
                padding: 1px 6px;
                font-size: 9px;
                border-radius: 20px;
                color: var(--darkGrey) !important;
                font-weight: normal;
                &.active {
                    background-color: var(--primary);
                    color: white !important;
                }
            }
        }
    }
    &::after{
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 0px;
        width: calc(100% - 50px);
        height: 1px;
        background-color: var(--separatorColor);
    }
  }
  