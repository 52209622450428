.GradientBorderElement {
    position: relative;
    width: 220px;
    height: 100%;
    margin: 0px 20px;
    max-height: 375px;
    background: linear-gradient(to top left, #9872FF 50%, #33fdd8 50%);
    overflow: hidden;
    z-index: 0;
    border: 2px dashed var(--appBG);
    &__mask {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: var(--card);
    }
    &__content {
        border-radius: 7px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(152, 114, 254, 0.10) 0.71%, rgba(51, 253, 216, 0.10) 100%);
    }
}