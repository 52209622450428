.ColumnVisibilityPanel{
    width: 100%;
    &__header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      border-bottom: 1px solid var(--popoverBorderColor);
      div{
        font-size: 16px;
        font-weight: bolder;
        color: white;
      }
    }
    &__content{
    
      padding: 18px;
      .SearchBox input{
        border-color: var(--header-btn-border) !important;
        color: white !important;
        &::placeholder{
          color: white;
        }
      }
      &__actions{
        display: flex;
        align-items: center;
        margin: 10px 0;
        button{
          padding: 0 !important;
          color: #8559FE !important;
          font-weight: light !important;
        }
        div{
          width: 2px;
          height: 2px;
          background-color: #8559FE;
          margin: 0 8px;
          border-radius: 50%;
        }
      }
      &__checkbox-group{
        overflow-y: auto;
        max-height: 220px;
        display: flex !important;
        flex-direction: column !important;
        gap: 8px !important;
        margin-bottom: 17px !important;
        label {
            span {
                font-size: 14px !important;
                color: rgba($color: white, $alpha: 0.5) !important;
            }

            &.ant-checkbox-wrapper-checked {
                span {
                    color: #8559FE !important;
                }

                .ant-checkbox-inner {
                    border-color: #8559FE !important;
                }
            }

            .ant-checkbox-inner {
                border-color: #B9B9B9 !important;
                width: 16px;
                height: 16px;

            }
        }
      
      } 
      &__error{
        margin-bottom: 10px;
        display: block;
        color: #E36F7E;
      }
      button.AppBtn{
        display: block;
        width: 100%;
      }
   
    }
  }

    .light {
      .ColumnVisibilityPanel {
        &__content {
          &__checkbox-group {
            label {
              span {
                color: rgba($color: rgb(19, 19, 19), $alpha: 0.5) !important;
              }
              &.ant-checkbox-wrapper-checked {
                span {
                    color: #8559FE !important;
                }
                .ant-checkbox-inner {
                    border-color: #8559FE !important;
                }
              }
            }
          }
        }
      }
    }