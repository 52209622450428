@import "../../styles/colors.scss";

.NotificationAlert {
  border-radius: 4px;
  background-color: $lightPrimary;
  border: 1px solid rgba($primary, 0.5);
  padding: 12px 20px;
  color: $primary;
  gap: 10px;

  &__text {
    gap: 10px;
  }

  &__btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &.noBorder {
    border-color: transparent;
  }

  &.stateFileDeleted {
    color: #8f8f8f;
    background-color: rgba(#8f8f8f, 0.1);
  }

  &.full {
    color: #e36f7e;
    background-color: rgba(#e36f7e, 0.1);
  }
  &.warn {
    color: #f6cd8b;
    background-color: rgba(#f6cd8b, 0.1);
  }
  &.half {
    color: #ff8800;
    background-color: rgba(#ff8800, 0.1);
  }
  &.none {
    color: #71c9ca;
    background-color: rgba(#71c9ca, 0.1);
  }
  &.loading {
    color: #916afc;
    background-color: rgba(#916afc, 0.1);
  }
  &.notSupported {
    color: #916afc;
    background-color: rgba(#916afc, 0.1);
  }
  &.stateFileEmpty {
    color: #59a0fe;
    background-color: rgba(#59a0fe, 0.1);
  }
}
