.SplashScreen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #212133;

  &__text {
    height: 45px;
    width: auto;
  }

  &__loading {
    font-weight: 300;
    color: white;
    font-size: 30px;
    margin-top: 40px;
  }
}
