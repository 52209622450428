.TableContextMenu {
    .MuiMenu-list {
        padding: 0px 0px !important;
        margin-bottom: 0px !important;
    }

    .MuiPaper-root {
        min-width: 200px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
    }

    .MuiDivider-fullWidth {
        margin: 0px !important;
    }

    .MuiMenuItem-root {
        font-size: 14px;
        padding: 6px 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: background-color 0.2s ease;

        .filter-name {
            margin-left: 4px;
            font-weight: 500;
        }

        svg,
        img {
            width: 16px;
            height: 16px;
        }

        &.filter-menu-trigger {
            position: relative;

            &::after {
                content: "›";
                position: absolute;
                right: 16px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    .MuiDivider-root {
        margin: 8px 0;
    }

    .asset-type-icon {
        width: 16px !important;
        height: 16px !important;
        fill: white;
    }

    .location-icon {
        width: 16px !important;
        height: 16px !important;
    }

    @keyframes moveGradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .filter-name {
        font-weight: 600;
        background: linear-gradient(270deg, rgb(37, 223, 192) 0%, rgb(152, 114, 254) 99.75%);
        background-size: 200% 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: moveGradient 1s ease infinite;
    }

    &.dark {
        .MuiPaper-root {
            background-color: #212133;
            color: #ffffff;
            border: solid 2px #30304a;
        }

        .MuiMenuItem-root {
            color: #ffffff;

            &:hover {
                background-color: rgba(255, 255, 255, 0.08);
            }

            &.Mui-disabled {
                color: rgba(255, 255, 255, 0.38);
            }

            svg {
                fill: #ffffff;
            }
        }

        .MuiDivider-root {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }

    &.light {
        .MuiPaper-root {
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.87);
            border: solid 2px #ededed;
        }

        .MuiMenuItem-root {
            color: rgba(0, 0, 0, 0.87);

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            &.Mui-disabled {
                color: rgba(0, 0, 0, 0.38);
            }

            svg {
                fill: rgba(0, 0, 0, 0.87);
            }
        }

        .MuiDivider-root {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }
}
