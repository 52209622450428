@import "../../../styles/colors.scss";
.StepLink {
  background-color: transparent;
  position: relative;

  &__text {
    color: var(--disableColor);
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s ease;
  }

  &__number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid var(--disableColor);
    background-color: var(--card);
    position: absolute;
    bottom: -16px;
    transition: all 0.3s ease;

    &-inner {
      background-color: var(--disableColor);
      color: white;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      line-height: 14px;
      transition: all 0.3s ease;
    }
  }

  &.done, &.active {
    .StepLink__text {
      color: var(--text);
      transition: all 0.3s ease;
    }
    .StepLink__number {
      border-color: $primary;
      transition: all 0.3s ease;
      &-inner {
        background-color: $primary;
        transition: all 0.3s ease;
      }
    }
  }
}
