$lightGreen: rgba(26, 213, 152, 0.2);
$lightRed: rgba(235, 51, 54, 0.2);
$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.CiSteps {
   &__header {
    display: grid;
    grid-template-columns: 15% calc(85% - 20px);
    gap: 20px;
    &-container {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &-icon {
      fill: var(--text);
    }
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--text);
    }
   }
   &__container {
      display: grid;
      grid-template-columns: 15% calc(85% - 20px);
      gap: 20px;
      height: calc(100vh - 150px);
      transition: all 0.3s ease;
      &.closed {
        grid-template-columns: 63px calc(100% - 63px - 20px);
      }
   }
    &__link-container {
        display: flex;
        gap: 10px;
        padding-top: 3px;
    }

    &__text-link{
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
    }
    
    &__workflows {
      overflow: auto;
      height: calc(100vh - 250px);
      //overrides collapse
      .ant-collapse-item {
        overflow: hidden;
        cursor: pointer;
        background-color: var(--CiCollapseBG);
        border: 1px solid var(--ciCollapseBorder);
        border-radius: 5px;
      }
      .ant-collapse-content {
        background-color: var(--card) !important;
      }

      .ant-collapse-header {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        color: var(--text);
        width: 100%;
        height: 50px;
        padding: 10px;
      }
      .ant-collapse-arrow {
        margin: 0px 5px;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
            color: var(--text);
            background-color: var(--ciOpenCollapseBG) !important;
            border-bottom: 2px solid var(--primary) !important;
            font-weight: 600 !important;
        }
      }
      &-sortArrow {
        fill: var(--primary);
        width: 16px;
        height: 16px;
        opacity: 0;
        transform: rotate(180deg);
        &.up {
          transform: rotate(180deg);
          transition: all 0.2s ease;
          opacity: 1;
        }
        &.down {
          transform: rotate(0deg);
          transition: all 0.2s ease;
          opacity: 1;
        }
      }
      &__collapseToggle {
        background-color: var(--card);
        width: 35px;
        height: 35px;
        border: 1px solid var(--ciCollapseBorder);
        border-radius: 50%;
        right: 19px;
        bottom: 25%;
        cursor: pointer;
        padding: 8px;
        color: var(--sidebarLink) !important;
        transition: color 0.2s ease;
        position: relative;
        transform: rotate(180deg);
        &:hover {
            transition: color 0.2s ease;
            color: var(--primary) !important;
        }
        &.collapsed{
          transform: rotate(0deg);
        }
    }
  }
  &__empty {
    height: 100vh;
    padding-top: 20px !important;
    &-container {
      align-items: center;
      gap: 20px;
      margin: 0 auto;
      max-width: 700px;
    }
    &-img {
      color: var(--ciEmptyImgBottomLine);
    }
    &-divider {
      width: 77px;
      height: 2px;
      background: $gradient-color;
    }
    &-description { max-width: 555px; }
    &-whyFirefly {
      gap: 4px;
      font-weight: bold;
      &-gradientText {
        background: $gradient-color;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-bullets {
      align-items: flex-start !important;
      &-item {
        align-items: center;
        &-point {
          height: 8px;
          width: 8px;
          border: 2px solid var(--primary);
          border-radius: 50%;
          &.blue { border-color: #7EAEF2; }
          &.green { border-color: #59FEE0; }
        }
      }
    }
  }
}

.CiStepsCollapse {
    width: 100%;
    height: 100%;
    &__pagination {
        border-bottom: 0px !important;
        overflow: visible !important;
        margin-right: 10px;
        .MuiIconButton-root:hover {
          color: var(--primary);
        }
        .MuiTablePagination-toolbar {
          color: var(--textLighter);
          p {
            margin-top: 10px !important;
          }
          .MuiTablePagination-selectIcon {
             color: var(--textLighter);
             &:hover { color: var(--primary); };
          }
        }
    }
    &__header {
        display: grid;
        flex-direction: row;
        width: 100%;
        grid-template-columns: 20% 20% 25% 10% 15% 10%;
        align-items: center;
        &-title {
          &.sort {
            cursor: pointer;
            &:hover {
              .CiSteps__workflows-sortArrow { 
                opacity: 1;
              }
           }
           &.marg { margin-right: 4px; }
          }
        }
        &.titles {
            grid-template-columns: 20% 20% 24% 11% 20% 5%;
            margin: 0px 0px 10px 50px;
            font-weight: bold;
            width: auto;
            font-size: 16px;
        }
        &-tfVersion{
          max-width: 90px;
        }
        &-vcs {
          gap: 7px;
            &-icon {
                width: 25px;
                height: 25px;
                &.tf {
                  width: 20px;
                  height: 20px;
                }
            }
            &-text {
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;
            }
        }
        &-status {
          gap: 7px;
          &-text {
            font-size: 11px;
            &.FINISHED, &.apply_success {
              color:#1AD598;
            }
            &.FAILED, &.apply_error, &.plan_error, &.init_error, &.blocked {
              color: #EB4C3D;
            }
            &.QUEUED {
              color:#8A8497;
            }
            &.plan_success { color: #DBB01A }
            &.plan_no_changes {
              color: #DBB01A80;
            }
            &.apply_no_changes {
              color: #1AD59880;
            }
            &.RUNNING, &.applying, &.planning {
              color:#9872FE;
            }
          }
          &-icon {
            padding: 7px;
            border-radius: 50%;
            font-size: 25px;
            color: var(--CiCollapseBG);
            &.table {
              padding: 0px;
              border-radius: 0px;
              font-size: 16px;
              width: 16px;
            }
            &.FINISHED, &.apply_success  {
              background-color:#1AD598;
              &-table {
                color: #1AD598;
              }
            }
            &.FAILED, &.apply_error, &.plan_error, &.init_error {
              color:#EB4C3D;
              background-color: var(--CiCollapseBG);
              box-shadow: 0px 0px 0px 5px #EB4C3D inset;
              padding: 2px;
              &-table {
                font-size: 18px;
                color: #EB4C3D;
              }
            }
            &.blocked {
              color:var(--CiCollapseBG);
              background: #EB4C3D;
              padding: 4px;
              width: 23px;
              height: 23px;
              &-table {
                font-size: 16px;
                color: #EB4C3D;
              }
            }
            &.QUEUED { 
              background-color:#8A8497;
            }
            &.plan_success {
              color:#DBB01A;
              background-color: var(--CiCollapseBG);
              box-shadow:  0px 0px 0px 5px #DBB01A inset;
              padding: 2px;
              &-table { 
                 color: #DBB01A;
              }
            }
            &.plan_no_changes {
              color: #DBB01A80;
              background-color: var(--CiCollapseBG);
              box-shadow:  0px 0px 0px 1px #dbb01a2b inset;
              padding: 0px;
              &-table { 
                 color: #DBB01A80;
              }
            }
            &.apply_no_changes {
              background-color: #1AD59880;
              &-table { 
                 color: #1AD59880;
              }
            }
            &.RUNNING, &.applying, &.planning {
              background-color:#9872FE;
            }
            &-edit {
              align-self: center;
              width: 16px;
              height: 16px;
              &.not-allowed {
                cursor: not-allowed;
              }
            }
          }
        }
    }
    .MuiDataGrid-row {
      color: var(--inputColor);
    }
    &__table {
      &-assets {
        cursor: pointer;
        gap: 8px;
        color: var(--create);
        border: 1px solid transparent;
        background-color: $lightGreen;
        font-size: 10px;
        font-weight: bold;
        border-radius: 24px;
        padding: 4px 8px;
        height: 20px;
        transition: all 0.2s ease;
        &:hover {
          border-color: var(--create);
          background-color: darken($lightGreen, 3);
        }
        &.apply_error, &.plan_error {
          color: var(--delete);
          background-color: $lightRed;
          &:hover {
            border-color: var(--delete);
            background-color: darken($lightRed, 3);
          }
        }
      }
      &-pr {
        &-icon {
          height: 13px;
          width: 13px;
          path {
            fill: var(--text);
          }
        }
        &:hover {
          span {
            color: var(--primary);
          }
          svg {
            color: var(--primary);
          }
          path {
            fill: var(--primary);
          }
        }
      }
      &-cost {
        &.down { color: var(--create) }
        &.up { color: var(--delete) }
        &-tooltip {
          max-width: unset !important;
          .ant-tooltip-inner {
            padding: 5px 0px !important;
          }
          &-header {
            &-title {
              border-bottom: 1px solid var(--ciCollapseBorder);
              font-weight: 500;
              padding: 5px 10px;
            }
            &-content { padding: 0px 10px; }
          }
      }
    }
  }
  &__guardrails {
    height: 22px;
    min-width: 22px;
  }

}
.CiFiltersBadges {
  flex-wrap: wrap;
  padding-inline: 3px;
  min-height: 20px;
}
