.light {
  @import "~antd/lib/style/themes/default.less";
  @import "~antd/dist/antd.less";

  // https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

  // basic layout
  @primary-color: #9872fe;
  @layout-body-background: #ffffff;
  @layout-header-background: #ffffff;
  @layout-sider-background: #ffffff;
  @border-radius-base: 4px;

  // typography
  @text-color: #252525;

  // Breadcrumb
  @breadcrumb-base-color: #252525;
  @breadcrumb-last-item-color: rgba(37, 37, 37, 0.8);
  @breadcrumb-link-color: rgba(37, 37, 37, 0.5);
  @breadcrumb-link-color-hover: #252525;

  // Table
  @table-bg: transparent;
  @table-header-bg: #fafafa;
  @table-border-color: rgba(224, 224, 224, 0.5);
  @table-header-color: rgba(37, 37, 37, 0.7);
  @table-header-sort-active-bg: #eeeeee;
  @table-header-sort-bg: #fafafa;
  @table-row-hover-bg: rgba(145, 106, 252, 0.12);

  // Filter
  @table-header-filter-active-bg: rgba(0, 0, 0, 0.04);

  // Buttons
  @btn-border-radius-base: 4px;
  @btn-height-base: 30px;

  // Form
  @label-required-color: transparent;
  @label-color: rgba(37, 37, 37, 0.5);

  // Select
  @select-border-color: rgba(81, 81, 81, 0.15);

  // Input
  @input-border-color: rgba(81, 81, 81, 0.15);
  @input-color: #515151;

  // Tooltip
  @tooltip-color: #515151;
  @tooltip-bg: #fff;

  // Tabs
  @tabs-hover-color: #9b78fc;
}
