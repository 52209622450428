@import "../../../styles/colors.scss";

.AzureIntegration {
    .DarkCopyBox__wrapper-box {
        align-items: baseline !important;
    }

    .AzurePreferances {
        width: 100%;
        max-width: 550px;
        gap: 30px;
        &__block {
            align-items: flex-start;
        }
    }

    .azureTerraformModule {
        &__note {
            border: 1px solid #616179;
            background-color: #302b4d;
            margin-bottom: 15px;
            border-radius: 8px;
            width: 718px;
            padding: 10px;
            display: flex;
            flex-direction: row;
        }

        &__content {
            display: flex;
            flex-direction: row;
            gap: 7px;
            align-items: center;

            &-text {
                font-size: 14px;
                color: var(--textLighter);
            }
        }
    }
}
