@import "../../../styles/colors.scss";

.SingleIntegrationTableWrapper{
    margin-bottom: 50px;
}
.SingleIntegrationTable {
    .MuiDataGrid-main {
        border: 1px solid var(--discoveryBorder);
        border-radius: 5px;

        .MuiDataGrid-columnHeaders {
            padding-top: 14px;
            padding-bottom: 14px;
            background-color: var(--discoveryTableBG);
            background: linear-gradient(0deg, #1F1D2D, #1F1D2D), linear-gradient(0deg, #363442, #363442);

            .MuiDataGrid-columnHeaderTitle {
                font-weight: 700;
                color: var(--discoveryTableColor);
            }
        }

        .MuiDataGrid-row {
            &:hover {
                background-color: inherit !important;
            }

            &:nth-child(even) {
                background: var(--card);

                &:hover {
                    background-color: var(--card) !important;
                }
            }

            &:last-child .MuiDataGrid-cell {
                border-bottom: 0 !important;
            }

            .MuiDataGrid-cell {
                font-size: 14px;
            }
        }

    }

    &__percentage-bar-style{
        width: 100%;
        height: 35px;
    }

    &__percentage-no-tagging-style{
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    &__coverage {
        &__tooltip{
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            padding: 0 15px;
            li{
                display: flex;
                gap:10px;
                align-items: center;
                column-gap: 15px;
                 span:first-child {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
        }
        &__progress {
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            display: flex;
            width: 150px;
            border-radius: 10px;
            overflow: hidden;

            li {
                height: 8px;
                display: flex;
                gap: 10px;
            }
        }
        &__tooltip > li > span, 
        &__progress li{
            &.managed {
                background-color: $turquoise;
            }

            &.ghost {
                background-color: $salmon;
            }

            &.modified {
                background-color: $purple;
            }

            &.unmanaged {
                background-color: $strawberry;
            }

            &.unknown {
                background-color: $strawberry;
            }

            &.pending {
                background-color: $light_turquoise;
            }
        }
    }

    &__badge {
        background-color: var(--badgeBG) !important;
        color: var(--primary);
        padding: 0px 8px;
        border-radius: 20px;
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: fit-content;
        margin: auto;
        border: 1px solid transparent;
        transition: all .2s ease;
        &.pointer{
            cursor: pointer;
            &:hover{
                border-color: var(--primary);
            }
        }
    }
    &__actions{
        gap: 5px;
        display: grid;
        grid-template-columns: 22px 22px 22px;
        align-items: center;
        gap: 8px;
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}

.SingleIntegrationDiscoveryCards{
    display: flex;
    gap: 16px;
    align-items: stretch;
}

.light {
    .SingleIntegrationTable {
        .MuiDataGrid-main {
            .MuiDataGrid-columnHeaders {
                background: unset;
            }
        }
    }
}
