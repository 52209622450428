.FrameworkCell {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;


  .icon-wrapper {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .icon-wrapper:hover .icon-default {
    display: none;
  }

  .icon-wrapper:hover .icon-hover {
    display: inline-flex;
  }

  .icon-hover {
    display: none;
    width: 24px;
    height: 24px;
  }

  .icon-default {
    width: 24px;
    height: 24px;
  }

  .icon-default svg,
  .icon-hover svg {
    width: 100%;
    height: 100%;
  }

  .ant-popover-inner-content {
    padding: 10px !important;
  }

}