@import "./colors.scss";
$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

// ------ pages layout -------

.basic-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh - 170px);
}

.full-page {
  width: 100%;
  height: 100%;
}

.tab-page {
  min-height: 60vh;
}
.gradientDivider {
  width: 77px;
  height: 2px;
  background: $gradient-color;
}
.gradientBG {
  background: var(--gradientColorBG);
}
// ------ card ---------

@mixin card {
  display: flex;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: var(--card);
  border: 1px solid var(--cardBorder);
}

.basic-card {
  @include card;
  padding: 20px;
}

.no-padding-card {
  @include card;
  padding: 0;
}

.thin-card {
  @include card;
  padding: 20px 20px 12px 20px;
}

.no-flex-card {
  background-color: var(--card);
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--cardBorder);
}

.plain-card {
  width: 100%;
  background-color: var(--card);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--cardBorder);
}

// ------ elements positioning --------
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.col-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.align-items-start {
  align-items: flex-start;
}
.between {
  justify-content: space-between;
}
.flex-end { justify-content: flex-end;}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.full-page-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181825;
}

.form-wizard-wrapper {
  width: 50%;
  max-width: 400px;
}
.img16 {
  width: 16px;
  height: 16px;
}
.img12 {
  width: 12px;
  height: 12px;
}
.g5 {
  gap: 5px;
}
.g8 {
  gap: 8px;
}
.g10 {
  gap: 10px;
}
.g15 {
  gap: 15px;
}
.g20 {
  gap: 20px;
}
.g40 { 
  gap: 40px;
}
.pad0 { padding: 0px;}
.pad5 { padding: 5px;}
.pad10 { padding: 10px;}
.padIn10 { padding: 0px 10px; }
.pad20 {
  padding: 20px;
}
.mgBtm5 {
  margin-bottom: 5px;
}
.mgTtm5 {
  margin-top: 5px;
}
.mgTtm10 {
  margin-top: 10px;
}
.mgLeft5 {
  margin-left: 5px;
}
.mgRight4 {
  margin-right: 4px;
}
.hide-element {
  display: none;
}