.FixDrift {
    background: var(--driftBlockBG);
    border-radius: 4px;
    .DarkCopyBox__wrapper-box {
        background: #30304A !important;
        border: 1px solid transparent;
        border-left: 5px solid #9872FE;
    }   
    .DarkCopyBox__wrapper-box-text {
        color: white !important;
        max-height: 82vh;
    }
    &.pr {
        background: unset;
        border-radius: 0px;
        .DarkCopyBox__wrapper-box {
            background: #212132 !important;
            gap: 5px;
            &-text {
              width: 100% !important;
            }
        }
    }
    &__ghost {
      &-description {
        width: 35%;
        &-stacks {
          .IaCTab__list-item {
            gap: 10px !important;
            padding: 5px 0px !important;
          }
        }
      }
    }
    &__cft {
      .DarkCopyBox__wrapper-box-text {
        padding: 10px;
      }
    }
    &-prIcon {
      margin-top: 5px;
    }
    &__content-collapse {
        overflow: auto;
        padding: 20px 25px;
        //overrides collapse
        .ant-collapse-item {
          overflow: hidden;
          cursor: pointer;
          background-color: #322F4B;
          border: 1px solid rgba(109, 103, 135, 0.5);
          border-radius: 5px;
        }
        .ant-collapse-content {
          padding: 10px;
          background-color: #13131c !important;
          overflow: auto;
        }
        .ant-collapse-content-box {
            border: none !important;
        }
        .ant-collapse-header {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          color: white;
          width: 100%;
          height: 45px;
          padding: 7px;
        }
        .ant-collapse-arrow {
          margin: 0px 5px;
        }
        .ant-collapse-item-active {
          .ant-collapse-header {
              color: white;
              background-color: rgba(152, 114, 254, .2) !important;
              border-bottom: 2px solid var(--primary) !important;
              font-weight: 400 !important;
          }
        }
    }
    &-divider {
      width: 100% !important;
      margin-top: 10px;
      margin-bottom: 8px;
    }
}