.ViewSwitcher {
  gap: 10px;
  align-items: center;
  color: var(--textLighter);
  border-radius: 34px;
  height: 40px;
  padding: 0 15px;
  transition: all .2s ease;
  cursor: pointer;
  width: max-content;
  border: 1px solid var(--header-btn-border);
  border-radius: 10px;
  transition: all .3s ease;
  background-color: var(--header-btn-BG);

  &__icon {
    color: var(--primary);
    font-size: 16px;
  }

  &:hover {
    border-color: var(--primary);
    transition: all .3s ease;
  }

  &__arrow {
    color: var(--textLighter);
    font-size: 10px;
    opacity: .8;
    transform: translateY(2px);
  }
}
