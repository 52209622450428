.FiltersRowBadges {
    height: 100%;
    width: 100%;
    gap: 7px;
    align-items: center;
    flex-wrap: wrap;
    &__popover{
        .ant-popover-inner{
            border: 1px solid var(--popoverBorderColor);
            border-radius: 5px;
            min-width: 220px;
            .ant-popover-inner-content{
             h5{
              font-size: 14px;
              font-weight: 700;
              display: flex;
              align-items: center;
              margin-bottom: 12px;
             }
             
            }
          }
    }
    &__popover-wrapper{
        padding: 0px 10px;
        &__items{
            overflow: auto;
            max-height: 300px;
            > div{
                margin-bottom: 8px;
                font-size: 12px;
                padding:3px 12px;
    
            }
        }
    }
}