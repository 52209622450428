.CreateInsight {
    max-width: 100%;
    margin: 0 auto;

    &__footer {

        // @media only screen and (max-width: 600px) {
            padding-right: 50px;
        // }
    }
}