.IacInfoCards {
    display: grid;
    // grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    padding: 25px;
    text-align: center;
    >div {
        border-radius: 10px;
        height: 84px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        column-gap: 11px;
        box-shadow: 0px 4px 4px 0px #00000040;
        border: 1px solid #46445D;
        background: linear-gradient(0deg, #181824, #181824),
            linear-gradient(0deg, #46445D, #46445D);
        cursor: pointer;
        &:hover {
            box-shadow: 0px 4px 4px 0px rgba(60, 203, 177, 0.20) inset;
            border: 1px solid #3CCBB1;
        }

        &.active {
            box-shadow: 0px 4px 4px 0px rgba(60, 203, 177, 0.20) inset;
            border: 1px solid #3CCBB1;
            background: rgba(60, 203, 177, 0.05);
            color: #3CCBB1;

            span {
                font-weight: 700;
                color: #3CCBB1;

            }
            div{
                color: #3CCBB1;
                font-weight: 800;
            }

        }

        span {
            font-size: 14px;
            font-weight: 300;
            color: white;
            margin-bottom: 11px;
        }

        div {
            font-size: 24px;
            font-weight: 400;
        }

    }
}

.light{
    .IacInfoCards{
        >div{
            border: 1px solid #E5E5E5;
            box-shadow: 0px 4px 4px 0px #9F9F9F40;
            background: #F6F5F8;
            box-shadow: 0px 4px 4px 0px #9F9F9F40;
            span,div{
                color: #333333;
            }
            &:hover{
                border: 1px solid #3CCBB1;
                background: #F6F5F8;
                border-bottom: 0;
                box-shadow: 0px 4px 4px 0px rgba(159, 159, 159, 0.25) inset;

            }
            &.active{
                border: 1px solid #3CCBB1;
                background: rgba(60, 203, 177, 0.05);
                box-shadow: 0px 4px 4px 0px rgba(60, 203, 177, 0.20) inset;
                span {
                    font-weight: 700;
                    color: #3CCBB1;
    
                }
                div{
                    color: #3CCBB1;
                    font-weight: 800;
                }
            }
        }
    }
}