.SingleFilter {
  border-radius: 4px;
  width: 280px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  padding: 1px;
  overflow: hidden;

  &__inner {
    padding: 1px;
    background-color: var(--card);
    height: 30px;
    font-size: 13px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: var(--appBG);
    }

    &:nth-child(1) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      .active {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    &:nth-child(2) {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      .active {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &-item {
      height: 100%;
      transition: all 0.3s ease;
      .active {
        transition: all 0.3s ease;
      }
    }
  }
}

// light dark colors
.light {
  .SingleFilter {
    &:nth-child(2) {
      background-color: #9873fe;
      .active {
        background: linear-gradient(to right, #c7b3fb, #bec1f8);
      }
    }

    &:nth-child(3) {
      background-color: #73a4f0;
      .active {
        background: linear-gradient(to right, #b2d0f3, #a9dcf0);
      }
    }

    &:nth-child(4) {
      background-color: #44e5df;
      .active {
        background: linear-gradient(to right, #9eecec, #94f9e8);
      }
    }
  }
}

.dark {
  .SingleFilter {
    &:nth-child(2) {
      background-color: #7b9af3;
      .active {
        background: linear-gradient(to right, #5b4895, #525592);
      }
    }

    &:nth-child(3) {
      background-color: #62bdea;
      .active {
        background: linear-gradient(to right, #46668e, #3d708a);
      }
    }

    &:nth-child(4) {
      background-color: #50d5e4;
      .active {
        background: linear-gradient(to right, #328186, #288d83);
      }
    }
  }
}
