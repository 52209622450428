@import "../../../../../styles/colors.scss";

@mixin slide {
    display: flex;
    color: var(--textLighter);
    transition: all 0.3s ease;
    width: 115px;
    height: 54px;
    top: 313px;
    left: 398px;
    gap: 0px;
    // border-radius: 40px;
    opacity: 0px;
    background-color: rgba(33, 33, 50, 1);
    // border: 1px solid rgba(56, 51, 81, 1);
}


.HistoryNavSlideV2 {
  gap: 10px;
  // padding: 0 20px;
  position: relative;

  &__title {
    position: relative;
    left: 5%;
  }

  &__icon {
    position: relative;
    left: 1.5%;
  }

  &__sub-title {
    justify-content: space-around !important;
  }

  &__current {
    @include slide;
  }

  &__content {
    // @include slide;
    // cursor: pointer;
    // z-index: 2;
    // border-color: var(--primary);

    &:hover {
      color: white;
      background-color: var(--assetCodeBG);
      transition: all 0.3s ease;
    }

    &.active {
      background-color: var(--primary);
      color: white;
      font-weight: bold;
      transition: all 0.3s ease;
    }
  }

  &__line {
    height: 57%;
    width: 1px;
    z-index: 1;
    position: absolute;
    bottom: 81px;
    left: 23.5%;
    border-left: 1px solid var(--separatorColor);
  }
}
