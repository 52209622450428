.PagerDutyIntegration {

  .PagerDutyForm {
      width: 50%;
      gap: 30px;
      &__intro {
        gap: 2px;
        &-orderedlist {
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 0px;
          li {
            padding: 0 !important;
          }
          li:not(:last-child) { 
            margin-bottom: 10px;  
          }
        }
      }
  }
}