.TagsDisplay {
    &__badge {
        width: 21px;
        margin-top: 3px !important;
        .ant-badge-count {
            background-color: var(--primary) !important;
            font-size: 10px !important;
            box-shadow: none !important;
        }
    }
    &__icon {
        color: var(--primary);
        width: 22px;
        height: 22px;
        &.disabled {
           color: var(--disableColor);
           cursor: not-allowed;
        }
    }
}