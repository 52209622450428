@import "../../../styles/colors.scss";

.WelcomeStep {
  width: 100%;
  max-width: 750px;
  // gap: 30px;
  margin: 0 auto;

  &__image {
    width: 100%;
    height: calc(100vh - 495px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer !important;

    &-play {
      background-color: $primary;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      border: none;
      font-size: 20px !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      cursor: pointer !important;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: none;
        transition: box-shadow 0.3s ease;
      }

      svg {
        color: white;
      }
    }
  }

  &__quote {
    position: relative;
    padding: 25px 50px;
    margin-top: 25px;
    z-index: 1;
    &-text {
      gap: 7px;
      z-index: 10;
      position: relative; // needed for the z-index
      font-style: italic;

      &-title {
        font-size: 18px;
        font-weight: bold;
      }
      &-sub {
        font-weight: 500;
        color: #bebebe;

        a {
          // font-style: normal;
          font-weight: normal;
          margin-left: 10px;
        }
      }
    }
    &-sign {
      height: 50px;
      width: auto;
      z-index: 5;
      opacity: 0.8;

      &.left-sign {
        position: absolute;
        top: 0;
        left: 0;
      }
      &.right-sign {
        position: absolute;
        top: 30px;
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  &__videoModal {
    .ant-modal-content {
      border-radius: 0px !important;
    }
  }
}
