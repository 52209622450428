$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
.WorkflowsSteps {
    &__general {
        &-authenticate {
            margin-top: 5px;
            .CreateAccessKey__keys-item {
                label {
                    font-size: 12px !important;
                }
            }
        }
        &-workspace {
            .ant-form-item-required { 
                height: unset !important;
                color: var(--text) !important;
            }
        }
        &-ciToolsIntegrate {
            label {
                color: var(--text) !important;
            }
        }
    }
    &-inputsRow {
        align-items: flex-start !important;
    }
    &__tfConfiguration {
        &-vars {
            margin-right: 10px;
        }
        &-vars-title, &-vars-input {
            flex: 1;
            &.no-margin { margin: 0 !important; }
            &.val {
                margin-right: 20px;
            }
        }
    }
    &-questionIcon{
        cursor: pointer;
        color: var(--label) !important;
    }
    &-copyIcon {
        width: 12px;
        cursor: pointer;
        path {
            fill: var(--primary);
        }
    }
    &__collapse {
        width: 100%;
        &-content {
            display: grid;
            padding: 10px 15px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 10px;
        }
    }
    &-addVarsTexts { margin-top: 3px; }
    &-addBtn {
        color: var(--primary);
        border: 1px solid var(--primary);
        border-top-width: 3px;
        border-radius: 2px;
        padding: 1px;
        cursor: pointer;
    }
   &-checkbox {
    align-self: center;
    margin: 0px 10px !important;
   }
    &-deleteBtn {
        color: var(--primary);
        cursor: pointer;
        margin-top: 10px;
        &.profile {
            align-self: flex-start;
            margin-top: 20px;
        }
    }
    &-addText {
        color: var(--primary);
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
    }
    &-orText { font-size: 12px; }
    //antd collapse overrides
    .ant-collapse-item {
        overflow: hidden;
        cursor: pointer;
        background-color: var(--CiCollapseBG);
        border: 1px solid var(--ciCollapseBorder);
        border-radius: 5px;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
            color: var(--text);
            background-color: var(--ciOpenCollapseBG) !important;
            border-bottom: 2px solid var(--primary) !important;
            font-weight: 400 !important;
        }
      }
      .ant-form label {
        color: var(--text) !important;

      }
      &__IacEngineSelection {
        gap: 40px;
        &-title {
            font-size: 27px;
            font-weight: 300;
        }
        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 15px;
            &-gradient {
              &:hover {
                background: $gradient-color;
                padding: 1px;
                box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25);
                border-radius: 10px;
                .WorkflowsSteps__IacEngineSelection__list-item {
                        width: 298px !important;
                        height: 178px;
                        box-shadow: none;
                        background-color: var(--card);
                        border: unset;                          
                }
             }
            }
            &-item {
                color: var(--textLighter) !important;
                box-shadow: none;
                background-color: rgba(152, 114, 254, .1);
                width: 300px !important;
                height: 180px;
                padding: 10px 20px;
                gap: 15px;
                cursor: pointer;            
                &-icon {
                    height: 38px;
                    width: auto;
                }
                &-title {
                    font-size: 16px;
                    font-weight: bold;
                }
                &-soon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    font-size: 12px;
                    font-weight: 500;
                }
                &-disabled { 
                    opacity: .5;
                    cursor: not-allowed;
                    pointer-events: none;
                    border-color: var(--cardBorder) !important;
                    position: relative;
                    color: var(--textLighter) !important;
                    box-shadow: none;
                    background-color: rgba(152, 114, 254, .1);
                    width: 300px !important;
                    height: 180px;
                    padding: 10px 20px;
                    gap: 15px;
                }
            }
        }
      }
      &__IacPipelineSelection {
        gap: 70px;
        &-description {
            text-align: center;
            width: 630px;
        }
        &-title {
            font-size: 27px;
            font-weight: 300;
        }
        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            &-gradient {
              &:hover {
                background: $gradient-color;
                padding: 1px;
                box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25);
                border-radius: 10px;
                .WorkflowsSteps__IacPipelineSelection__list-item {
                         width: 298px !important;
                         height: 178px;
                         box-shadow: none;
                         background-color: var(--card);
                         border: unset;
                }
                .WorkflowsSteps__IacPipelineSelection__list-item-icon, .WorkflowsSteps__IacPipelineSelection__list-item-icon-mask {
                        background: $gradient-color;
                }
             }
            }
            &-item {
                position: relative;
                color: var(--textLighter) !important;
                width: 300px !important;
                height: 180px;
                padding: 10px 20px;
                gap: 15px;
                cursor: pointer;
                box-shadow: none;
                background-color: rgba(152, 114, 254, .1);
                &-icon {
                    position: absolute;
                    transform: rotate(45deg);
                    right: 42%;
                    top: -27px;
                    background: var(--primary);
                    border-radius: 8px;
                    width: 50px;
                    height: 50px;
                    &-container {
                        padding: 4px;
                        background: linear-gradient(135deg,var(--ciWizardContentBodyBG) 50%, var(--card) 50%);
                        border-radius: 8px;
                    }
                    &-mask {
                        width: 40px;
                        height: 40px;
                        background: var(--primary);
                        border-radius: 8px;
                    }
                    &-img {
                        transform: rotate(-45deg);
                        width: 25px;
                        height: 25px;
                    }
                }
                &-title {
                    font-size: 22px;
                    font-weight: 400;
                    text-align: center;

                }
            }
        }
      }
      &__integrate { 
        width: 80%;
        &-keyPair {
            text-decoration: underline;
            cursor: pointer;
        }
        &-orderedlist {
            padding-left: 20px;
            margin-top: 10px;
            margin-bottom: 0px;
            li {
              padding: 0 !important;
            }
            li:not(:last-child) { 
              margin-bottom: 10px;  
            }
            &.general {
                li { margin-bottom: 5px; }
                margin-bottom: 0px !important;
            }
            &.sub {
                li { padding-left: 10px !important; }
            }
          }
          .CreateAccessKey__keys-item {
            margin-top: 5px;
           }
          .ant-tabs-tab {
            padding: 10px 20px !important;
            width: 80px !important;
          }
          .ant-tabs-tab-active {
            color: var(--text) !important;
          }
          .ant-tabs-tab-btn:focus {
            color: var(--text) !important;
          }
          .ant-tabs-ink-bar {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            margin-left: 2px !important;
            background: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%) !important;
          }
    }
    &__review {
        &-filePath {
            padding: 10px 15px;
            border-radius: 5px 5px 0px 0px;
            font-weight: bold;
            background: #312A50;
            color: var(--primary);
            &.collapsed {
                border-radius: 5px;
            }
            &-mark {
              position: relative;
              height: 40px;
              width: 10px;
              &-dot {
                background-color: var(--primary);
                position: absolute;
                z-index: 3;
                color: white;
                border-radius: 50%;
                width: 16px !important;
                height: 16px;
                border: 3px solid var(--primary);
                top: 50%;
                left: -3px;
                right: 0;
                margin: auto;
                transform: translateY(-50%);
              }
              &-line {
                background-color: var(--primary);
                margin: auto;
                height: 100%;
                border-radius: 10px;
                width: 4px;
              }
            }
        }
        &-code {
            &-container {
                overflow: auto;
                border: 1px solid rgba(152, 114, 254, 0.30);
                border-top: none;
                border-radius: 0px 0px 5px 5px;
            }
        }
        &-chevron {
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            width: 15px;
            fill: var(--primary);
            &.up {
                transform: rotate(180deg);
            }
            &.down {
                transform: rotate(0deg);
            }
        }
    }
   
}