@import "../../styles/colors.scss";

.AppSwitch {
  background-color: var(--innerCard);
  border-radius: 8px;
  height: 30px;
  overflow: hidden;
  width: 100%;
  max-width: max-content;
  border: 1px solid var(--separatorColor);
  font-size: 12px;
  &__slice {
    width: 50%;
    padding: 0 15px;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--textLighter);
    background-color: transparent;
    border: 1px solid transparent;
    svg {
      stroke: var(--textLighter);
      transition: all 0.2s ease;
    }
    &.active {
      border-radius: 8px;
      background: rgba($primary, 0.6);
      border-color: $primary;
      color: white;
      height: 100%;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      svg {
        stroke: white;
        transition: all 0.2s ease;
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
