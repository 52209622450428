@import "../../styles/colors.scss";

.Integrations {
  gap: 30px !important;
  &__list {
    gap: 50px;
    &-block {
        gap: 12px;
      &-items {
        gap: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        flex-wrap: wrap;
        &-title {
          &-sum {
            font-weight: 300;
          }
        }
        &-items {
          flex-wrap: wrap;
        }
      }
    }
  }
}
