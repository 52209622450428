.CompanyLogo {
    width: 100%;
    padding: 15px 0;
    gap: 10px;
  
    &__logo {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      height: 30px;
      width: 130px;
    }

    &__organization {
      font-size: 12px;
      color: var(--sidebarLink);
      gap: 5px;
      transition: all .2s ease;
      cursor: pointer;
      &:hover {
        color: var(--primary);
      }
    }
  }