@import "../../../styles/colors.scss";

.SuggestionsModal {
  &__content {
    height: 65vh !important;


    &__saving {
      padding: 0;
      padding-left: 30px;
      .AppWizard__content-body {
      width: calc(85vw - 305px);
      border: none;
      background-color: #181825 !important;
      height: 65vh !important;
      }

      &.stepsUnvisible {
          padding-left: 0;
          .AppWizard__content-body { width: unset; }
      }
    }

    .ant-collapse-header {
      display: flex;
      width: 99%;
      border: 1px solid rgba(135, 128, 166, .5);
      border-radius: 8px;
      margin-top: 10px;

      svg {
        margin-top: 10px;
        margin-left: 5px;
      }
    }
  }

  &__footer {
    align-items: unset !important;
    
    &__link {
      align-self: flex-start;
      gap: 10px;
    }

    &__copy {
      align-self: end !important;
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    background-color: $sidebarBG !important;
    border-radius: 10px 10px 10px 10px;
    padding-left: 5px;
  }

  .ant-collapse-item {
    border-radius: 10px 10px 10px 10px;
    padding-left: 5px;
  }

  .ant-collapse-item-active {
    background-color: $sidebarBG !important;
    border-radius: 10px 10px 0px 0px;
  }

  .ant-collapse-arrow {
    padding-left: 5px;
  }
}

.MinimalCopy__icon {
  color: var(--text) !important;
}


.SuggestionsModalCustom {
    &__content {
      gap: 7px;
    }

    &__ai-remediation {
        padding-bottom: 10px;
        gap: 14px;
    }

    &__text {
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        cursor: pointer;
    }

    &__remediation-text {
        background-color: #041527;
        padding: 15px;
        width: 100%;
        font-family: "Courier New", monospace;
        border-radius: 0px 0px 10px 10px;
        position: relative;
    }

    .ant-collapse-header {
            display: flex;
            align-items: center;
            background-color: $sidebarBG !important;
            border-radius: 10px 10px 10px 10px;
            padding-left: 5px;
            gap: 10px;
            padding: 15px;
      }
    
    .ant-collapse-item {
        border-radius: 10px;
    }

    .ant-collapse-item-active {
        background-color: $sidebarBG !important;
        border-radius: 10px 10px 0px 0px;
    }

    .ant-collapse-content-active{
        border-top: 1px solid #616161 !important;
    }

    .ant-collapse-content-box{
        border-bottom: 0px !important;
    }
  }