@import "../../../../styles/colors.scss";

.DatePicker {
    height: 30px;
    border-radius: 5px;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #3f3366 1.92%, #25575d 100%) padding-box,
        linear-gradient(
                to right,
                #a185f8 0%,
                #8eb0f0 16.59%,
                #7adbe6 33.72%,
                #6ef6e0 43.32%,
                #59fee0 100%
            )
            border-box;

    &__inner {
        height: 28px;
        width: 240px;
        border-radius: 5px;
        display: flex;
        color: var(--text);

        &-btn {
            width: 100%;
            color: var(--text);
            font-size: 14px;
            position: relative;
            border-radius: 2px;

            &.disabled {
                color: var(--disableColor);
            }

            &.active {
                font-weight: 700;
            }

            &.day {
                &.active {
                    color: #3b2b74;
                    background: linear-gradient(90deg, #9872fe 0%, #779ff2 100%);
                }
            }

            &.week {
                &.active {
                    color: #2b4168;
                    background: linear-gradient(90deg, #779ff2 0%, #4cbad5 100%);
                }
            }

            &.month {
                &.active {
                    color: #225f6a;
                    background: linear-gradient(90deg, #4abcd4 0%, #25dfc0 100%);
                }
            }

            &.custom {
                &.active {
                    color: #225f6a;
                    background: linear-gradient(90deg, #4abcd4 0%, #25dfc0 100%);
                }
            }
        }

        &-divider {
            height: 100%;
            width: 1px;

            &.first {
                background-color: #779ff2;
            }

            &.second {
                background-color: #4cbad5;
            }

            &.third {
                background-color: #4cbad5;
            }
        }
    }

    .calendar-container {
        background-color: #363653;
        width: 370px;
        position: absolute;
        border-radius: 10px;
        top: 155px;
        border: 1px solid #ffffff4d;
        z-index: 10000;

        .SmallCalendar {
            .react-calendar__navigation {
                border-bottom: 1px solid var(--separatorColor);
            }

            .react-calendar__navigation button:disabled {
                background-color: transparent;
                opacity: 0.2;
            }

            .react-calendar__navigation__arrow {
                font-size: 20px;
            }

            .react-calendar__navigation button:enabled:hover,
            .react-calendar__navigation button:enabled:focus {
                background-color: $lightPrimary;
            }

            .react-calendar__navigation__label__labelText--from {
                font-weight: bold !important;
            }

            .react-calendar__month-view__days__day--neighboringMonth {
                background-color: transparent;
                opacity: 0.2;
            }

            .react-calendar__month-view__weekdays__weekday {
                color: $primary;

                abbr {
                    text-decoration: none !important;
                }
            }

            .react-calendar__tile {
                border-radius: 0px;

                &:disabled {
                    background-color: transparent;
                    opacity: 0.2;

                    &:hover {
                        background-color: transparent;
                    }
                }

                &:hover {
                    background-color: $lightPrimary;
                }

                &--hover {
                    background-color: $lightPrimary;
                }
            }

            .react-calendar__tile--active,
            .react-calendar__tile--active:enabled:focus {
                background-color: $primary !important;
                color: white !important;
            }

            .react-calendar__tile--now {
                background-color: transparent;
                border: 1px solid $primary;
            }

            .react-calendar__tile--rangeStart {
                border-radius: 5px 0 0 5px;
            }

            .react-calendar__tile--rangeEnd {
                border-radius: 0 5px 5px 0;
            }
        }
    }

    .calendar-actions {
        border-top: 1px solid #ffffff4d;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 0px 0px 10px 10px;
    }
}

.light {
    .ProviderMasterFilter {
        &__inner {
            background: linear-gradient(90deg, #dacefa 0%, #c0f1f0 100%);
        }
    }
    .DatePicker {
        background: linear-gradient(90deg, #dacefa 1.92%, #c0f1f0 100%) padding-box,
            linear-gradient(
                    to right,
                    #a185f8 0%,
                    #8eb0f0 16.59%,
                    #7adbe6 33.72%,
                    #6ef6e0 43.32%,
                    #59fee0 100%
                )
                border-box;

        &__inner {
            &-btn {
                color: #555555;
            }
        }

        .calendar-container {
            background-color: #ffffff;
            border: 1px solid #b6b4c4;

            .SmallCalendar {
                .react-calendar__navigation {
                    border-bottom: 1px solid #b6b5c5;
                }
                .react-calendar__viewContainer {
                    border-bottom: 1px solid #b6b5c5;
                }
            }
        }
    }
}

.dark {
    .ProviderMasterFilter {
        &__inner {
            background: linear-gradient(90deg, #3f3366 0%, #25575d 100%);
        }
    }
}
