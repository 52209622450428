@import "../../styles/colors.scss";

.ViewerOverlay {
  position: absolute;
  top: 70px;
  right: 25px;

  width: 450px;
  height: 160px;

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(65, 65, 65, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 9999999999;
  gap: 30px;
  border-radius: 10px;
  border: 1px solid white;

  img {
    width: 100px;
    height: auto;
  }

  &__text {
      gap: 5px;

      .AppBtn {
          width: max-content;
          margin-top: 10px;
      }
  }
}
