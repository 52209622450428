.NotagsLabelIndicator {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--primary) !important;
  transition: all .2s ease;
  font-size: 15px !important;

  svg {
    transform: scale(120%);
  }
}
