@import "../../../styles/colors.scss";

.IssueModal {
  max-height: 100%;
  &__row {
    gap: 10px;
  }
  &__note {
    color: $primary;
    font-size: 13px;
    font-style: italic;
  }
}