.unauthorized-backup {
    display: inline;
    display: flex;
    height: 850px;

    &__contact-button {
        position: absolute;
        bottom: 15%;
        left: 45%;
    }

    &__close-button {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
        font-weight: 700;
        opacity: 0.3;
        color: #909099;
    }
}

.unauthorized-backup-modal {
    overflow: auto;
    max-height: 95vh;
    padding: 0px;
    background-color: transparent;
}

@media only screen and (min-width: 867px) and (max-width: 1513px) {
    .unauthorized-backup-modal {
        max-height: 90vh;
    }

    .unauthorized-backup {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .unauthorized-backup__image-style {
        transform: scale(1);
        height: 714px;
    }
}
