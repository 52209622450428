@import "../../styles/colors.scss";

.ExclutionPolicyCreation {
  // details step
  .ExclutionConfiguration {
    width: 50%;
    gap: 30px;
    padding: 33px 40px;
  }
  // codein step----
  .PolicyCode {
    min-height: 100%;
    max-width: 100%;

    &__right {
      min-width: 100%;
      max-height: 63vh !important;
      overflow-y: auto;

      &-scope {
        padding: 20px 12px 20px 10px;
        gap: 7px;
        border-bottom: 1px solid var(--separatorColor);
      }

      &-table {
        &-empty {
          background: var(--disableBG);
          padding: 30px 20px;
        }
        
      }

      .ant-collapse-content-box {
        max-width: 100%;
        overflow-x: hidden;

        .InventoryTable {
          padding-bottom: 10px;
        }

        .InventoryTable__header {
          padding: 0 10px;
        }
      }

      .excluded-assets {
        .ant-collapse-content-box {
          padding: 15px;
          background: var(--disableBG);
          min-height: 100px;

          .excluded-assets-list {
            span {
              padding-bottom: 10px;
              margin-bottom: 10px;
              border-bottom: 1px solid var(--separatorColor);
            }
          }

          .explanation {
            margin-top: 20px;
          }
        }
      }
    }

    &__left {
      min-width: 100%;
      min-height: 63vh;
      height: 100%;
      border-right: 1px solid var(--separatorColor);
      position: relative;
      overflow-y: hidden;
    }
  }
}
