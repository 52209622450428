@import "../../../styles/colors.scss";

.ScanResult {

    &__header {
        padding: 11px;
        background-color: var(--innerCardBG);
        color: var(--primary);
        cursor: pointer;
        &:hover {
            color: darken($primary, 3);
        }
    }
    &__summary {
        padding: 20px;
        height: 100%;
        gap: 15px;

        &-vulnerability {
            &.critical {
                color: #3FD3D8;
            }
            &.high {
                color: #498BC8;
                
            }
            &.medium {
                color: #4E64C0;
            }
            &.low {
                color: #5246B9;
            }
            &.unknown {
                color: #562AB2;
            }
        }

        &-code {
            background-color: var(--innerCardCodeBG);
            border-radius: 4px;
        }
    }
}
