@import "../../styles/colors.scss";

.CustomPropertiesTags {
  gap: 10px;

  // &__tag {
  //   text-transform: lowercase;
  // }
  .purple-flag {
    transition: all .2s ease;
    text-transform: lowercase;
    
    &:hover {
      background-color: darken($lightPrimary, 10);
      color: darken($primary, 10);
      transition: all .2s ease;
    }
  }
}
