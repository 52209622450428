.Configuration {
    background-color: var(--assetCodeBG);
    color: white;
    border: 1px solid var(--primary);
    border-radius: 10px;
    height: 100%;
    position: relative;

    &__btns {
        position: absolute;
        bottom: 12px;
        right: 15px;
        gap: 10px;
    }
}