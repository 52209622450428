.SavingsCell {
    &.purple-flag {
        background:  rgba(232, 224, 255, 0.22) !important;
        border: 0.6px solid #AD99F7 !important;
        width: 140px !important;
        justify-content: space-evenly !important;
        cursor: pointer !important;
    }

    .ai-remediation-icon {
        color: #F0EAFF;
    }

    .ai-remediation-text {
        font-weight: 700;
        font-size: 12px;
    }
}

.light {
    .ai-remediation-icon {
        color: var(--textLighter);
    }
}

