.dark {
  @import "~antd/lib/style/themes/default.less";
  @import "~antd/dist/antd.dark.less";

  @primary-color: #9b78fc;
  @layout-body-background: #212133;
  @layout-header-background: #212133;
  @layout-sider-background: #212133;
  @modal-content-bg: #212133;
  @component-background: #212133;
  @popover-background: #212133;
  @border-color-base: #616161; // effecting on checkboxes border
  @border-radius-base: 4px;

  // drawer
  @drawer-bg: #212133;

  // Table
  @table-bg: transparent !important;
  @table-header-bg: #2c2c41 !important;
  @table-border-color: rgba(81, 81, 81, 0.35);
  @table-header-color: #d5d5d9;
  @table-header-sort-active-bg: #363650 !important;
  @table-header-sort-bg: #2c2c41;
  @table-row-hover-bg: rgba(145, 106, 252, 0.15) !important;

  // Form
  @label-required-color: transparent;
  @label-color: rgba(255, 255, 255, 0.6);

  // Select
  // ---
  @select-border-color: rgba(225, 231, 234, 0.2);

  // Input
  @input-border-color: rgba(225, 231, 234, 0.2);
  @input-color: rgba(255, 255, 255, 0.8);

  // Tooltip
  @tooltip-color: #fff;
  @tooltip-bg: #30304a;
  
  // Tabs
  @tabs-hover-color: #9b78fc;
}
