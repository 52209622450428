@import '../../styles/colors.scss';

.ConfirmationModal {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__description {
        color: var(--textSuperLight);
        white-space: pre-line;
    }

    &__btns {
        gap: 15px;
        justify-content: flex-end;
    }

    &__cli {
        margin-top: 15px;
    }
}