.ProvisionMessages {
    background-color: var(--appBG);
    height: 100%;
    overflow: auto;
    border-radius: 4px 4px 0px 0px;
    scroll-behavior: smooth;
    &-item {
      padding: 15px;
      background-color: var(--appBG);
  
      &-userIcon {
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 34px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      &-icon {
        height: 25px;
        width: 25px;
        min-width: 25px;
        &.loading {
          animation: sparkle 2s ease-in-out infinite;
       
        }
      }
      &.user {
        background-color: var(--card);
      }
      .TypingDots {
        span {
         background: var(--text) !important;
        }
      }
    }
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
@keyframes sparkle {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}