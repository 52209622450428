.SidebarToggle {
  background-color: var(--card) !important;
  width: 34px;
  height: 34px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sidebarLink) !important;
  font-size: 14px !important;
  transition: color 0.2s ease;

  &:hover {
    transition: color 0.2s ease;
    color: var(--primary) !important;
  }
}
