.DiagramChart {
  .react-flow {
    &__attribution {
      display: none;
    }
    &__controls {
      box-shadow: none;
      border-radius: 4px;
      padding: 4px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
      background-color: var(--card);
      border: 1px solid var(--cardBorder);
      top: 20px;
      bottom: auto;
      &-button {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-bottom: none;
        transition: all 0.2s ease;
        svg {
          fill: var(--textSuperLight);
          transition: all 0.2s ease;
        }
        &:hover {
          svg {
            transition: all 0.2s ease;
            fill: var(--text);
          }
        }
      }
    }
  }
}
