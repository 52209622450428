.GuardrailBlock {
  position: relative;
  padding-bottom: 20px;
  .error{
    margin-bottom: 10px;
    display: block;
    color: #E36F7E;
  }
  .ant-radio-inner::after { left: 3px !important; top: 3px !important;}
  .ant-input-number-handler-wrap {  opacity: 1 !important;}
  .ant-radio-wrapper { margin-right: 0px !important ;}
  .ant-radio-inner { width: 14px; height: 14px;}
  .RemediationAllBtn {
    margin-top: -3px;
  }
  &-header {
    position: relative;
  }
  &-status {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.7;
    }
    &.selected {
      font-weight: 600;
    }
  }
  &-divider {
    width: 100%;
    height: 2px;
    background: var(--separatorColor);
    &.horizontal {
        width: 1px;
        height: 85%;
        position: static;
    }
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 14px !important;
  }
  &-icon {
    width: 16px !important;
    height: 16px;
    &-status {
        width: 18px !important;
        height: 18px !important;
        fill: currentColor !important;

        &.failed {
            color:var(--guardrailViolationBorder);
        }
        &.softFailed {
            color:var(--guardrailsViolationsSoftFailedBorder);
        }
        &.passed {
            color:var(--guardrailsViolationsPassedIcon);
        }
    }
    path {
      fill: var(--text) !important;
    }
  }
}
