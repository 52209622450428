@import "../../styles/colors.scss";

  .AppTag {
    height: 25px;
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 0 15px;
    gap: 10px;
    transition: all 0.3s ease;
    min-width: max-content;
    background-color: $lightPrimary;
    color: $primary;

    &.primaryMode {
      background-color: $primary;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: darken($primary, 6);
        border-color: darken($primary, 6);
      }
    }

    &.minimal {
      font-size: 12px;
      line-height: 12px;
      height: 22px;
      border-color: transparent;
      &:hover {
        background-color: $lightPrimary;
        border-color: transparent;
      }
    }

    &__icon {
        cursor: pointer;
        width: max-content;
        svg {
          font-size: 12px;
        }
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: darken($lightPrimary, 20);
    }

    &.disabled {
      background-color: var(--disableBG) !important;
      color: var(--disableColor) !important;
      border: 1px solid transparent !important;
      cursor: not-allowed;
    }
  }
