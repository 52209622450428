.OrganizationModal {
  padding: 0;
  &__item {
    padding: 20px 10px 20px 30px;
    gap: 10px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid var(--separatorColor);
    }
    &:hover {
      background-color: var(--lightPrimary);
    }

    &.active {
      color: var(--primary);
    }

    &-logo {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      height: 25px;
      width: 100px;
    }
  }
}
