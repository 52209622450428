@import "../../../styles/colors.scss";

.SingleIntegrationItem {
  width: 100%;
  background-color: var(--card) !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--cardBorder);
  display: flex;
  flex-direction: column;
  .clickable{
    cursor: pointer;
  }
  &__labels {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    padding-bottom: 0;
    svg {
      height: 15px;
    }
    img {
      height: 16px;
    }
  }

  &__content {
    padding: 15px 20px 20px 20px;
    gap: 12px;

    &-name {
      color: var(--text);
      font-size: 13px;
      gap: 10px;
    }
    &-account {
      font-size: 12px !important;
      color: var(--textSuperLight);
    }
  }

  &__actions {
    margin-top: auto;
    display: flex;
    border-top: 1px solid var(--separatorColor);
    align-items: center;
    justify-content: space-between;
    padding: 8px 9px 8px 17px;
    height: 40px;

    &-flags {
      gap: 15px;
      width: 100%;
      &-prod {
        height: 16px;
        color: $primary;
      }
      &-event {
        height: 16px;
      }
      &-primary {
        height: 16px;
      }
    }
    &-line {
      height: 20px;
      background-color: var(--cardBorder);
      width: 1px;
      display: inline-block;
    }
    &-btns {
      width: 100%;
      justify-content: flex-end;
      gap: 4px;
      color: rgba($primary, 0.8);
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 6px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:hover {
          transition: all 0.2s ease;
          background-color: $lightPrimary;
          color: rgba($primary, 1);
        }

        &:disabled,
        [disabled="disabled"] {
          cursor: not-allowed;
          pointer-events: none;
          color: var(--disableColor);
        }
      }
    }
  }
  &-sendNotification {
    height: 23px;
    width: 23px;
    cursor: pointer;
    color: $primary;
    transition: all 0.2s ease;
  }
  &-slackChannels {
    height: 275px;
    &-select {
      width: 100%;
    }
    &-desc {
      background-color: var(--card);
      &-text {
        font-size: 13px;
        color: var(--text);
      }

    }
    &-icon {
      align-self: flex-start;
      height: 27px;
      width: 27px !important;
      color: $primary;
      transition: all 0.2s ease;
    }
  }
}
