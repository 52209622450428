@import "../../styles/colors.scss";

.OnBoarding {
  width: 100%;
  height: 100%;

  &__wizard {
    height: 100%;
    max-width: 1100px;

    &-body {
      height: calc(100vh - 190px);
      overflow-y: auto;
      padding: 40px;
      gap: 40px;

      &-header {
        gap: 3px;
        text-align: center;
        &-title {
          font-size: 24px;
          margin-bottom: 5px;
        }
        &-sub {
          color: var(--textLighter);
        }

        img {
          margin-bottom: 20px;
        }
      }
    }

    &-footer {
      height: 90px;
      border-top: 1px solid var(--separatorColor);
      padding: 0 30px;
    }
  }

  &__video {
    margin: 0 auto;
  }
}
