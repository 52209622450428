@import "../../styles/colors.scss";

.LanguageSwitcher {
  height: 40px;
  width: 100%;
  background-color: var(--card);
  // padding-left: 40px;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
  border-bottom: 1px solid var(--separatorColor);

  &-item {
    width: 150px;
    cursor: pointer;
    transition: color 0.2s ease;
    transition: border-color 0.2s ease;
    color: var(--text);
    gap: 7px;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-size: 12px;
    padding-top: 5px;

    &:hover {
      transition: background-color 0.2s ease;
      svg {
        fill: $primary;
      }
    }

    &.active {
      color: $primary;
      font-weight: bold;
      transition: border-color 0.4s ease;
      border-color: $primary;

      svg {
        opacity: 1;
        fill: $primary;
      }
    }
    &.disabled {
      cursor: not-allowed;
      color: $grey;
    }
  }
}
