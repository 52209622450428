@import "../../styles/colors.scss";

.CreateAccount {
  width: 100vw;
  height: 100vh;
  background: #160755
    url("https://infralight-templates-public.s3.amazonaws.com/auth0/galaxy_16_9.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &__wrapper {
    width: 600px;
    // min-height: 60vh;
    border-radius: 15px;
    border: 4px solid #8559fe;
    background-color: #25136e;
    position: relative;
    padding: 60px 40px;
    gap: 10px;
    color: white;

    &-header {
      gap: 10px;
      align-items: center;

      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        text-align: center;

        &-main {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        &-sub {
          opacity: .7;
        }
      }
    }

    &-form {
      gap: 20px;

      input {
        border: 1px solid rgba(0, 0, 0, 0.15);
        height: 43px;
        border-radius: 49px;
        box-shadow: none;
        background-color: #3d2197;
        color: white !important;
        padding: 12px 18px !important;
        transition: all 0.2s ease;

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }

        &:hover {
          border-color: #8559febb;
          background-color: #5130bb;
          transition: all 0.2s ease;
        }

        &:focus {
          border-color: #8559febb;
          box-shadow: none;
          background-color: #5130bb;
        }
      }

      .form-row {
        gap: 10px;
        width: 100%;
        margin-top: 10px;

        .btn {
          background-color: transparent;
          border-radius: 49px;
          width: 100%;
          height: 43px;
          transition: all 0.4s ease;
          color: white;
          cursor: pointer;

          &.submit {
            background: linear-gradient(
              109.64deg,
              #8559fe 0.01%,
              #59fee0 106.5%
            );
            transition: all 0.4s ease;
            border: 1px solid #25136e;

            &:hover {
              border-color: #8559febb;
              transition: all 0.4s ease;
            }
          }

          &.logout {
            background-color: rgba(255, 255, 255, 0.1);
            border: 1px solid transparent;
            &:hover {
              border-color: #8559febb;
            }
          }
        }
      }
    }
  }
}
