.SingleComment {
  &__new {
    width: 100%;
    display: grid;
    grid-template-columns: 50px calc(100% - 50px);
  }

  &__delete {
      gap: 15px;

      &-title {
          font-weight: 500;
      }
      &-actions {
        gap: 5px
      }
  }
}
