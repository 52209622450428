.PullRequestForm {
  &__content {
    width: 100%;
    height: calc(82vh - 2px);
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      padding-left: 40px;
      height: 10vh;
    }

    &-body {
      padding: 34px 40px 40px 40px;

      &-url {
        margin-top: 1.5rem;
      }

      &-vcs {
        gap: 10px;
        img {
          height: 18px;
          width: auto;
        }
      }

      .fileName-form-block {
        .ant-form-item-label {
          label {
            width: 100%;
            flex-direction: row-reverse;
            .browse-repo-link {
              cursor: pointer;
              transition: 0.2s ease;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &-footer {
      padding: 35px 40px 0 40px;
      display: flex;
      gap: 15px;
      justify-content: flex-end;
    }

    &-close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      opacity: 0.3;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    &-success {
      padding-top: 40px;
      img {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
      }

      &-subtitle {
        margin-top: 8px;
      }
    }
    .ant-select-clear {
      right: 30px !important;
    }
  }
}
