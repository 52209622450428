@import "../../../styles/colors.scss";

.OtherStateTypes {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
    &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
        &:hover {
            opacity: 0.8;
        }
        &-count {
            font-weight: 300;
            font-size: 14px;
            text-align: center;
        }
        &-title {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        &-icon {
            padding: 5px;
            width: 24px !important;
            height: 24px !important;
            border-radius: 50%;
        }
        &.pending {
            color: $strawberry;
            .OtherStateTypes-item-icon {
                background-color: $light_strawberry;
            }
        }
        &.undetermined {
            color: var(--undeterminedFlagBorder);
            .OtherStateTypes-item-icon {
                padding: 6px;
                background-color: var(--undeterminedFlagBG);
            }
        }
        &.iacIgnored {
            color: $blueIacIgnored;
            .OtherStateTypes-item-icon {
                background-color: $light_blue;
            }
        }
        &.child {
            color: $turquoise;
            .OtherStateTypes-item-icon {
                background-color: $light_turquoise;
            }
        }
    }
    &__icon-number-style {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
    }

    &-divider {
        background-color: var(--otherTypesDividerColor);
        &.ver {
            width: 1px;
            height: 95%;
        }
    }
}
