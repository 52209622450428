.SlackIntegration {
    
    .CreationStep {
        gap: 30px;
        width: 50%;

        &__info {
            gap: 10px;
            &-orderedlist {
                padding-left: 10px;
                margin-top: 10px;
                margin-bottom: 0px;
                li {
                  padding: 0 !important;
                }
                li:not(:last-child) { 
                  margin-bottom: 10px;  
                }
              }
        }

        &__checbox {
            height: 32px;
            color: rgba(4, 21, 39, 0.5);
            font-size: 14px;
        }

        .webhook-link {
            text-decoration: underline !important;
        }
    }
}