@import "../../styles/colors.scss";

// light dark support
.light {
  .CardBox {
    background-color: var(--card) !important;
  }
}

.dark {
  .CardBox {
    background-color: $codeCard !important;
  }
}


.CardBox {
  width: 240px;
  height: 150px;
  position: relative;
  color: var(--textLighter) !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 15px;
  transition: border-color 0.2s ease, background-color 0.2s ease,;

  img {
    height: 30px;
    width: auto;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  &__comingSoonText {
    opacity: 0.5;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 9px;
    font-style: normal;
    letter-spacing: 0.2px;
  }
  &__recommended {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 9px !important;
    font-style: normal;
    letter-spacing: 0.2px;
  }

  &:hover {
    box-shadow: none;
    border: 1px solid rgba($primary, 0.6);
  }

  &.comingSoon {
    background-color: transparent !important;
    cursor: not-allowed;
    border: 1px solid var(--separatorColor);
    box-shadow: none;

    .CardBox__title {
      opacity: 0.5;
    }

    img {
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid var(--separatorColor);
    }
  }

  &.active {
    border: 1px solid rgba($primary, 0.6);
    background-color: $lightPrimary !important;
    box-shadow: none;
    .CardBox__title {
      color: $primary;
    }
  }

  &.activeOnHover {
    &:hover {
      border: 1px solid rgba($primary, 0.6);
      background-color: $lightPrimary !important;
      box-shadow: none;
    }
  }
}