@import "../../../styles/colors.scss";
.StepsSidebar {
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  background-color: var(--card);
  padding: 0 20px;
  z-index: 10;

  &__user {
    gap: 10px;
  }
}