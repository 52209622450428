$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.AiRemediationSteps {

    &__AiRemediationeSelection {
        gap: 40px;

        &__loading{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-description {
            text-align: center;
            width: 630px;
        }

        &-title {
            margin-top: 30px;
            font-size: 27px;
            font-weight: 300;
        }

        &__list {
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;

            &-gradient {
                &:hover:not(.disabled)  {
                    background: $gradient-color;
                    padding: 1px;
                    box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25);
                    border-radius: 10px;

                    .AiRemediationSteps__AiRemediationeSelection__list-item {
                        width: 298px !important;
                        height: 178px;
                        box-shadow: none;
                        background-color: var(--card);
                        border: unset;
                    }

                    .AiRemediationSteps__AiRemediationeSelection__list-item-icon,
                    .AiRemediationSteps__AiRemediationeSelection__list-item-icon-mask {
                        background: $gradient-color;
                    }

                    .AiRemediationSteps__AiRemediationeSelection__list-item-icon-img {
                        color: black;
                    }
                }
            }

            &-item {
                position: relative;
                color: var(--textLighter) !important;
                width: 300px !important;
                height: 180px;
                padding: 10px 20px;
                gap: 15px;
                cursor: pointer;
                box-shadow: none;
                background-color: rgba(152, 114, 254, .1);

                &.disabled {
                    border-color: rgba(97, 97, 97, 1) !important;
                }


                &-icon {
                    position: absolute;
                    transform: rotate(45deg);
                    right: 42%;
                    top: -27px;
                    background: var(--primary);
                    border-radius: 8px;
                    width: 50px;
                    height: 50px;

                    &.disabled {
                        background: #676773;
                        box-shadow: 0px 5.33336px 9.77782px rgba(188, 188, 188, 0.25);
                    }

                    &-container {
                        padding: 4px;
                        background: linear-gradient(135deg, var(--ciWizardContentBodyBG) 50%, var(--card) 50%);
                        border-radius: 8px;

                        &.disabled {
                            background: #676773;
                        }
                    }

                    &-mask {
                        width: 40px;
                        height: 40px;
                        background: var(--primary);
                        border-radius: 8px;

                        &.disabled {
                            background: #676773;
                        }

                    }

                    &-img {
                        transform: rotate(-45deg);
                        width: 25px;
                        height: 25px;
                        color: white;

                    }
                }

                &-title {
                    font-size: 22px;
                    font-weight: 400;
                    text-align: center;

                    &.disabled {
                        color: #51515E;
                        margin-top: 20px !important;
                    }
                }
            }
        }

        &__recommended {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 9px !important;
            font-style: normal;
            letter-spacing: 0.2px;

            &.disabled {
                background: #51515E !important;
            }
        }
    }
}

.light {
    .AiRemediationSteps__AiRemediationeSelection__list-gradient {
        &:hover:not(.disabled)  {
            background: $gradient-color;
            padding: 1px;
            box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25);
            border-radius: 10px;

            .AiRemediationSteps__AiRemediationeSelection__list-item {
                width: 298px !important;
                height: 178px;
                box-shadow: none;
                background-color: rgba(246, 247, 248, 1);
                border: unset;
            }

            .AiRemediationSteps__AiRemediationeSelection__list-item-icon,
            .AiRemediationSteps__AiRemediationeSelection__list-item-icon-mask {
                background: $gradient-color;
            }

            .AiRemediationSteps__AiRemediationeSelection__list-item-icon-img {
                color: black;
            }
        }

    }
    
        .AiRemediationSteps__AiRemediationeSelection__list-item {
            background-color: rgba(246, 247, 248, 1);
            border: 1px solid rgba(152, 114, 254, 0.5)
    }

}