body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #212133;
}

code {
    font-family: monospace, "Courier New", Consolas, Monaco, source-code-pro, Menlo;
}

.dark {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #181825;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: #212132;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-corner {
        background: #212132;
    }
}

.light {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #bcbccacc;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: #d7d7e091;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-corner {
        background: #d7d7e091;
    }
}
