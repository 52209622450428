@mixin count_flag {
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 4px 8px;
  width: max-content;
  height: max-content;
  gap: 5px;
}

.light {
  .AssetCountFlag {

    &.asset {
      @include count_flag;
      background-color: #e9e4fd;
      color: #9476fb;
    }
    &.cost {
      @include count_flag;
      background-color: #C8F2E3;
      color: #46ac7b;
    }
  }
}
.dark {
  .AssetCountFlag {
    &.asset {
      @include count_flag;
      background-color: #5e48ab;
      color: #ddd4fe;
    }
    &.cost {
      @include count_flag;
      background-color: #3D6061;
      color: #61CD9A;
    }
  }
}
