@import "../../styles/colors.scss";

.CheckedMultiSelect {
    &__dropdown {
        padding: 0 !important;

        .ant-select-tree-treenode {
            padding: 0px 13px !important;
            align-items: center !important;
            cursor: pointer;

            &:hover {
                background-color: #323242;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #383847;
            }

            .ant-select-tree-node-content-wrapper{
                padding: 10px 8px;
            }
            .ant-select-tree-checkbox{
                margin-top: 0;
            }
            &.ant-select-tree-treenode-checkbox-checked {
                font-weight: 700;
                background-color: #373259;
            }


            .ant-select-tree-checkbox {
                margin-right: 4px !important;
            }

            .ant-select-tree-indent,
            .ant-select-tree-switcher {
                display: none !important;
            }



        }
    }

    &__dropdown-item {
        display: flex;
        align-items: center;
        img,
        svg {
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-right: 12px;

            &.Drift {
                color: $purple;
            }

            &.Codified {
                color: $turquoise;
            }

            &.UnmanagedResource {
                color: $strawberry;
            }

            &.GhostResource {
                color: $salmon;
            }
            &.InsightDetected {
                fill: var(--policyIconColor);
            }

        }

        &.AppTag {
            display: flex;
            align-items: center;
            width: fit-content;
        }
    }


}

.light{
    .CheckedMultiSelect{
        &__dropdown {
            padding: 0 !important;
    
            .ant-select-tree-treenode {
    
                &:hover {
                    background-color: #F5F5F5;
                }
    
                &:not(:last-child) {
                    border-bottom: 1px solid #DCDCDC;
                }
    
               
                &.ant-select-tree-treenode-checkbox-checked {
                    font-weight: 700;
                    background-color: #E9E3FD;
                }
    
    
            }
        }
    }
    
}