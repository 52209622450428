$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

.ChooseExperience {
    padding-top: 10%;
    &-description {
        align-self: center;
        text-align: center;
        width: 500px;
    }
    &-title {
        font-size: 27px;
        font-weight: 300;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        &-gradient {
          &:hover {
            background: $gradient-color;
            padding: 1px;
            box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25);
            border-radius: 10px;
            .ChooseExperience__list-item {
                     width: 298px !important;
                     height: 150px;
                     box-shadow: none;
                     background-color: var(--card);
                     border: unset;
            }
            .ChooseExperience__list-item-icon, .ChooseExperience__list-item-icon-mask {
                    background: $gradient-color;
            }
         }
        }
        &-item {
            position: relative;
            color: var(--textLighter) !important;
            width: 300px !important;
            height: 150px;
            padding: 10px 20px;
            gap: 15px;
            cursor: pointer;
            box-shadow: none;
            border: 1px solid #9872fe50 !important;
            background-color: rgba(152, 114, 254, .1);
            &-icon {
                position: absolute;
                transform: rotate(45deg);
                right: 42%;
                top: -27px;
                background: var(--primary);
                border-radius: 8px;
                width: 50px;
                height: 50px;
                &-container {
                    padding: 4px;
                    background: linear-gradient(135deg,var(--ciWizardContentBodyBG) 50%, var(--card) 50%);
                    border-radius: 8px;
                }
                &-mask {
                    width: 40px;
                    height: 40px;
                    background: var(--primary);
                    border-radius: 8px;
                }
                &-img {
                    transform: rotate(-45deg);
                    width: 25px;
                    height: 25px;
                }
            }
            &-title {
                font-size: 22px;
                font-weight: 400;
                text-align: center;

            }
        }
    }
}