.SingleIntegrationHeader {
    img {
        height: 38px;
        width: auto;
      }
  
      .okta-icon {
        height: 28px;
      }
  
      .newrelic-icon {
        height: 38px;
      }
  
      .aws-icon {
        height: 32px;
        transform: translateY(2px);
      }
  
      .cloudFlare-icon {
        height: 32px;
      }
}