.NotificationStep {
    width: 100%;
    align-items: center;
    gap: 10px;

    &__list {
        width: 100%;
        max-width: 750px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}