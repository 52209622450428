@import '../../../styles/colors.scss';

.AwsIntegration {
  &__steps {
    gap: 20px;

    .CreateRole {
      gap: 15px;

      .purple-flag {
        font-size: 12px !important;
      }

      &__load {
        gap: 20px;
        margin-bottom: 20px;
      }

      img {
        width: 50%;
        display: flex;
        margin-top: 15px;
      }

      &-list {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        li:not(:last-child) { 
          margin-bottom: 10px;  
        }
      }

      .launchStackBtn {
        width: 140px;
        height: auto;
        cursor: pointer;
        transition: transform .2s ease;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: translateZ(0);
    

        &:hover {
          transform: scale(1.02);
        }
      }
    }

    .RoleValidation {
      gap: 20px;
      width: 50%;

      &-integrationCheck {
        svg {
          font-size: 40px;
          color: $green;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      .event-driven-block {
        margin-left: 7px;
        padding-left: 20px;
        padding-top: 10px;
        border-left: 2px solid var(--separatorColor);
      }
    }

    .Integration {
      gap: 20px;

      &-integrationCheck {
        gap: 10px;
        margin-bottom: 40px;

        &-title {
          font-size: 18px;
        }
        svg {
          font-size: 25px;
          color: $green;
        }
      }
      &-failure {
        gap: 10px;
        &-list {
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 0px;
          li:not(:last-child) { 
            margin-bottom: 10px;  
          }
        }
      }
    }
  }
}
