.ClickOps {
    width: 100%;
    display: flex;
    background-color: rgba(44, 45, 64, 1);
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    font-size: 12px;
    color: #ffffff;

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
        row-gap: 10px;

        &__text {
            font-weight: 600;
        }

        &__item {
            display: flex;
            flex-direction: column;
        }
    }
}

.ClickOps__code {
    width: 94%;
    transform: translate(6px, -15px);
    font-size: 12px !important;
    pre {
        background-color: rgba(19, 19, 28, 1) !important;
    }
}
