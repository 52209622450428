@import "../../styles/colors.scss";

.IntegrationsCatalog {
  gap: 30px;
  margin-bottom: 20px;

  &__block {
    background-color: var(--card);
    gap: 20px;
    border-radius: 10px;
    padding: 32px;
    scroll-behavior: smooth;

    &-header {
      gap: 10px;
      flex-wrap: wrap;
      &-title {
        font-size: 16px;
      }
      &-sub {
        color: var(--textSuperLight);
      }
    }
    &-items {
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      flex-wrap: wrap;
    }
  }

  // single item style
  &__item {
    background-color: var(--innerIntegrationCard) !important;
    color: var(--textLighter) !important;
    // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 80px;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 20px;
    border: 2px solid transparent;
    transition: all 0.2s ease !important;

    img {
      height: 31px;
      width: auto;
    }

    .okta-icon {
      height: 22px;
    }

    .newrelic-icon {
      height: 30px;
    }

    .aws-icon {
      height: 29px;
    }

    .cloudFlare-icon {
      height: 27px;
    }

    &-text {
      color: var(--text);
      gap: 20px;
    }

    &.comingSoon {
      background-color: transparent !important;
      border: 1px solid var(--separatorColor);
      cursor: not-allowed;
      box-shadow: none;

      .IntegrationsCatalog__item-text {
        opacity: 0.55;
      }

      img {
        opacity: 0.55 !important;
      }

      &:hover {
        border: 1px solid var(--separatorColor);
      }
    }

    &-soon {
      color: var(--disableColor);
      font-size: 10px;
      font-style: normal;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2px;
    }

    &:hover {
      box-shadow: none;
      border-color: rgba($primary, 0.6);
    }

    &-plus {
      min-width: 24px;
      height: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      background-color: var(--primary);
      border-radius: 50%;
    }
  }
}

// adjust plus btn color by theme
.light {
  .IntegrationsCatalog__item-plus {
    color: white;
  }
}
.dark {
  .IntegrationsCatalog__item-plus {
    color: #121212;
  }
}
