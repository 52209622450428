@import '../../styles/colors.scss';

.VcsLabelIndicator {
    background-color: transparent;
    border: none;
    padding: 0;
    svg {
        max-width: 15px;
    }
}
