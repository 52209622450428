.OrganizationSelect {
    background-color: var(--header-btn-BG);
    border: 1px solid var(--header-btn-border);
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.3s ease;
    height: 40px;
    min-width: 40px;
    padding: 0 10px;
    color: var(--textLighter) !important;
    gap: 10px;
  
    &:hover {
      transition: all 0.3s ease;
      border-color: var(--primary);
    }
}