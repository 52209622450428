$gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);
$gradient-color-content: linear-gradient(90deg, rgba(152, 114, 254, 0.10) 0.71%, rgba(51, 253, 216, 0.10) 100%);

@mixin policy-text-color {
    &.HIGH { color: var(--high);}
    &.MEDIUM { color: var(--medium);}
    &.LOW, &.warning { color: var(--low);}
}
@mixin policy-header {
    .ant-collapse-arrow { top: 35% !important; }
    .ant-collapse-header { &:hover { background-color: unset !important;} }
    &.HIGH {
        background-color: rgba(73, 139, 200, 0.1) !important;
    border-color: var(--high);
        .ant-collapse-arrow { color: var(--high) !important; }
        &:hover { background-color: rgba(73, 139, 200, 0.2) !important; }
    }
    &.MEDIUM { 
        background-color: rgba(78, 100, 192, 0.1) !important;
        border-color: var(--medium); 
        .ant-collapse-arrow { color: var(--medium) !important; }
        &:hover { background-color: rgba(78, 100, 192, 0.2) !important; }
    }
    &.LOW, &.WARNING {
        background-color: rgba(82, 70, 185, 0.1) !important;
        border-color: var(--low);
        .ant-collapse-arrow { color: var(--low) !important; }
        &:hover { background-color: rgba(82, 70, 185, 0.2) !important; }
    }
}
@mixin policy-header-bottom-color {
    .ant-collapse-header { 
        color: var(--text);
    }
    &.HIGH {
    .ant-collapse-header {
        background-color: rgba(73, 139, 200, 0.2) !important;
        border-bottom-color: var(--high) !important; 
    }}
    &.MEDIUM { .ant-collapse-header {
        background-color: rgba(78, 100, 192, 0.2) !important;
        border-bottom-color: var(--medium) !important;
    }}
    &.LOW, &.WARNING { .ant-collapse-header {
        background-color: rgba(82, 70, 185, 0.2) !important;
        border-bottom-color: var(--low) !important;}}
}
.GeneralSummaryConfig {
    .ant-drawer-close { top: 10px !important; };
    .ant-drawer-header {
        border-bottom: 0px !important;
        border-radius: 0px  !important;
        background: $gradient-color !important;
        padding: 0px 0px 2px 0px !important;
    }
    &__mask {
        background: var(--card);
    }
    &__header {
        background: $gradient-color-content;
        padding: 24px;
        &-title {
            font-size: 18px;
            font-weight: 700;
            color: var(--text);
        }
        &-subtitle {
            color: var(--label);
            font-size: 15px;
            margin-top: 3px;
        }
        &-dollarImg {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
            width: 65px;
            height: 82px;
          }
        &-taggingImg {
            position: absolute;
            top: 0;
            right: 15px;
            z-index: -1;
            width: 35px;
            height: 70px;
          }
        &-securityImg {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }
    }
    &__body {
        height: 100%;
        &-policy {
            position: relative;
            padding: 20px 26px;
            border-bottom: 3px solid var(--separatorColor);
            &-title {
                font-size: 16px;
                font-weight: 500;
                color: var(--text);
            }
            &-content {
                &-panel {
                    border: 1px solid transparent;
                    @include policy-header;
                }
                &-header {
                    &-item {
                        padding: 20px 15px;
                        border-bottom: 1px solid white;
                        &.last { border-bottom: none; }
                        &.HIGH {
                            background-color: rgba(73, 139, 200, 0.1) !important; 
                            border-color: var(--high);
                        }
                        &.MEDIUM { 
                            background-color: rgba(78, 100, 192, 0.1) !important;
                            border-color: var(--medium);
                        }
                        &.LOW, &.WARNING { 
                            background-color: rgba(82, 70, 185, 0.1) !important;
                            border-color: var(--low);
                        }
                    }
                    &-title { font-size: 16px; font-weight: 500; }
                    &-count { 
                      font-size: 18px;
                      font-weight: 600;
                      margin-right: 24px;
                      @include policy-text-color;
                    }
                }
                .ant-collapse-item {
                    overflow: hidden;
                    cursor: pointer;
                    background-color: var(--CiCollapseBG);
                    border-radius: 5px;
                  }
                  .ant-collapse-content {
                    background-color: var(--card) !important;
                  }
                  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header { padding: 5px 16px !important }
                  .ant-collapse-item-active {
                    @include policy-header;
                    .ant-collapse-header { background-color: unset !important; }
                    @include policy-header-bottom-color;
                  }
                 
            }
        }
        &-cost {
            padding: 20px 26px;
            height: 100%;
            &-title {
                &-item {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 2px;
                    &.nowTitle { font-weight: 600;}
                    &-nowValue {
                        font-size: 24px;
                        margin-bottom: 4px;
                        font-weight: 700;
                    }
                }
            }
            .CostAnalysis { margin: 0px };
        }
        &-tags {
            position: relative;
            padding: 20px 26px;
            border-bottom: 3px solid var(--separatorColor);
        }
    }
}