.Inventory {
  height: calc(100vh - 170px);
  width: 100%;
  display: grid !important;
  grid-template-columns: 18%  calc(82% - 20px);
  gap: 20px;

  &__right {
    gap: 20px;
  }
  &__thinkerbell {
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 3px;
    animation: moveAndRotate 1s ease;
    transition: all 0.4s ease;
    path {
      fill: var(--text);
    }
    &:hover {
      transform: scale(1.05) rotate(5deg);
      transition: all 0.1s ease;
    }
  }
  .GradientBorderButton {
    padding: 1px;
    border-radius: 10px;
    &__content {
      border-radius: 10px;
      &:hover {
        path {
          fill: white;
        }
      }
    }
  }
}
