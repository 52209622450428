.DiagramTraceBlock {
  background-color: var(--card);
  padding: 10px 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid var(--cardBorder);
  transition: border .2s ease;
  max-width: 240px;

  &__title {
    word-break: normal;
    color: #5d4ee5;
  }

  img {
    height: 30px;
    width: auto;
  }

  &:hover {
    transition: border .2s ease;
    border-color: var(--primary);
  }
}
