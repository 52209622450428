.CodeCommitIntegration {
  .small-dark-blue {
    line-height: 20px;
  }
}
.CodeCommitConfiguration {
  gap: 15px;
  &__intro {
    gap: 10px;
    &-list {
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 0px;
      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  &__formWrapper {
    width: 40%;
  }
}
.Integration {
  gap: 20px;

  &-createRole {
    gap: 15px;

    .purple-flag {
      font-size: 12px !important;
    }

    &__load {
      gap: 20px;
      margin-bottom: 20px;
    }

    &-list {
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 0px;
      li:not(:last-child) { 
        margin-bottom: 10px;  
      }
    }
  }
  &-failure {
    gap: 10px;
    &-list {
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 0px;
      li:not(:last-child) { 
        margin-bottom: 10px;
      }
    }
  }
}