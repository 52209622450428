@import "../../../styles/colors.scss";

.InventoryFilters {
  width: 100%;
  max-height: 100%;

  &__header {
    padding: 0;
    height: 44px;
    
    &-title {
      font-weight: bold;
      color: var(--textSuperLight);
    }

    &-actions {
      gap: 7px;
      color: $primary;

      &-clear {
        font-size: 12px;
        color: var(--disableColor);
        cursor: not-allowed;
        padding: 2px 4px;
        transition: all 0.3s ease;

        &.active {
          color: $primary;
          cursor: pointer;

          &:hover {
            background-color: rgba(152,114,254,.2);
            border-radius: 5px;
          }
        }
      }
      &-divider {
        margin: 0 !important;
        top: 0 !important;
        height: 1.1em !important;
        opacity: .7;
        border-left: 1px solid var(--disableColor) !important;
        &.active {
          border-left: 1px solid $primary !important;
        }
      }
        button {
          padding: 3px 6px;
          margin-top: 2px !important;
          svg {
            width: 16x !important;
            height: 16px;
          }

          &:hover {
            background-color: rgba(152,114,254,.2);
            border-radius: 50%;
          }
        }
    }
  }

  &__masterFilterWrapper {
    padding: 10px 0 20px 0;
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  &__collapse{
    overflow-y: auto;
    height: calc(100vh - 290px);
  }

  &__item {
    gap: 8px;

    &-title {
      max-width: 80%;
      color: var(--textLighter);
      font-size: 13px;
      gap: 3px;

      &.active {
        color: $primary;
      }
    }

    &-filterIcon {
      position: relative;
      width: min-content;
      &-icon {
        font-size: 12px;

        &.classification {
          fill: var(--text);
          transform: translateY(1px);
          width: 16px;
        }

        &.emptyIcon {
          color: var(--text);
          transform: translateY(1px);
          width: 16px;
        }
  
        &.classificationActive {
          fill: $primary;
          transform: translateY(1px);
          width: 16px;
          color: $primary;
        }

        &.iconActive {
          transform: translateY(1px);
          color: $primary;
          width: 16px;
        }

        &.emptyIconActive {
          color: $primary;
          transform: translateY(1px);
          width: 16px;
        }

        &.asset-type-icon {
          max-height: 16px;
        }

        &.years-icon {
          max-height: 18px;
          width: 18px;
        }
        &.iacStack {
          height: 14px;
          width: 14px;
          path {
            fill: var(--text);
          }
        }
        &.iacStackActive { 
          path {
            fill: $primary;
          }
        }
        &.provider-type-icon {
          width: 18px !important;
        }
        &.iac-ignored-icon{
          path {
            fill: var(--text);
          }
          width: 18px !important;
        }
        &.iac-ignored-active { 
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  // styling collapse
  .ant-collapse-item {
    overflow: hidden;
    cursor: pointer;
  }
  .ant-collapse-content {
    background-color: transparent !important;
  }

  .ant-collapse-header {
    color: var(--text);
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  .ant-collapse-arrow {
    top: 38% !important;
    right: 25px !important;
  }

  // inside white card mode
  .inCardMode {
    .ant-collapse-item {
      margin: 0 !important;
      overflow: hidden;
    }
  }
}
