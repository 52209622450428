@import "../../styles/colors.scss";

.AppModal {
  width: 100%;
  &__header {
    width: 100%;
    padding: 26px 30px 23px 30px;
    border-bottom: 1px solid var(--separatorColor);
    gap: 8px;

    &-title {
      font-size: 18px;
      color: var(--text);
    }

    &-wrapper {
      gap: 12px;
      &-icon {
        height: 40px;
        width: auto;
      }
    }

    &-close {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--exitButtonX) !important;
      font-size: 18px !important;
      width: 25px;
      height: 25px;
      transition: all 0.2s ease;

      &:hover {
        color: var(--darkenExitButtonX) !important;
      }
    }
    &-description {
      color: var(--textSuperLight);
      font-size: 13px !important;
    }
  }

  &__body {
    padding: 30px;
    max-height: 45vh;
    overflow: auto;
  }

  &__footer {
    padding: 26px 30px 23px 30px;
    border-top: 1px solid var(--separatorColor);
    justify-content: flex-end;
    align-items: flex-end !important;
    gap: 20px;

    &-btns {
      gap: 10px;
    }
  }
}
