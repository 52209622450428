.Loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes rotateAnimation {
	0%{
        transform: rotateY(0deg);
     }
     100%{
         transform: rotateY(360deg);
     }
}