@import "../../styles/colors.scss";

.BackupHistoryTable {
    &-status {
        &-icon {
           &.completed { color: $greenFlag;} 
           &.inProgress { color: $purple;}
           &.failed { color: $redFlag;}
        }
    }
}