@import "../../../styles/colors.scss";

.leaflet-popup-content-wrapper {
    border: #30304a 1px solid !important;
    background-color: #212133 !important;
    color: white !important;
}
.leaflet-container {
    background-color: var(--card)
}
.mapComponent {
    width: 100%;
    gap: 25px;
    color: white;

    &__mapContainer {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background-color: var(--card);
       &.loaded {
            background-color: var(--mapRegionsBG) !important;
       }
    }

    &__provider-filters {
        color: #564e4e !important;
    }

    &__select-label {
        display: flex;
        gap: 5px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    &__filter-wrapper {
        z-index: 9999;
        position: absolute;
        margin: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        background-color: #212133;
        border: #30304a 1px solid;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        &__provider-filter {
            display: flex;
            color: white;
            gap: 4px;
            align-items: center;
            justify-content: center;
        }
    }

    &__bar-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &__header {
            font-size: 12px;
        }
    }

    &__coverage {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &__wrapper-name {
            display: flex;
            flex-direction: row;
            gap: 2px;
            margin-bottom: 15px;
            cursor: pointer;
            div {
                font-size: 14px;
                font-weight: bold;
            }
        }

        &__count {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            margin-bottom: 8px;
            &__text {
                font-size: 12px;
            }
            &__value {
                font-size: 12px;
            }
        }

        &__tooltip {
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            padding: 0 15px;
            li {
                display: flex;
                gap: 10px;
                align-items: center;
                column-gap: 15px;
                span:first-child {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
        }
        &__progress {
            list-style-type: none;
            padding: 0;
            margin-bottom: 0 !important;
            display: flex;
            width: 150px;
            border-radius: 10px;
            overflow: hidden;
            height: 12px;

            li {
                height: 8px;
                display: flex;
                gap: 10px;
            }
        }
        &__tooltip > li > span,
        &__progress li {
            height: 16px;
            &.codified {
                background-color: $turquoise;
            }

            &.ghost {
                background-color: $salmon;
            }

            &.drift {
                background-color: $purple;
            }

            &.unmanaged {
                background-color: $strawberry;
            }
        }
    }
}
