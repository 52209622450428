@import "../postPlanChart.scss";
@import "../../../../../styles/colors.scss";

.DiagramResourceBlock {
    position: relative;
    background-color: var(--card);
    padding: 10px 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid var(--cardBorder);
    transition: border .2s ease;
  
    &:hover {
      transition: border .2s ease;
      border-color: var(--primary);
    }
    &.second {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: -4px;
      right: -4px;
    }
    &.third {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: -8px;
      right: -8px;
      z-index: -1;
    }
    &.ci {
      max-width: 235px;
      padding: 8px 12px;
      border-width: 2px;
      .bold {
        font-size: 13px !important;
      }
      .sub-title {
        font-size: 11px !important;
        overflow: hidden;
      }
    }
    &.no-op {
      border-color: var(--no-op);
      &:hover {
        background-color: var(--no-opHover);
      }
    }
    &.delete {
      border-color: var(--delete);
      &:hover {
        background-color: var(--deleteHover);
      }
    }
    &.read {
      border-color: var(--read);
      &:hover {
        background-color: var(--readHover);
      }
    }
    &.update {
      border-color: var(--update);
      &:hover {
        background-color: var(--updateHover);
      }
    }
    &.create {
      border-color: var(--create);
      &:hover {
        background-color: var(--createHover);
      }
    }
    &.createdelete {
      border-color: var(--createdelete);
      &:hover {
        background-color: var(--createdeleteHover);
      }
    }
    &.deletecreate {
      border-color: var(--deletecreate);
      &:hover {
        background-color: var(--deletecreateHover);
      }
    }
    &.import {
      border-color: var(--import);
      &:hover {
        background-color: var(--importHover);
      }
    }
    &__body {
      &-count {
        font-weight: 400;
        &.create { color: var(--create); }
        &.delete { color: var(--delete); }
        &.read { color: var(--read); }
        &.update { color: var(--update); }
        &.createdelete { color: var(--createdelete); }
        &.deletecreate { color: var(--deletecreate); }
        &.no-op { color: var(--no-op); }
        &.import { color: var(--import); }
      }
    }
    &__textContainer {
      height: 85%;
      width: 82%;
    }
    &__icons { margin-left: 5px;}
  }
.ResourcePopover {
    &__header {
        padding-bottom: 10px;
        margin: 0px 15px;
    }
    &__body {
        margin: 10px 15px 0px 15px;
        &-title {
          min-width: 93px;
        }
        &-value {
            margin-left: 5px;
        }
    }
    &__icon {
        width: 13px !important;
        height: 13px;
        padding: 2px;
        border-radius: 2px;
        color: var(--card);
        &.diagram {
          align-self: flex-start;
          margin-top: 5px;
        }
        &.cost { background: #7DCA9E; }
        &.tags {
          background-color: var(--primary);
          path { fill: var(--card);}
        }
        &.noTags {
          background: #D33833;
          path { fill: var(--card); }
        }
        &.drifted {
          background-color: $purple_Lilac;
          color: $purple;
        }
        //actions
        &.no-op { background-color: var(--no-op);}
        &.delete { background-color: var(--delete);}
        &.read { background-color: var(--read);}
        &.update { background-color: var(--update);}
        &.create { background-color: var(--create);}
        &.createdelete { background-color: var(--createdelete);}
        &.deletecreate { background-color: var(--deletecreate);}
        &.import { background-color: var(--import);}
        // severity
        &.critical, &.error { background-color: var(--critical);}
        &.high { background-color: var(--high);}
        &.medium { background-color: var(--medium);}  
        &.low,  &.warning { background-color: var(--low);}
    }
    .ant-divider-horizontal {
        margin: 0px !important;
    }
}
.ant-popover-inner-content {
    padding: 10px 0px !important;
    border: 1px solid var(--separatorColor);
    border-radius: 4px;
}