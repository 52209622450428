.BackupPolicyModal {
    &-divider {
        height: 2px;
        width: 100%;
        background-color: var(--separatorColor);
    }
    &__footer {
        align-self: flex-end;
    }
    &__body {
        min-height: 65vh;
        label{
            color:var(--text) !important;
        }
    }
    &__header{
        svg {
            color: var(--primary);
        }
        &-title {
            margin-top: 3px;
        }
    }
    &__section {

        &-row {
            display: flex;
            gap: 10px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &-item {
                flex: 1;
                &-notifIcon {
                    path {
                        fill: #3EE1DE !important;
                    }
                }
            }
        }
    }
}