@import "../../styles/colors.scss";

.ActionIcon {
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: transparent;
    width: 16px;
    height: 16px;

    path {
      fill: var(--primary);
    }
    
    &:hover {
      opacity: .7;
      path {
        transition: all 0.3s ease;
        fill: var(--primary) !important;
      }
    }
  
    &.disabled {
      background-color: var(--disableBG) !important;
      color: var(--disableColor) !important;
      cursor: not-allowed;
    }

    &.action-success {
      transition: all 0.3s ease;
      fill: var(--primary) !important;
    }
  }