@import "../../../styles/colors.scss";

.FreeTierMask {
  width: 100%;
  height: 100%;
  filter: blur(3px);
  cursor: pointer;
  &.noBlur {
    filter: none;
  }
}
