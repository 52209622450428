.GithubIntegration {

    .GithubOverview {
        width: 50%;
        gap: 3px;
    }

    .GithubSetup {
        width: 50%;
        gap: 30px;
        &__intro {
            gap: 10px;
            &-orderedlist {
            padding-left: 10px;
            margin-top: 10px;
            margin-bottom: 0px;
            li {
                padding: 0 !important;
            }
            li:not(:last-child) { 
                margin-bottom: 10px;  
            }
            }
        }

        &__loading {
            height: 50vh;
        }

        &__intro {
            gap: 3px;
        }
    }
}