@import "../../../../../styles/colors.scss";

.AiRemediationVcTable {

    &.icon {
        [role="img"] {
            color: $purple !important;
            margin-right: 3px !important;
        }
    }

}