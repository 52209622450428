.CodeSyntaxEditor {
    position: relative;

    &__header {
        position: absolute;
        top: 30px;
        right: 20px;
        z-index: 100; 
        .GradientBorderButton__content {
            padding: 0px !important;
            gap: 0px !important;
            &:hover {
                background: var(--aiBtnBG) !important;
                .CodeSyntaxEditor__header-divider {
                    height: 28px !important;
                }
            }
        }
        &-btn {
            padding: 4px 8px !important;
            svg { width: 16px !important; }
            &:hover {
                background: var(--gradientColorBG) !important;
            }
        }
        &-divider {
            height: 20px;
            width: 1px;
            background: #909099;
            transition: all 0.2s ease-in-out;
        }
    }
    &__code {
        width: 85%;
        margin-top: 10px;
        .monaco-editor, .monaco-editor-background 
        {
            background-color: var(--codeBG) !important;
        }
        .margin-view-overlays {
            background-color: var(--codeBG) !important;
        }
        &.edit {
            height: 100vh;
            width: 100%;
        }
    }
    &__editor {
        background: var(--codeBG);
        margin-top: 10px;
    }
}