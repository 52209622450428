.EditableTagGroup{
    border: 1px solid var(--layoutBorder);
    border-radius: 4px;
    padding: 7px 10px;
    display: flex;
    column-gap: 5px;
    row-gap: 10px;
    flex-wrap: wrap;
    align-items: center;

    &:hover{
        border-color: #705ab6;
    }
    &.active{
        border-color: #9b78fc;
        box-shadow: 0 0 0 2px rgba(155, 120, 252, 0.2);
    }
    
    input{
        border: none !important;
        min-width: 30px !important;
        box-shadow: none !important;
        transition: none !important;
        padding: 0 !important;

    }
    span.EditableTagGroup-tag{
        border-radius: 4px !important;
        background: #373259 !important;
        background-color: rgba(152, 114, 254, 0.2) !important;
        color: rgba(152, 114, 254, 0.8) !important;
        border: 0 !important;
        >span{
            margin-left: 7px;
        }
    }
    &.rowDisplay {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
    }
    &.disabled {
        background-color: var(--disableBG) !important;
    }
}