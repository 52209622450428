@import "../../styles/colors.scss";
.AppDrawer {
  &__content {
    width: 100%;
    min-height: 100vh;
    overflow-x: auto;
    z-index: 2;

    &-header {
      padding-left: 40px;
      height: 10vh;
      gap: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;

      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 20px;
        color: var(--exitButtonX);
        transition: color 0.2s ease;
        cursor: pointer;

        &:hover {
          color: var(--darkenExitButtonX);
          transition: color 0.2s ease;
        }
      }

      &-title {
        gap: 20px;
        .purple-flag {
          font-size: 12px;
          font-weight: bold;
        }
      }

      &-sub {
        gap: 10px;
        flex-wrap: wrap;
        color: var(--sidebarLink);
        font-size: 13px;
      }
    }

    &-body {
      height: 82vh !important;
      max-height: 82vh !important;
      overflow-y: auto;
      position: relative;
      border-bottom: 1px solid var(--separatorColor);

      &.topBorder {
        border-top: 1px solid var(--separatorColor);
      }
    }

    &-footer {
      height: 8vh;
      width: 100%;
      gap: 15px;
      padding-left: 40px;
    }
  }
}
