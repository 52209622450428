.ModuleCall {
    &.config {
      height: 100%;
      width: 55%;
    }
    &__code-cost {
      height: 100%;
      width: 45%;
    }
    &.code {
      height: 60%;
      padding: 0px;
      .cm-theme {
        height: 100%;
      }
      &.fullHeight {
        height: 100% !important;
      }
    }
    &.cost {
      height: 40%;
    }

    &__title {
      color: var(--text);
      gap: 7px;
      margin-bottom: 14px;
      &.tf {
        margin-top: 10px;
      }
    }
    &__select {
      &-item {
        width: 50%;
      }
      &-link {
        cursor: pointer;
        &:hover {
          color: var(--primary);
        }
      }
    }
    &__tfVars {
      height: 75%;
      &-container {
        height: 100%;
        overflow-y: auto;
        scroll-behavior: smooth;
      }
      &-scrollIcon {
        background-color: var(--text);
        color: var(--card);
        font-size: 33px;
        border-radius: 50%;
        padding: 5px;
        border: 1px solid var(--separatorColor);
        position: relative;
        cursor: pointer;
        bottom: 10px;
        left: 50%;
        transition: all 0.2s ease-in;
        &:hover {
          background-color: var(--primary);
        }
      }
      .GradientBtn {
        align-self: flex-end;
        margin: 0px 0px 20px 0px;
      }
    }
  &__errors {
    white-space: pre-wrap;
    font-size: 13px;
    text-align: center;
    &-icon {
      color: var(--red);
      font-size: 20px;
    }
    &-noFound {
      color: var(--red);
    }
  }
}