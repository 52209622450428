@import "../../../styles/colors.scss";
$gradientCodifyChat: linear-gradient(45deg, var(--primary), var(--grey));

.CodifyDrawer {
  &__tab {
    gap: 8px;
    &-icon {
      width: 17px;
      height: 17px;
      &-opentofu {
        width: 19px;
        height: 18px;
      }
    }
  }
  &__content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    &.loadingAi {
      .CodeSyntaxEditor__code {
        filter: blur(1px);
      }
      
    }
    &-loaderAi {
      position: absolute;
      z-index: 1000;
      top: calc(50% - 10vh + 50px);
      right: calc(50% - 200px);
  }
    &-title {
      display: flex;
      align-items: center;
      padding-left: 40px;
      height: 10vh;
    }

    &-body {
      background-color: var(--codeBG);
      height: calc(82vh - 55px) !important; // tabs body - tabs labels
      max-height: calc(82vh - 55px) !important; // tabs body - tabs labels
      overflow-y: auto;
      color: white;
      position: relative;

      .LanguageSwitcher {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.switcherTab {
        padding-top: 40px;
      }
    }

    &-footer {
      height: 8vh;
      background-color: var(--card);
      width: 100%;
      gap: 15px;
      padding-left: 40px;
      padding-right: 80px;
      border-top: 1px solid var(--separatorColor);
      &-importCmds {
        .AppBtn {
          &.disabled {
            background-color: transparent !important;
          }
          &__icon {
            align-self: end;
          }
        }
      }
    }
    .ActionBtn {
      &__icon {
        align-self: flex-start;
      }
    }
  }
  &__chat {
    left: 0px;
    height: 100% !important;
    right: unset !important;
    width: 25vw !important;
    bottom: 0px !important;
    padding: 0px !important;
    background: $gradientCodifyChat !important;
    border-radius: 0px !important;
    &::before {
      background: $gradientCodifyChat !important;
      border-radius: 0px !important;
      filter: blur(3px) !important;

    }
    .FiltersChat__messages {
      height: calc(100% - 105px - 250px - 51px) !important; // chat - header - suggestions additions (300px) input (31px) - padding (20px)
    }
    .FiltersChat__header {
      border-radius: 0px !important;
    }
    .FiltersChat__container {
      border-radius: 0px !important;
    }
    .FiltersChat__input {
      border-radius: 0px !important;
      padding: 10px 0px !important;
    }
    .FiltersChat__header-icon {
      animation: butterFly 0.4s ease-in-out;
      animation-delay: 0.7s;
    }
  }
  &__thinkerbell {
    position: absolute;
    top: 33px;
    z-index: 1000;
    left: 300px;
    animation: butterFly 0.5s ease;
    height: 24px;
    width: 24px;
    &:hover {
      opacity: 0.8;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  .ant-tabs-tab {
    width: auto;
  }
}
