@import '../../../styles/colors.scss';

.FreeTierModal {
  .ant-modal-content {
    background-color: #212133 !important;
    color: white;
    border: 1px solid #454569 !important;
    .ant-modal-body {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  &__header {
    gap: 30px;
    font-size: 16px;
    position: relative;

    img {
      width: 150px;
    }
    &-title {
      font-size: 55px;
      font-weight: bold;
      color: $primary;
      line-height: 60px;
    }
    &-sub {
      max-width: 350px;
    }
    &-close {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: rgba(255, 255, 255, .75) !important;
      font-size: 18px !important;
      width: 30px;
      height: 30px;
      transition: all 0.2s ease;

      &:hover {
        color: white !important;
        transition: all 0.2s ease;
      }
    }
  }

  &__list {
    gap: 14px;
    flex-wrap: wrap;

    &-item {
      gap: 6px;
      &:not(:last-child) {
        padding-right: 14px;
        border-right: 1px solid #59fee0;
      }
      img {
        width: 12px;
      }
    }
  }

  &__actions {
    gap: 15px;
    margin-top: 10px;
  }
}
