.WorkspaceMenu {
    &-popover {
        padding: 0 7px;
        cursor: pointer;
        &:hover {
            .WorkspaceMenu-icon {
                color: var(--primary);
            }
        }
    }
    &-icon {
        font-size: 14px;
    }
    &-iacIcon {
        width: 16px;
        path {
            fill: var(--primary);
        }
        &-guardrail {
            width: 16px;
            height: 16px;
        }
        &-delete {
            margin-left: 2px;
        }
    }
    &__deletePrBody {
        max-height: 70vh;
        height: 70vh;
    }
    &__deleteIgnore {
        color: var(--red);
    }
}