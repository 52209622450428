@import "../../styles/colors.scss";

.WarningTriangle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 13px;
  margin-left: 5px;

  img {
    transform: translateY(-1px);
    width: auto;
    height: 14px !important;
  }

  &.red {
    background-color: rgba($red, 0.1);
  }

  &.orange {
    background-color: rgba($orange, 0.1);
  }
  &.green {
    background-color: rgba($orange, 0.1);
  }

  &.minimal {
    background-color: transparent;
    width: 15px;
    height: 15px;
    img {
      transform: translateY(0);
      height: 12px !important;
    }
  }
  &.noMargin {
    margin: 0;
  }
}
