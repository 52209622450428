.GuardrailsBlockTable {
    &.hideNav {
      .TableWrapper__table .MuiDataGrid-footerContainer {
        display: none;
      }
    }
    .ant-collapse-item {
        overflow: hidden;
        cursor: pointer;
        background-color: var(--guardrailViolationsFailedBg);
        border: 1px solid var(--guardrailViolationBorder);
        border-radius: 5px;
      }
      .ant-collapse-content {
        background-color: var(--card) !important;
      }
   
      .ant-collapse-header {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        color: var(--text);
        width: 100%;
        &:hover {
            opacity: .8;
            background-color: unset !important;
        }
      }
      .ant-collapse-arrow {
        margin: 0px 5px;
      }
      .ant-collapse-item-active .ant-collapse-header {
        color: var(--text) !important;
        background-color: unset !important;
        .ant-collapse-arrow {
            color: var(--text) !important;
            fill: var(--text) !important;
        }
      }
      
      &.passed {
        .ant-collapse-item {
            background-color: var(--guardrailsViolationsPassedBg);
            border: 1px solid var(--guardrailsViolationsPassedBorder);
        }
      }

      &.hideCollapseArrow {
        .ant-collapse-arrow {
          display: none;
        }
      }

      &.softFailed {
        .ant-collapse-item {
          background-color: var(--guardrailsViolationsSoftFailedBg);
          border: 1px solid var(--guardrailsViolationsSoftFailedBorder);
        }
      }

      &__item {
        &-icon {
            width: 18px !important;
            height: 18px;
            path {
              fill: var(--guardrailViolationBorder) !important;
            }
            &.passed {
                path {
                    fill: var(--guardrailsViolationsPassedIcon) !important;
                }
            }
            &.softFailed {
              path {
                fill: var(--guardrailsViolationsSoftFailedIcon) !important;
              }
            }
        }
        &-table {
            &-severity { 
                line-height: 18px;
                height: 18px;
                font-size: 12px;
            }
            &-actionName {
                margin-top: 3px;
            }
            &-icon {
                &.passed {
                  color: #1AD598;
                }
                &.softFailed {
                  color: #F8CD4C;
                }
                color:#EB4C3D;
            }
        }
      }
      .TableWrapper__table .MuiDataGrid-detailPanel {
        left: 0px !important;
      }
      .MuiDataGrid-detailPanelToggleCell {
        display: none;
      }
      .RemediationBlock {
        &.expandedBlock {
          margin-top: 0px !important;
        }
      }
}