.CodifyChatSuggestions {
    border-top: 1px solid var(--separatorColor);
    padding: 10px;
    height: 250px;
    gap: 7px;
    overflow: auto;
    &__item {
        cursor: pointer;
        background: var(--gradientColorBG);
        padding: 1px;
        border-radius: 7px;
        &:hover {
           .CodifyChatSuggestions__item-content {
                opacity: 0.8;
            }
        }
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
        &-content{
            background: linear-gradient(90deg,#3f3366, #25575d);
            color: white;
            border-radius: 7px;
            padding: 4px 8px;
            transition: 0.3s all ease-in;
            height: 100%;
            width: 100%;
        }
    }
   
}