@import "../../styles/colors.scss";

.AppToggle {
  width: 34px;
  cursor: pointer;
  user-select: none;
  height: 18px;
  position: relative;
  transition: background-color 0.5s ease;

  &.unChecked {
    .AppToggle__line {
      background-color: #a5a5a5;
    }
    .AppToggle__inner {
      border: 1px solid #a5a5a5;
    }
  }

  &.loading {
    opacity: 0.6;
  }

  &.isDefault {
    margin-left: 20px;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &__inner {
    background-color: white;
    border-radius: 34.5px;
    // box-shadow: 0px 0px 5px rgba(152, 114, 254, 0.5);
    border: 1px solid var(--primary);
    width: 13px;
    height: 13px;
    min-width: unset;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 2;

    &.misconfiguration {
      // box-shadow: 0px 0px 5px rgba(227, 17, 116, 0.5);
      border-color: $misconfig;
    }

    &.reliability {
      // box-shadow: 0px 0px 5px rgba(0, 168, 255, 0.5);
      border-color: $reliability;
    }

    &.optimization {
      // box-shadow: 0px 0px 5px rgba(125, 219, 222, 0.5);
      border-color: $optimization;
    }

    &.unChecked {
      left: 3px;
    }
  }

  &__line {
    width: 80%;
    height: 4px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--primary);

    &.misconfiguration {
      background-color: $misconfig;
    }

    &.reliability {
      background-color: $reliability;
    }

    &.optimization {
      background-color: $optimization;
    }
  }
  &__text {
    color: var(--text);
  }
  // adjust spinner
  .ant-spin-spinning {
    color: black;
    transform: scale(0.7);
  }
}
